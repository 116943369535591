import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";

import User from "./dropdown/user/User";


const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });
  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
              icon="menu"
              click={props.sidebarToggle}
            />
          </div>
          <div className="nk-header-brand d-xl-none">
          <img src={require('../../assets/images/jsTree/bird.png')} alt="logo icon" style={{width:"42px"}} className="logo_icon"/>
          </div>
          <div className="nk-header-news d-none d-xl-block">
            {/* <News /> */}
          </div>
          
         <b className="header_title">Guest House Management</b> 
          <div className="nk-header-tools mt-n3" style={{float: "right"}}>
            <ul className="nk-quick-nav">
              <li className="user-dropdown"  onClick={() => setVisibility(false)}>
                <User />
              </li>
              <li className="notification-dropdown mr-n1"  onClick={() => setVisibility(false)}>
               
              </li>
            </ul>
          
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
