import React, { useState, useContext } from "react";
import LogoLight2x from "../../images/hb/HB_logo.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { FormGroup,Spinner } from "reactstrap";
import { Link,useHistory   } from "react-router-dom";
import axios from "axios";
import { BookingContent } from "../../rcomponents/bookingContext/BookingContext";


const ForgotPassword = () => {
  const { proxy_Url } = useContext(BookingContent);

  const [forgotUName, setForgotUName] = useState("");
  const [errormessage, setErrormessage] = useState([]);
  const [successmessage, setsuccessmessage] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const sendReset = (ev) => {
    ev.preventDefault();
    setLoading(true);
if(forgotUName?.length > 0) {
    //forgot password
    let bodyData = {
      Email: `${forgotUName}`,
    };

    axios
      .post(`${proxy_Url}/forgetpwd`, bodyData)
      .then((res) => {
        let Rdata = res.data.Result;
        if(Rdata === 'Invalid Email Id')
        {
          setErrormessage(["Invalid Email Id"]);
          setTimeout(() => {
            setErrormessage([]);
            setLoading(false);
          }, 2000);
        }
        else if(res.data.Result[0]?.msg === "Account Exists") {
          setsuccessmessage(['Forgot password link has been sent successfully. Please check your email']);
          setTimeout(() => {
            setsuccessmessage([]);
            setLoading(false);
            history.push(`${process.env.PUBLIC_URL}/auth-login`);

          }, 2000);
          
        }
        else {
          setLoading(false);
        }
      })
      .catch((err) => 
      {
        // console.log(err.message)
      });
    }
    else {
      setErrormessage(["Please enter your email"]);
      setTimeout(() => {
        setErrormessage([]);
        setLoading(false);
      }, 2000);
    }
  };

  const onPaste = (e) => {
    e.clipboardData.getData("frogotemail");
  };
  return (
    <React.Fragment>
      {errormessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Error Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              
                <p className="t-center">
                  {errormessage[0]}
                </p>
             
            </div>
          </div>
        </div>
      )}
      {successmessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con exceed" style={{ background: "#01bf00" }}>
              <span>Success Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              
                <p className="t-center">
                  {successmessage[0]}
                </p>
             
            </div>
          </div>
        </div>
      )}
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <div className="brand-logo pb-4 text-center w-100 d-flex align-item-center justify-content-center">
                  {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link"> */}
                  <img
                    className="logo-dark logo-img logo-img-lg no-bg"
                    style={{ background: "none !important" }}
                    src="https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png"
                    alt="logo-dark"
                  />
                  {/* </Link> */}
                </div>
                <BlockTitle tag="h5">Reset password</BlockTitle>
                <BlockDes>
                  <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                  defaultValue={forgotUName}
                  name="frogotemail"
                  onChange={(e) => setForgotUName(e.target.value)}
                  onPaste={onPaste}
                />
              </FormGroup>
              <FormGroup>
                <Button color="primary" size="lg" className="btn-block" onClick={sendReset}>
                {loading ? <Spinner size="sm" color="light" /> : "Send Reset Link"}
                </Button>
              </FormGroup>
            </form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
