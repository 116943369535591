import React, { useState, useEffect, useContext } from "react";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";

const BookingCancelTemplate = ({ editpopup, showEditpopup,showcancelpopup }) => {
  const {
    propertyID,
    set_Error_Message_func,
    booking_modify_cancel_data,
    propnameid,
    token,
    proxy_Url,
    show_error_templatefunc,
    getErrorMessagefunc,
    errorClassNamefunc,
    isLoadingfunc,
    property_details,
    gridoccupencydatafunc,
    ghaccessdata,
    setErrorMsgClassfunc,
    propInput,
  } = useContext(BookingContent);
  // const [cancel_details, setCancelDetails] = useState(booking_modify_cancel_data && booking_modify_cancel_data?.BookingCancelType);
  //const [cancel_data, setcancel_data] = useState(booking_modify_cancel_data && booking_modify_cancel_data?.Result[0]);
  const [cancel_reason, setcancel_reason] = useState("");
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [msg_option, setmsg_option] = useState([]);
  const [showchangelog, setshowchangelog] = useState(false);
  const [changelog, setchangelog] = useState([]);

  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };
  useEffect(() => {
    if (ghaccessdata[0]?.CancelResFlg === false) {
      setgetErrorMessagefunc(["You dont have access to cancel this booking"]);
      setTimeout(() => { setgetErrorMessagefunc([])},2000);
    }
  }, []);
  let ghproperty_Name = document.querySelector("#ghproperty");
  let ghproperty_Entity = document.querySelector("#Entity_select");
  const close = () => {
    if (changelog?.length > 0) {
      setshowchangelog(true);
  }
      else {
    //showEditpopup(!editpopup);
    showcancelpopup(false);
        showEditpopup(false);
       
      }
  };

  const SelectChange = (e) => {
    setcancel_reason(e.target.value);
   
      setchangelog(["Changes that you made may not be saved","do you want to close?"])
    setmsg_option("Error")
    //showEditpopup(!editpopup);
  };
  const confirm_changlog = () => {
    showEditpopup(false);
    showcancelpopup(false);
    
   
  };
  const cancel_changlog = () => {
    setshowchangelog(false);
   
  };

  const cancelBooking = () => {
   
    let cancelationremarks =  document.querySelector("#cancelationremarks").value;
    let cancelationreason =  document.querySelector("#cancel_booking").value;

if(cancelationremarks !=='' && cancelationreason !== "Select") {
  isLoadingfunc(true);
    let data = {
      BookingCode: booking_modify_cancel_data?.Result[0]?.BookingCode,
      BookingId: booking_modify_cancel_data?.Result[0]?.BookingId,
      Remarks:cancelationremarks, 
      UserId: ghuserId,
      BookingLevel: booking_modify_cancel_data?.Result[0]?.BookingLevelId, // Room or Bed
      RoomCapturedId: booking_modify_cancel_data?.Result[0]?.RoomCapturedId,
      BookingPropertyAssignedGuestId: booking_modify_cancel_data?.Result[0]?.BookingAssingedId, // ‘823502’ or ‘823503, 823504’
      Occupancy: booking_modify_cancel_data?.Result[0]?.Occupancy, // Single or Double
      cancelationremarks:document.querySelector("#cancel_booking").value || (cancel_reason && cancel_reason)
    };
    /* cancelationremarks and remarks interchanged for backend*/

    let s_date = document.querySelector("#STime")?.value.split("/");
    let e_date = document.querySelector("#ETime")?.value.split("/");
    let gridData = {
      PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || propertyID[0],
      FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
      ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
      PropertyName:
        property_details[0]?.PropertyName ||
        property_details[0]?.Property ||
        document.querySelector("#ghproperty")?.value,
    };
    axios
      .post(`${proxy_Url}/BookingCancellation`, data, { headers: headers })
      .then((res) => {
        // show_error_templatefunc(true);
        // getErrorMessagefunc([res.data.Result]);
        // setgetErrorMessagefunc([res?.data?.Result])
      
        set_Error_Message_func([res.data.Result]);
        setTimeout(() => {set_Error_Message_func([]);
          isLoadingfunc(false);},2000);
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
        errorClassNamefunc("error_success");
        
        //showEditpopup(!editpopup);
        showEditpopup(false);
        showcancelpopup(false);
    
        gridoccupencydatafunc(gridData);

      })
      .catch((error) => {
       
        // show_error_templatefunc(true);
        //  getErrorMessagefunc(error.response.data.Result);
        set_Error_Message_func([error.response.data.Result]);
        setTimeout(() => {set_Error_Message_func([]);},2000);
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });

        //errorClassNamefunc("error_success");
        isLoadingfunc(false);
        showEditpopup(!editpopup);
     
        gridoccupencydatafunc(gridData);
      });
    }
    else {


      if(cancelationremarks ==='') {
      
     
      setgetErrorMessagefunc(pre => [...pre,"Please enter cancellation remarks"]);
      }

      if(cancelationreason === "Select") {
        setgetErrorMessagefunc(pre => [...pre,"Please select reason for cancellation"]);
      }
      setTimeout(() => {setgetErrorMessagefunc([]);},2000);
    }
  };
  const error_close = () => {
    setgetErrorMessagefunc([]);
  };

  return (
    <>
    {changelog?.length > 0 && showchangelog === true && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con">
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center">
              {changelog?.map((item, i) => (
                <p className="t-center" key={i}>{item && ` ${item}`}</p>
              ))}
            </div>
            <button className="btn btn-sm btn-warning  mt-n1 mb-2 mr-2" onClick={cancel_changlog}>
              No
            </button>

            <button className="btn btn-sm btn-info  mt-n1 mb-2 mr-2" onClick={confirm_changlog}>
              Yes
            </button>
          </div>
        </div>
      )}
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span> Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {[...new Set(getErrorMessage)]?.map((item, i) => (
                <p className="t-center" key={i}>{item && ` ${item}`}</p>
              ))}
            </div>
          </div>
        </div>
      )}
      {ghaccessdata[0]?.CancelResFlg === true && (
        <div className="changeEditor">
          <div className="editorBody">
            <div className="header">
              <span>
                Booking Cancellation for <b>{`${ghproperty_Name?.value} |
               ${ booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.ClientName !== undefined ?  booking_modify_cancel_data?.Result[0]?.ClientName : ""}
                 
                `}</b>{" "}
              </span>
              <span className="d-flex ml-auto bg-light popup_Close" onClick={close}>
                X
              </span>
            </div>
            <div className="body">
              <div className="row">
                <div className="col-md-6 col-lg-6 booking_input">
                  <div className="col-md-12 my-2">
                    <span>Booking Confirmation Number</span> :{" "}
                    <b>{booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.BookingCode}</b>
                  </div>
                  <div className="col-md-12">
                    <span>Status</span> :{" "}
                    <b>{booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.CurrentStatus}</b>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 booking_input">
                  <div className="col-md-12 my-2">
                    <span>Guest Name</span> :{" "}
                    <b>{booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.Guest}</b>
                  </div>

                  <div className="col-md-12 my-2">
                    <span>Booking Level</span> :{" "}
                    <b>{booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.BookingLevelId}</b>
                  </div>
                </div>
              </div>
              <div className="p-1" style={{ background: "rgb(221, 233, 248)" }}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="change_editor">
                      Reason For Cancellation <span className="c-red">*</span> :{" "}
                      <select
                        style={{ background: "#ffffff", width:"100%"}}
                        name="cancel_booking"
                        id="cancel_booking"
                        onChange={SelectChange}
                       
                      >
                        {/* {cancel_details?.map((item) => (
                          <option key={item.Id} value={item.CancelType}>
                            {item.CancelType}
                          </option>
                        ))} */}
                        <option>Select</option>
                        {booking_modify_cancel_data?.BookingCancelType?.map((item) => (
                          <option key={item.Id} value={item.CancelType}>
                            {item.CancelType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>


                  <div className="col-md-6">
                  <div className="change_editor">
                      Cancellation Remarks<span className="c-red"> *</span> :{" "} 
                      <input type="text" maxLength="50" id='cancelationremarks' className="form-control"
                      onInput={propInput}/></div>
                  </div>



                </div>
              </div>
            </div>

            <div className="footer">
              <input
                type="button"
                value="Cancel Booking"
                className="btn btn-success btn-sm mr-1"
                onClick={cancelBooking}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(BookingCancelTemplate);
