import React, { useEffect,useContext,useRef,useState } from 'react';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { BookingContent } from "../bookingContext/BookingContext";
import { srcery } from 'react-syntax-highlighter/dist/esm/styles/hljs';


import { ScheduleComponent, ViewsDirective, ViewDirective, TimelineViews, Inject, ResourcesDirective, ResourceDirective, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import { extend, isNullOrUndefined } from '@syncfusion/ej2-base';
import dataSource from '../../datasource.json';
import "../../assets/scss/timeline-resource-grouping.css";




const Intro = () => {
    const { showintofunc,setSchedulerData,getPropertyDetails,gridstartenddate,settestdatefunc,isLoadingfunc } = useContext(BookingContent);
    const [showintro,showintrofunc] = useState(false);

    const closeintro=() => {
        showintofunc(false);
        setSchedulerData({});
        getPropertyDetails([])
        document.querySelector("#ghproperty").value =""
        setdate(new Date());
        settestdatefunc(null)
    }

    const setdate = (val) => {
        let newday = val;
   let fristday = new Date(newday.setDate(1));
   let endday = new Date(fristday.getFullYear(), fristday.getMonth() + 1, 0).getDate();
   let end_daynew = new Date(fristday.setDate(endday));


   gridstartenddate({ FromDte: newday, ToDte: end_daynew });
    }
  useEffect(() => {
    
   document.querySelector("#ghproperty").value ="Test Property"
   setdate(new Date('08,01,2023'))
   
   const intro = introJs();
setTimeout(() => {

    showintrofunc(true);
    isLoadingfunc(false);

    intro.setOptions({
      steps: [
        {
          element: '.property_search_page',
          intro: 'Select Guest House Name and submit to view the booking details.',
        },
        {
          element: '.second-element',
          intro: `<img src=${require('../../assets/images/bookingpopup.png')} alt=""/><br>
          Select entity first and Click any  available grid cell for new booking,<br>Room booking will book the entire cell<br>
          Bed booking will book the cell based on bed availability `,
        },
        {
            element:  document.querySelector(".room-green"),
            intro: `<img src=${require('../../assets/images/modification.png')} alt=""/></br>Click + drag the booking "or" Click + resize from right / left edge of the booking will open modification window, enter the mandatory fields and click check availability and modify button to modify booking.`,
          },
        {
            element: '.e-appointment',
            intro: `<img src=${require('../../assets/images/cancellation.png')} alt=""/></br>Click on the booking will open cancel window, you can cancel the booking here.`,
            position: 'bottom'
          },
          
          {
            element: '.room-black', 
            intro: `<img src=${require('../../assets/images/unblock.png')} alt=""/><br>Click on blocked booking will open unblock window, you can unblock the room here. `,
          },
      ],
    });

   intro.start();
},1000)


// Clean up Intro.js when the component unmounts
return () => {
  intro.exit();
  //showintofunc(false);
};
  }, []);

  // let date = new Date();
  //   date.setDate(date.getDate() + 1);

  // const data = [
  //   {
  //     Id: 1,
  //     Subject: 'Meeting - 1',
  //     StartTime: new Date(),
  //     EndTime: date,
  //     IsAllDay: false
  //   },
  // ];
  // const eventSettings = { dataSource: data }





  /**
   * schedule room scheduler sample
   */
  
      const data = extend([], dataSource.roomData, null, true);
      let scheduleObj = useRef(null);
      const ownerData = [
          { text: 'Jammy', id: 1, color: '#ea7a57', capacity: 20, type: 'Conference' },
          { text: 'Tweety', id: 2, color: '#7fa900', capacity: 7, type: 'Cabin' },
          { text: 'Nestle', id: 3, color: '#5978ee', capacity: 5, type: 'Cabin' },
          { text: 'Phoenix', id: 4, color: '#fec200', capacity: 15, type: 'Conference' },
          { text: 'Mission', id: 5, color: '#df5286', capacity: 25, type: 'Conference' },
          { text: 'Hangout', id: 6, color: '#00bdae', capacity: 10, type: 'Cabin' },
          { text: 'Rick Roll', id: 7, color: '#865fcf', capacity: 20, type: 'Conference' },
          { text: 'Rainbow', id: 8, color: '#1aaa55', capacity: 8, type: 'Cabin' },
          { text: 'Swarm', id: 9, color: '#df5286', capacity: 30, type: 'Conference' },
          { text: 'Photogenic', id: 10, color: '#710193', capacity: 25, type: 'Conference' }
      ];
      const getRoomName = (value) => {
          return value.resourceData[value.resource.textField];
      };
      const getRoomType = (value) => {
          return value.resourceData.type;
      };
      const getRoomCapacity = (value) => {
          return value.resourceData.capacity;
      };
      const isReadOnly = (endDate) => {
          return (endDate < new Date(2021, 6, 31, 0, 0));
      };
      const resourceHeaderTemplate = (props) => {
          return (<div className="template-wrap">
                  <div className="room-name">{getRoomName(props)}</div>
                  <div className="room-type">{getRoomType(props)}</div>
                  <div className="room-capacity">{getRoomCapacity(props)}</div>
              </div>);
      };
      const onActionBegin = (args) => {
          if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
              let data = args.data instanceof Array ? args.data[0] : args.data;
              args.cancel = !scheduleObj.current.isSlotAvailable(data);
          }
      };
      const onEventRendered = (args) => {
          let data = args.data;
          if (isReadOnly(data.EndTime)) {
              args.element.setAttribute('aria-readonly', 'true');
              args.element.classList.add('e-read-only');
          }
      };
      const onRenderCell = (args) => {
          if (args.element.classList.contains('e-work-cells')) {
              if (args.date < new Date(2021, 6, 31, 0, 0)) {
                  args.element.setAttribute('aria-readonly', 'true');
                  args.element.classList.add('e-read-only-cells');
              }
          }
          if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
              let target = args.element.querySelector('.e-resource-text');
              target.innerHTML = '<div class="name">Rooms</div><div class="type">Type</div><div class="capacity">Capacity</div>';
          }
      };
      const onPopupOpen = (args) => {
          let data = args.data;
          if (args.type === 'QuickInfo' || args.type === 'Editor' || args.type === 'RecurrenceAlert' || args.type === 'DeleteAlert') {
              let target = (args.type === 'RecurrenceAlert' ||
                  args.type === 'DeleteAlert') ? args.element[0] : args.target;
              if (!isNullOrUndefined(target) && target.classList.contains('e-work-cells')) {
                  if ((target.classList.contains('e-read-only-cells')) ||
                      (!scheduleObj.current.isSlotAvailable(data))) {
                      args.cancel = true;
                  }
              }
              else if (!isNullOrUndefined(target) && target.classList.contains('e-appointment') &&
                  (isReadOnly(data.EndTime))) {
                  args.cancel = true;
              }
          }
      };
     




  return (
    <>
    <div className="introposition">
     { showintro && <div className='intro_close' onClick={closeintro}>Close <span className='introclose'>X</span></div> }

        
        {/* <ScheduleComponent height='550px' selectedDate= {new Date()}
    //</div>  eventSettings={eventSettings}
    >
    </ScheduleComponent> */}
    {/* <div className='schedule-control-section'>
              <div className='col-lg-12 control-section'>
                  <div className='control-wrapper'>
                      <ScheduleComponent cssClass='timeline-resource' ref={scheduleObj} width='100%' height='650px' selectedDate={new Date(2021, 7, 2)} workHours={{ start: '08:00', end: '18:00' }} timeScale={{ interval: 60, slotCount: 2 }} resourceHeaderTemplate={resourceHeaderTemplate} eventSettings={{ dataSource: data, fields: { id: 'Id', subject: { title: 'Summary', name: 'Subject' }, location: { title: 'Location', name: 'Location' }, description: { title: 'Comments', name: 'Description' }, startTime: { title: 'From', name: 'StartTime' }, endTime: { title: 'To', name: 'EndTime' } } }} eventRendered={onEventRendered} popupOpen={onPopupOpen} actionBegin={onActionBegin} renderCell={onRenderCell} group={{ enableCompactView: false, resources: ['MeetingRoom'] }}>
                          <ResourcesDirective>
                              <ResourceDirective field='RoomId' title='Room Type' name='MeetingRoom' allowMultiple={true} dataSource={ownerData} textField='text' idField='id' colorField='color'/>
                          </ResourcesDirective>
                          <ViewsDirective>
                              <ViewDirective option='TimelineDay'  
                        interval={31}/>
                              
                          </ViewsDirective>
                          <Inject services={[TimelineViews, Resize, DragAndDrop]}/>
                      </ScheduleComponent>
                  </div>
              </div>
  
          </div>*/}
     </div> 
    </>
  );
};

export default Intro;