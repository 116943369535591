import React,{useContext} from "react";
import LogoLight2x from "../../images/hb/HB_logo.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import {BookingContent} from "../../rcomponents/bookingContext/BookingContext"

const Success = () => {
  const {User_name, getLoginAuth,proxy_Url}  = useContext(BookingContent);
  const clearloginauth = () => { 

    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessToken1");
    sessionStorage.removeItem("credentials");
    getLoginAuth({})
  }
  return (
    <React.Fragment>
      <Head title="Success" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-dark logo-img logo-img-lg no-bg"  src="https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png" alt="logo-dark" />
            </Link>
          </div>
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4" className='text-center'>Password successfully updated </BlockTitle>
              <BlockDes className="text-success text-center">
                <p>You can now <Link to={`${process.env.PUBLIC_URL}/auth-login`} onClick={clearloginauth} className="logo-link font-weight-bold">sign in</Link> with your new password</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Success;
