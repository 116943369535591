import React, { useCallback, useEffect, useState, useContext, useRef } from "react";
import { BookingContent } from "../bookingContext/BookingContext";
//import axios from "axios";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";

const EditTr = ({
  setShowedittr,
  edittrval,
  editindex,
  settr,
  Add_tr_list,

  //setSelectedValue
}) => {
  const { new_booking_data, clientcustomfieldscollection, propInput, clientfulldetails } = useContext(BookingContent);

  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [gettrErrorMessage, setgettrErrorMessagefunc] = useState([]);
  const [count, setcount] = useState(0);
  const [editFormData, seteditFormData] = useState(edittrval);
  // const [changelog, setchangelog] = useState([]);
  // const [showchangelog, setshowchangelog] = useState(false);

  //console.log(setShowedittr, edittrval, editindex, settr, Add_tr_list);
  //console.log(clientfulldetails);
  const grades = clientfulldetails[0][2]?.map((item) => item.Grade);
  const closeFields = () => {
   // console.log(count)

    if(count > 0) {
      setgetErrorMessagefunc(["Changes that you made may not be saved", "do you want to continue?"])
    }
    else {
    setShowedittr(false); }
  };

  const handlesubmit = (e) => {
    e.preventDefault();
  };
  
  const saveTrFields = () => {
   // console.log("first");
 
   let trfilter = clientfulldetails[0][2]?.filter(item => item?.Grade == editFormData?.Grade)
   if(trfilter?.length > 0 || (editFormData?.Grade == ''||editFormData?.Grade == null || editFormData?.Grade == undefined) ) {
    let filtertr = Add_tr_list?.map((item, index) =>
      index == editindex
        ? {
            ...item,
            EmpCode: (editFormData?.EmpCode !== null && editFormData?.EmpCode !== undefined) ? editFormData?.EmpCode : '',
            Grade: (editFormData?.Grade !== null && editFormData?.Grade !== undefined) ? editFormData?.Grade : '',
            GradeId: (editFormData?.Grade !== null && editFormData?.Grade !== undefined) ? editFormData?.GradeId || editFormData?.Id : '',
            Designation: (editFormData?.Designation !== null && editFormData?.Designation !== undefined) ? editFormData?.Designation :'',
          }
        : item
    );
    //console.log(filtertr)
    settr(filtertr);
    setShowedittr(false);
  } else {
    setgettrErrorMessagefunc(["Please select grade form the list"]);
    trfilter=[];
    setTimeout(() => { setgettrErrorMessagefunc([]);},2000)
  }
  };
  const clearTrFields = () => {
    seteditFormData(edittrval)
  }

  const error_close = () => {
    setgetErrorMessagefunc([]);
  };
  const g_handelChange = (e) => {
   // console.log("first")
    setcount(1);
    const { name, value } = e.target;
    seteditFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

   // console.log(value);
  };
 // console.log(editFormData);

  const handleAutoCompleteChange = (e) => {
   // console.log("1")
   // disable keyboard entry
   document.addEventListener('keydown', function(event) {
    // Check if the Enter key is pressed
    if (event.key === 'Enter') {
        // Prevent the default action
        event.preventDefault();
    }
});
    setcount(1);
   // console.log(e);
    let clienid = clientfulldetails[0][2].filter((item) => item.Grade === e.value);
    seteditFormData((prevState) => ({
      ...prevState,
      Grade: e.value, // Adjust this according to your data structure
      GradeId: clienid[0]?.Id,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // handle form submission logic here
  };
 // console.log(getErrorMessage)
  const confirm_clear = () => {
    setShowedittr(false);
  }

  const noconfirm_clear = () => {
    setgetErrorMessagefunc([]);
  }
  return (
    <>
       {gettrErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span> Error Message</span>{" "}
              
            </div>
            <div className="error_content t-center">
              {gettrErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {" "}
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )} 
      {getErrorMessage?.length > 0 && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center">
              {getErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {" "}
                  {item && ` ${item}`}
                </p>
              ))}
            </div>


              <button className="btn btn-sm btn-info  mt-n1 mb-2 mr-2  continue-but" onClick={confirm_clear}>
                Yes
              </button>
           

            <button className="btn btn-sm btn-warning mt-n1 mb-2  continue-but" onClick={noconfirm_clear}>
              No
            </button>
          </div>
        </div>
      )}
      
      <div className="addFieldsbg" /*style={{display: addifields ? 'flex': 'none'}}*/>
        <div className="AddFieldsBlock">
          <div className="AddFields">
            <div className="AddFieldsheader d-flex">
              <span>Edit guest details</span>{" "}
              <span className="d-flex ml-auto bg-light popup_Close" onClick={closeFields}>
                X
              </span>{" "}
            </div>

            <div className="AddFieldsbody customentry">
              <form onSubmit={handlesubmit}>
                <table className="table" style={{ marginBottom: "0px !important" }}>
                  <tbody>
                    <tr>
                      <td>
                        <span className="">Emp code</span>
                      </td>
                      <td>
                        <input
                          id="EmpCode"
                          className="form-control tredit"
                          name="EmpCode"
                          placeholder="EmpCode"
                          data-name="EmpCode"
                          type="text/x-template"
                          style={{ width: "100%" }}
                          value={editFormData?.EmpCode}
                          onChange={g_handelChange}
                          onInput={propInput}
                          />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="">Title</span>
                      </td>
                      <td>
                        <input
                          id="title"
                          className="form-control tredit trdisabled"
                          name="Title"
                          placeholder="Title"
                          data-name="Title"
                          type="text/x-template"
                          style={{ width: "100%" }}
                          value={editFormData?.Title}
                          // readOnly={true}
                          // onChange={g_handelChange}
                          onInput={propInput}
                          readOnly={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="">First Name</span>
                      </td>
                      <td>
                        <input
                          id="fname"
                          className="form-control tredit trdisabled"
                          name="fname"
                          placeholder="First Name"
                          data-name="FirstName"
                          type="text/x-template"
                          style={{ width: "100%" }}
                          value={editFormData?.FirstName}
                          // onChange={g_handelChange}
                          onInput={propInput}
                          readOnly={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="">Last Name</span>
                      </td>
                      <td >
                        <input
                          id="lname"
                          className="form-control tredit trdisabled"
                          name="lname"
                          placeholder="Last Name"
                          data-name="LastName"
                          type="text/x-template"
                          style={{ width: "100%" }}
                          value={editFormData?.LastName}
                          // onChange={g_handelChange}
                          onInput={propInput}
                          readOnly={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="">Grade</span>
                      </td>
                      <td>
                       
                        <AutoCompleteComponent
                          id="Grade1"
                          name="Grade"
                          value={editFormData?.Grade != 0 ? editFormData?.Grade : null}
                          placeholder="Select Grade"
                          className="form-control tredit"
                          dataSource={grades}
                          fields={{ value: "Grade" }} // Adjust this according to your data structure
                          change={handleAutoCompleteChange}
                          
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="">Designation</span>
                      </td>
                      <td>
                        <input
                          id="Designation"
                          className="form-control tredit"
                          name="Designation"
                          placeholder="Enter Designation"
                          data-name="Designation"
                          type="text/x-template"
                          style={{ width: "100%" }}
                          value={editFormData?.Designation}
                          onChange={g_handelChange}
                          onInput={propInput}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="">Email</span>
                      </td>
                      <td>
                        <input
                          id="email"
                          className="form-control tredit trdisabled"
                          name="email"
                          placeholder="Enter Your email"
                          data-name="email"
                          type="text/x-template"
                          style={{ width: "100%" }}
                          value={editFormData?.EmailId}
                          // onChange={g_handelChange}
                          onInput={propInput}
                          readOnly={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="">Mobile No</span>
                      </td>
                      <td>
                        <input
                          id="mobile"
                          className="form-control tredit trdisabled"
                          name="mobile"
                          placeholder="Enter mobile no"
                          data-name="MobileNo"
                          type="text/x-template"
                          style={{ width: "100%" }}
                          value={editFormData?.MobileNo}
                          onInput={propInput}
                          readOnly={true}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="2">
                        <div className="AddFieldsfooter">
                          <button className="btn btn-primary btn-sm" style={{padding: "5px 25px"}} onClick={saveTrFields}>
                            Add
                          </button>
                          {/* <button className="btn btn-warning btn-sm ml-2" onClick={clearTrFields}>
                            Clear
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default React.memo(EditTr);
