import React, { useContext, useEffect } from "react";
import { BookingContent } from "../bookingContext/BookingContext";
import "../../assets/scss/errormessage.scss";

const Errow_page = () => {
  const {
    err_msg,
    error_class_name,
    staydaystodisplayfunc,
    editorcancelenddatefunc,
    show_error_templatefunc,
    getErrorMessagefunc,
    error_msg_color,
    errorAcceptButtonfunc,
    errorbuttonvalidationmsg,
  } = useContext(BookingContent);
  let errorcontent = error_msg_color[0] !== undefined ? error_msg_color[0] : { content: "Error", color: "#ff0000" };
  const error_close = () => {
    show_error_templatefunc(false);
    errorAcceptButtonfunc(false);
    getErrorMessagefunc([]);
    errorbuttonvalidationmsg(false);   
    editorcancelenddatefunc(false);
    staydaystodisplayfunc("1");
  };
  // const confirm_msg = () => {
  //   show_error_templatefunc(false);
  //   errorAcceptButtonfunc(false);
  //   getErrorMessagefunc([]);
  //   errorbuttonvalidationmsg(true);
  //   staydaystodisplayfunc(staydayscount);

  //   editorcancelenddatefunc(true);
  // };
  useEffect(() => {
    setTimeout(() => {
      error_close();
    },2000);
  },[]);

  let error_msg = [...new Set(err_msg)].flat(Infinity);
  let { content, color } = errorcontent;
  return (
    <div className="error_message">
      <div className="error_Message_content">
        <div className="error_MSg_Con">
          <span style={{ color: `${color}` }}> {content} Message</span>{" "}
          {/* <div className="close_but bg-light" onClick={error_close}>
            X
          </div> */}
        </div>
        <div className="error_content">
          {[...new Set(error_msg)]?.map((item, i) => (
             item?.length > 0 &&  <p key={i} className={`${error_class_name} msg t-center`}>
               {` ${item}`}
            </p>
          ))}
          {/* <hr></hr> */}
          {/* {errorAcceptButton && (
            <button className="btn btn-sm btn-info float-right mt-n1 mb-2" onClick={confirm_msg}>
              Continue
            </button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Errow_page);
