import React from "react";
import { FormGroup, Progress, Label, Spinner } from "reactstrap";

const SpinnerTemplate = () => {
  return (
    <div className="spinner-class">
      <div className="bg-white p-2" style={{ boxShadow: "0px 0px 10px #bbb", borderRadius: "10px" }}>
        <Spinner size="sm" />
        <span> Loading... </span>
      </div>
    </div>
  );
};

export default React.memo(SpinnerTemplate);
