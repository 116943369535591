import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
//import './assets/scss/custom.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BookingProvider} from './rcomponents/bookingContext/BookingContext'
// ej2 dependencies
import { registerLicense } from '@syncfusion/ej2-base';
const Error404Modern = lazy(() => import("./pages/error/404-modern"));



 
// Registering Syncfusion license key.
registerLicense(process.env.REACT_APP_SYNC_KEY);

ReactDOM.render(
  <>
    <Suspense fallback={<div />}>
      <Router basename={`/`}>
        <Route render={({ location }) => (location.state && location.state.is404 ? <Error404Modern /> :<BookingProvider> <App /></BookingProvider>)} />
      </Router>
    </Suspense>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
