import React, { useContext, useEffect, useState, useRef } from "react";
import { BookingContent } from "../bookingContext/BookingContext";
import { Button } from "reactstrap";
import SpinnerTemplate from "../error_component/Spinner";
import "../../assets/scss/table.scss";

const HotelSearch = () => {
  const {
    ghName,
    gridoccupencydatafunc,
    getpropertynameandid,
    showGh,
    setShowGhfunc,
    getandsetGHName,
    is_loading,
    isLoadingfunc,
    getPropertyId,
    getPropertyDetails,
    gridstartenddate,
    gridstartend_date,
    dayscountfunc,
    initial_day,
    setSchedulerData,
    setSchedulerData_original_func,
  } = useContext(BookingContent);
  let adaptiveUI = false;
  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));

  // ghpreopretySearch
  const [ghpreopretySearch, setGhpreopretySearch] = useState("");
  let trselectref = useRef();
  const [iniDate, setIniDate] = useState(31);

  useEffect(() => {
    trselectref?.current?.focus();
  }, []);
  //popup close
  const Close = () => {
    //setShowGh(!showGh);
    setShowGhfunc(!showGh);

    setGhpreopretySearch("");
  };

  const resDate = () => {
    let w = window.innerWidth;
    if (w > 700 && w < 800) {
      setSchedulerData([]);
      setSchedulerData_original_func([]);
    }
    if (w > 760) {
      
        setIniDate(31);
        dayscountfunc(31);
     

      adaptiveUI = false;
    } else {
      dayscountfunc(7);
      adaptiveUI = true;
    }
  };
  //get selectProperty
  const selectProperty = (val) => {
    isLoadingfunc(true);
    // let stdate = document.querySelector("#STime")?.value.split("/");
    // let etdate = document.querySelector("#ETime")?.value.split("/");
    // set start and end date
    let s_date = new Date();
    let e_date = new Date().getTime() + (31 - 1) * 86400000;
    let getdate = new Date(e_date).getDate();
   let getmonth = new Date(e_date).getMonth() + 1;
    let getyear = new Date(e_date).getFullYear();
    getdate  = getdate < 10 ? "0" + getdate : getdate;
    getmonth = getmonth < 10 ? "0" + getmonth : getmonth;
    gridstartenddate({ ...gridstartend_date, FromDte: s_date, ToDte: e_date });
   
    let stdate = document.querySelector("#STime")?.value.split("/");
    let etdate = document.querySelector("#ETime")?.value.split("/");
    
    let gridData = {
      PropertyId: val?.Id,
      FromDte: stdate[1] + "/" + stdate[0] + "/" + stdate[2],
      ToDte: getmonth + "/" + getdate  + "/" + getyear ,
      PropertyName: val?.Property,
    };


    getPropertyDetails(gridData);

    gridoccupencydatafunc(gridData);

    getandsetGHName(val);

    getpropertynameandid(val);
    setShowGhfunc(!showGh);
    setGhpreopretySearch("");
    getPropertyId(val?.Id !== undefined && val?.Id);

    
    //console.log({ ...gridstartend_date, FromDte: s_date, ToDte: new Date(e_date) })
    //get property data for new booking
    resDate();
  
  };
  //filter the property list
  let filter_property =
    ghName[0] &&
    ghName[0]?.filter((item) => {
      if (ghpreopretySearch === "") {
        return item;
      } else if (
        item.Property.toLowerCase().includes(ghpreopretySearch.toLowerCase()) ||
        item.CityName.toLowerCase().includes(ghpreopretySearch.toLowerCase()) ||
        item.StateName.toLowerCase().includes(ghpreopretySearch.toLowerCase())
      ) {
        return item;
      }
    });

  return (
    <>
      {is_loading && <SpinnerTemplate />}
      <div className="changeEditor">
        <div className="editorBody" style={{ maxHeight: "60%", overflow: "hidden" }}>
          <div className="header">
            <span className="w-100">
              <span>Guest House List</span>

              <input
                type="search"
                name="ghpreopretySearch"
                defaultValue=""
                placeholder="Guest House Name Search"
                className="ghpropertySearch ml-1 mt-n1"
                onChange={(e) => setGhpreopretySearch(e.target.value)}
                ref={trselectref}
              />
            </span>
            <span className="d-flex ml-auto bg-light popup_Close" onClick={Close}>
              X
            </span>
          </div>
          <div className="body">
            <div className="h-100">
              <form className="tr_list_table new_tr_booking h-100">
                <div className="row h-100">
                  <div className="col-md-12 h-100">
                    <div className="change_editor" style={{ overflow: "hidden", height: "100%" }}>
                      <div className="o_flow" style={{ height: "95% !important" }}>
                        <table
                          role="table"
                          className="prop_search_table  table table-hover b-shadow mt-2 empTable w-100"
                        >
                          <thead>
                            <tr
                              role="row"
                              style={{ position: "sticky", top: -1, zIndex: 1000 }}
                              className="display_mobile_none"
                            >
                              <th role="columnheader" className="fw-bold" style={{ width: "55%" }}>
                                Guest House Name
                              </th>
                              {/* <th role="columnheader" className="fw-bold">
                                Property&nbsp;Id
                              </th>   */}
                              <th role="columnheader" className="fw-bold" style={{ width: "20%" }}>
                                City Name
                              </th>
                              <th role="columnheader" className="fw-bold" style={{ width: "20%" }}>
                                State Name
                              </th>
                              <th role="columnheader" className="fw-bold" style={{ width: "83px", maxWidth: "130px" }}>
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {filter_property?.length > 0 ? (
                              filter_property.map((item, i) => (
                                <tr key={i} role="row">
                                  <td role="cell">{item.Property}</td>
                                  {/* <td role="cell">{item.Id}</td>  */}
                                  <td role="cell">{item.CityName}</td>
                                  <td role="cell">{item.StateName}</td>
                                  <td role="cell">
                                    <Button color="primary" className="btn-sm" onClick={() => selectProperty(item)}>
                                      Select
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr role="row">
                                <td role="cell" colSpan={5} style={{ textAlign: "center", paddingTop: "50px" }}>
                                  No records found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(HotelSearch);
