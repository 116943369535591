import React, { useState,useContext } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import {BookingContent} from "../../../../rcomponents/bookingContext/BookingContext"

const User = () => {
  const {User_name, getLoginAuth,proxy_Url}  = useContext(BookingContent);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);
  const credentials = JSON.parse(localStorage.getItem("accessToken"));
  const username = credentials?.UserName.charAt(0).toUpperCase() + credentials?.UserName.slice(1);
  

  const handleSignout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessToken1");
    sessionStorage.removeItem("credentials");
    getLoginAuth({})
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
          <div className="user-info d-none d-md-block">
            
            <div className="user-name dropdown-indicator">Welcome {username}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
        {/* <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
             <div className="user-avatar">
            <span>{credentials.UserName.split(" ").map(val => val.slice(0,1))}</span>
            </div>
            <div className="user-info">
            <span className="lead-text"> Welcome {credentials.UserName}</span>
            </div> 

          </div>
        </div> */}
        {/* <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/user-profile-regular" icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem>
            <LinkItem link="/user-profile-setting" icon="setting-alt" onClick={toggle}>
              Account Setting
            </LinkItem>
            <LinkItem link="/user-profile-activity" icon="activity-alt" onClick={toggle}>
              Login Activity
            </LinkItem>
          </LinkList>
        </div> */}
        <div className="dropdown-inner">
        <LinkList>
            <a href={`${process.env.PUBLIC_URL}/auth-change-password`}>
              <Icon name="exchange"></Icon>
              <span>Change Password</span>
            </a>
          </LinkList>
          <LinkList>
            <a href={`${process.env.PUBLIC_URL}/auth-login`} onClick={handleSignout}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
