import React, { useEffect, useRef, useState, useContext, useCallback } from "react";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
//import TableComponent from "../editorTableComponent/TableComponent1";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";

const EditorTemplate = ({ props, trData, editordata }) => {
  //trform ref
  let trref = useRef();
  let trbookref = useRef();
  let trmaxref = useRef();
  let troccpencyref = useRef();
  let trclientref = useRef();
  let trccref = useRef();
  let endObj = useRef();
  let startObj = useRef();

  const {
    proxy_Url,
    getClientRequestfunc,
    ghroom_id,
    propertyID,
    clientID,
    token,
    setShowTrfunc,
    showTr,
    seteditordata,
    setEditordatafunc,
    client_id,
  } = useContext(BookingContent);

  //const [editorfielddata, setEditorfielddata] = useState(editordata);
  const [roomAvailable, setroomAvailable] = useState("");
  //const [sDate, setSDate] = useState("");
 // const [eDate, setEDate] = useState();
  const [editorInput, setEditorInput] = useState({
    TRrequestid: "",
    // BType: JSON.parse(localStorage.getItem("editorbktype")),
    // chkindate: JSON.parse(localStorage.getItem("sdate")),
    // chkoutdate: JSON.parse(localStorage.getItem("edate")),
    // guestAllowed: JSON.parse(localStorage.getItem("editormaxguest")),
    // occupancy: JSON.parse(localStorage.getItem("editoroccupency")),
    // ClientRequest: JSON.parse(localStorage.getItem("ClientRequest")),
    // ExtraccMail: JSON.parse(localStorage.getItem("ExtraccMail")),
    BType: seteditordata?.editorbktype,
    chkindate: seteditordata?.sdate,
    chkoutdate: seteditordata?.edate,
    guestAllowed: seteditordata?.editormaxguest,
    occupancy: seteditordata?.editoroccupency,
    ClientRequest: seteditordata?.ClientRequest,
    ExtraccMail: seteditordata?.ExtraccMail,
  });

  //format date
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }

  useEffect(() => {
  
    if (trData?.CheckInDate?.length > 2) {
      setEditorInput((prev) => ({
        ...prev,
        TRrequestid: trData.label,
        chkindate: trData.CheckInDate + " " + trData.CheckInTime,
        chkoutdate: trData.CheckOutDate + " " + trData.CheckOutTime,
      }));
    }

    return () => {
      setEditorInput([]);
    };
  }, [trData, editordata]);


  const checkroomavail = useCallback(async () => {
   // setroomAvailable("")
    if (propertyID[0]) {
      let bodyData = {
        PropertyId: propertyID[0],
        ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
        FromDt: seteditordata?.sdate,
        ToDt: seteditordata?.edate,
        RoomId: ghroom_id,
        //JSON.parse(localStorage.getItem("ghroomid")),
      };
    
      const headers = {
        "Content-Type": "application/json",
        "x-access-token": `${token}`,
      };
      await axios
        .post(`${proxy_Url}/RoomAvailableCheck1`, bodyData, { headers: headers })
        .then((res) => {
        
          setroomAvailable(" Available");
        
        })
        .catch((error) => {
          setroomAvailable(" not Available");
        
        });
    }
  }, [ghroom_id]);

  //checkroomavail();

  //tr state change to show
  const trClick = () => {
    // document.querySelector(".e-dlg-header").classList.add("sfd");
    setShowTrfunc(!showTr);
  };

  // date show on focus
  const onFocus = (args) => {
    endObj.show();
    document.querySelector("#EndTime").blur();
  };
  const onFocussdate = (args) => {
    startObj.show();
    document.querySelector("#StartTime").blur();
  };

  const EventDateChange = (e) => {
    let e_name = e.target.htmlattributes.name;

    if (e_name === "chkindate") {
      // localStorage.setItem("sdate", JSON.stringify(document.querySelector("#StartTime").value));
      setEditordatafunc({ ...seteditordata, sdate: document.querySelector("#StartTime").value });
    } else if (e_name === "chkoutdate") {
      // localStorage.setItem("edate", JSON.stringify(document.querySelector("#EndTime").value));
      setEditordatafunc({ ...seteditordata, sdate: document.querySelector("#EndTime").value });
    } else {
    }

    checkroomavail();
  };
  const EventChange = (e) => {
    let e_tar_name = e.target.name;
    let e_val = e.target.value;

    let bok_type = document.querySelector("#guestAllowed");

    // if ((e_tar_name = "BType")) {
    //   if (e_val === "Room") {
    //     bok_type.value = 2;
    //   } else if (e_val === "Bed") {
    //     bok_type.value = 1;
    //   } else if (e_val === "Blocked") {
    //     localStorage.setItem("editorbktype", JSON.stringify("Blocked"));
    //     bok_type.value = 0;
    //   } else {
    //   }
    // }
    if (e_tar_name == "ClientRequest") {
      //localStorage.setItem("ClientRequest", JSON.stringify(editorInput.ExtraccMail));
      getClientRequestfunc(editorInput.ExtraccMail);
    }
    if (e_tar_name == "ExtraccMail") {
      localStorage.setItem("ExtraccMail", JSON.stringify(editorInput.editorInput));
    }

    setEditorInput((prev) => ({ ...prev, [e_tar_name]: e_val }));
  };
  return (
    <div className="custom-event-editor" style={{ width: "100%", cellpadding: "5", position: "relative" }}>
      <div className="row" style={{ paddingBottom: "15px", display: "none !important" }}>
        <div className="col-md-3 blk-opacity">
          <label className="e-textlabel">
            <span onClick={trClick} className="ghlink ghselect font-weight-bold">
              Choose
            </span>{" "}
            TR Request
          </label>
          <input
            id="TRrequestid"
            ref={trref}
            className="e-travelRequest e-field e-input disble"
            type="text/x-template"
            fields={{ text: "TRrequestid", defaultValue: "TRrequestid" }}
            defaultValue={editorInput.TRrequestid}
            name="TRrequestid"
            style={{ width: "100%" }}
            readOnly
          />
        </div>
        <div className="col-md-3">
          <label className="e-textlabel">Booking Type</label>
          <DropDownListComponent
            ref={trbookref}
            id="EventType"
            placeholder="Choose Type"
            data-name="EventType"
            style={{ width: "100%" }}
            dataSource={["Room", "Bed", "Blocked"]}
            value={editorInput.BType || "Room"}
            onChange={EventChange}
            name="BType"
            className="e-field e-input disble"
          ></DropDownListComponent>
        </div>
        <div className="col-md-3 disble">
          <label className="e-textlabel">Check-In Date</label>
          <DateTimePickerComponent
            format="dd/MM/yyyy hh:mm a"
            id="StartTime"
            data-name="StartTime"
            onFocus={onFocussdate}
            ref={(date) => (startObj = date)}
            //ref={startObj}
            fields={{ text: "StartTime", defaultValue: "StartTime" }}
            //value={props.StartTime || props.startTime}
            value={editorInput.chkindate}
            {...props}
            style={{ width: "100%" }}
            className="e-datetimepicker e-field e-input disble"
            name="chkindate"
            onChange={EventDateChange}
            readOnly
          ></DateTimePickerComponent>
        </div>
        <div className="col-md-3 disble">
          <label className="e-textlabel">Check-Out Date </label>
          <DateTimePickerComponent
            format="dd/MM/yyyy hh:mm a"
            id="EndTime"
            data-name="EndTime"
            onFocus={onFocus}
            ref={(date) => (endObj = date)}
            value={editorInput.chkoutdate}
            {...props}
            style={{ width: "100%" }}
            className="e-datetimepicker"
            name="chkoutdate"
            onChange={EventDateChange}
          ></DateTimePickerComponent>
        </div>
        <div className="col-md-3 blk-opacity">
          <label className="e-textlabel">Max Guest Allowed</label>
          <input
            id="guestAllowed"
            ref={trmaxref}
            className="e-input disble"
            type="text"
            defaultValue={editorInput.guestAllowed || "2"}
            name="guestAllowed"
            style={{ width: "100%" }}
            onChange={EventChange}
            readOnly
          />
        </div>
        <div className="col-md-3 blk-opacity not-allowed">
          <label className="e-textlabel">Occupancy</label>
          <DropDownListComponent
            ref={troccpencyref}
            id="occupancy"
            placeholder=""
            data-name="occupancy"
            style={{ width: "100%" }}
            dataSource={["Single", "Double"]}
            value={editorInput.occupancy || "Single"}
            className="disble"
            name="occupancy"
            onChange={EventChange}
          ></DropDownListComponent>
        </div>

        <div className="col-md-3 blk-opacity">
          <label className="e-textlabel">Client Request/Ref. No </label>
          <input
            type="text"
            ref={trclientref}
            id="ClientRequest"
            className="e-input disble"
            name="ClientRequest"
            style={{ width: "97%" }}
            onChange={EventChange}
            defaultValue={editorInput.ClientRequest}
          />
        </div>
        <div className="col-md-3 blk-opacity">
          <label className="e-textlabel">Extra CC Email </label>
          <input
            type="text"
            ref={trccref}
            id="ExtraccMail"
            className="e-input disble"
            name="ExtraccMail"
            style={{ width: "97%" }}
            onChange={EventChange}
            defaultValue={editorInput.ExtraccMail}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center font-weight-bold text-danger mb-2">Room&nbsp;{roomAvailable}</div>
      </div>
      <div className="row tborderbottom blk-opacity">
        {/* <TableComponent
          trfulldata={trData}
          addifields={addifields}
          setAddifields={setAddifields}
          table_list_count={table_list_count}
          getsavetrdata={getsavetrdata}
        /> */}
      </div>
    </div>
  );
};

export default React.memo(EditorTemplate);
