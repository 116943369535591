import React, { useState, useEffect, useContext } from "react";
import { BookingContent } from "../bookingContext/BookingContext";

const BookingModifyCancel = ({showcancelpopup,showEditpopup,showroomshiftingmodify,editpopup}) => {

    const {
        booking_modify_cancel_data,
        changeeventdatafunc,
        selectmodifyoptionfunc
      } = useContext(BookingContent);
      const [submit,setSubmit] = useState("");
      const [selectoption, setselectoption] = useState('');
      
      let ghproperty_Name = document.querySelector("#ghproperty");

      const ClickSubmit = () => {
        showroomshiftingmodify(false);
        showEditpopup(false);
        showcancelpopup(false);
        console.log("hai");
        if(selectoption == "CancelBooking") {
            showcancelpopup(true);
            
            
        }
        else if(selectoption == "RoomShifting") {
            showcancelpopup(false);
            showroomshiftingmodify(true);
            changeeventdatafunc(editpopup);
            selectmodifyoptionfunc("Shift");
            
        }
        else {
            showcancelpopup(false);
            showroomshiftingmodify(true);
            changeeventdatafunc(editpopup);
            selectmodifyoptionfunc("Stay");
        }
      }
      const close = () => {
        showcancelpopup(false);
        showEditpopup(false);
        showroomshiftingmodify(false);
        console.log("close");
      };

      const handleGenderChange = (event) => {
        setselectoption(event.target.value); // Update state with selected gender
        if(event.target.value == "CancelBooking") {
        setSubmit(" Cancel Booking");
        showcancelpopup(true);
        showroomshiftingmodify(false);
        showEditpopup(false);
        }
        else if(event.target.value == "RoomShifting") {
            setSubmit(" Room Shifting"); 
            showEditpopup(false);
            showcancelpopup(false);
            showroomshiftingmodify(true);
            changeeventdatafunc(editpopup);
            selectmodifyoptionfunc("Shift");
           
        }
        else {
            setSubmit(" Room Modify"); 
            showEditpopup(false);
            showcancelpopup(false);
            showroomshiftingmodify(true);
            changeeventdatafunc(editpopup);
            selectmodifyoptionfunc("Stay");
            
        }

      };
  return (
    <div className="changeEditor">
    <div className="editorBody">
      <div className="header">
         <span>
         Manage Reservation for <b>{`${ghproperty_Name?.value} |
         ${ booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.ClientName !== undefined ?  booking_modify_cancel_data?.Result[0]?.ClientName : ""}
           
          `}</b>{" "}
        </span> 
        <span className="d-flex ml-auto bg-light popup_Close" onClick={close}>
          X
        </span>
      </div>
      <div className="body">
        <div className="row">
        <div className="col-md-12 col-lg-12 booking_input"style={{marginBottom:'20px'}}>
        <div className="Bookingmodifycancelblock" style={{paddingBottom:'20px'}}>
      <label>
        <input
          type="radio"
          value="BookingModify"
          name="modifyoption"
          checked={selectoption === 'BookingModify'}
          onChange={handleGenderChange} // Handle change event
          style={{ display: 'none' }}
        />
        &nbsp;&nbsp;Booking Modify
      </label>
      <label>
        <input
          type="radio"
          value="RoomShifting"
          name="modifyoption"
          checked={selectoption === 'RoomShifting'}
          onChange={handleGenderChange} // Handle change event
          style={{ display: 'none' }}
        />
        &nbsp;&nbsp;Room Shifting
      </label>
      <label>
        <input
          type="radio"
          value="CancelBooking"
          name="modifyoption"
          checked={selectoption === 'CancelBooking'}
          onChange={handleGenderChange} // Handle change event
          style={{ padding: '0 20px',display: 'none' }}
        />
        &nbsp;&nbsp;Cancel Booking
      </label>
    </div>
        </div>
        </div>
       
      </div>

      {/* <div className="footer">
       {submit !== '' && <input
          type="button"
          value={`Open ${submit}`}
          className="btn btn-success btn-sm mr-1"
          onClick={ClickSubmit}
        />}
      </div> */}
    </div>
  </div>
  )
}

export default BookingModifyCancel