import React from "react";

const ToolTipComponent = (props) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      addzero = d.getMinutes() + "0",
      cmin = addzero.length > 2 ? addzero.slice(0, -1) : addzero,
      duration = d.getHours() >= 12 ? "PM" : "AM";
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let result = [year, month, day].join("/");
    return [`${result} ${hours} : ${cmin} ${duration}`];
  }
  return (
    <div className="tooltip-wrap">
      <div className="content-area">
        {props.Type !== "Blocked" && (
          <>
            <div className="event-name">Booking Confirmation Number : {props.BookingCode}</div>
            {props?.BookingLevel === "Bed" && ( <div className="event-name">Title : {props.Title}</div> )}
            <div className="event-name">Guest Name : {props.Subject}</div>{" "}
            
          </>
        )}
        <div className="time">Check-In Date&nbsp;:&nbsp;{formatDate(props.StartTime).toLocaleString()}</div>
        <div className="time">Check-Out Date&nbsp;:&nbsp;{formatDate(props.EndTime).toLocaleString()}</div>
        
        {props.Type !== "Blocked" && (
          <>
            {" "}
            <div className="event-name">Booking Level : {props.BookingLevel}</div>
            <div className="event-name">Occupancy Type : {props.Occupancy}</div>
          </>
        )}
        <div className="event-name">Status : {props.Type}</div>
            {/*  <div className="event-name">RoomId : {props.RoomId}</div> 
        <div className="event-name">BedId : {props.BedId}</div> 
         <div className="event-name">RoomCapturedId : {props.RoomCapturedId}</div>   */}
         {props.Type === "Blocked" && (
         <div className="time">Remarks&nbsp;:&nbsp;{props.Remarks.split(" ").slice(0,10).join(" ")}</div>
         )}
         {/* <div className="event-name">Bedtype : {props.Bedtype}</div> */}
      </div>
    </div>
  );
};

export default React.memo(ToolTipComponent);
