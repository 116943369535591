import React, { useContext, useState, useRef, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { BookingContent } from "../bookingContext/BookingContext";
import { useDownloadExcel } from "react-export-table-to-excel";
import Errow_page from "../error_component/Errow_page";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import axios from "axios";
import { useCallback } from "react";
import * as XLSX from "xlsx";

const BookingDetails = () => {
  const {
    ghName,
    proxy_Url,
    getGhName,
    token,
    show_error_templatefunc,
    showerror_template,
    getErrorMessagefunc,
    errorClassNamefunc,
    isLoadingfunc,
    propertyloadfunc,
    propInput,
    set_Error_Message_func,
  } = useContext(BookingContent);
  const [formValues, setFormValues] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [DownloadData, setDownloadData] = useState([]);
  const [staycount, setStaycount] = useState([]);
  //const [Propertyload, setPropertyload] = useState([]);
  const [bookingfilter, setBookingfilter] = useState([]);
  const [roomType, setRoomType] = useState([]);
  const [roomNo, setRoomNo] = useState([]);
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [getsuccessErrorMessagefunc, setgetsuccessErrorMessagefunc] = useState([]);
  const [guestcheckedinfunc, setguestcheckedinfunc] = useState([]);
  const [futureerrormsg, setfutureerrormsg] = useState([]);
  const [newgetErrorMessagefunc, setnewgetErrorMessagefunc] = useState([]);
  const [err_success, seterr_successfunc] = useState("");
  const [errormsg, seterrormsg] = useState("");
  const [selected, setselected] = useState("");
  const [Successmsg, setSuccessmsg] = useState("");

  // excel download
  const tableRef = useRef();

  //date object
  let startObj = useRef();
  let propertyloadref = useRef();
  let bookingStatusref = useRef();
  let roomnoref = useRef();
  let roomtyperef = useRef();

  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };

  let booking_name = document.querySelector("#booking_ghname");
  let booking_date = document.querySelector("#bookedDate");
  let booking_status = document.querySelector("#bookingStatus");
  let room_type = document.querySelector("#room_type");
  let room_no = document.querySelector("#room_no");





  

  useEffect(() => {
    getGhName([]);
    // propertyload func call
    propertyloadfunc();
    // let bodyData = {
    //   UserId: ghuserId,
    // };

    // axios
    //   .post(`${proxy_Url}/PropertyLoad`, bodyData, { headers: headers })
    //   .then(({ data }) => {
    //     let Rdata = data.Result[0];
    //     // Rdata.map(item => delete item.Id)
    //
    //     getGhName(Rdata);
    //   })
    //   .catch((error) => console.log(error.message));
  }, []);

  const bookingdetailsdata = (val) => {
    let propertyId = ghName[0]?.filter((item) => item.Property.trim() === booking_name?.value.trim());
    if (propertyId.length > 0) {
      let bodyData = {
        PropertyId: `${propertyId[0].Id}`,
        Status: document.querySelector("#bookingStatus")?.value || `${formValues.bookingStatus}`,
        BookedDate: booking_date?.value,
        UserId: ghuserId,
        RoomNo: room_no?.value || "",
        RoomType: room_type?.value || "",
      };
      // console.log(bodyData)
      axios
        .post(`${proxy_Url}/bookingloads`, bodyData, { headers: headers })
        .then(({ data }) => {
          setBookingDetails([]);
          let Rdata = Array.isArray(data?.Result[0]) === true ? data?.Result[0] : [];
          if (Rdata.length > 0) {
          } else {
            setgetErrorMessagefunc("The booking details not available");
          }

          seterrormsg("Error");
          setTimeout(() => {
            error_close();
          }, 2000);
let addoccupency = Rdata?.map(item => ({...item,Occupancy:item?.Occupancy === null ? 'Sharing' : item?.Occupancy }))
//console.log(addoccupency)

setBookingDetails(addoccupency);
          setStaycount(data?.Result[1]);
        })
        .catch((error) => {
        //  console.log(error.message)
          setBookingDetails([]);
          setStaycount(0);
          setgetErrorMessagefunc("No data Found");
          setTimeout(() => {
            setgetErrorMessagefunc("");
          }, 2000);
        });
    } else {
      setgetErrorMessagefunc("Please select guest house name from list");
      seterrormsg("Error");
      setTimeout(() => {
        error_close();
      }, 2000);
    }
  };

  const onSubmit = useCallback(
    (e) => {
      // e.preventDefault();
      let bookingstatus = document.querySelector("#bookingStatus")?.value;

      if (booking_name?.value !== "" && bookingstatus !== "") {
        bookingdetailsdata("search");

        // room type load
        // let roomtypeData = {
        //   PropertyId: `${propertyId[0].Id}`,
        // };

        // axios
        //   .post(`${proxy_Url}/roomtypeload`, roomtypeData, { headers: headers })
        //   .then(({ data }) => {

        //     let room_type = data?.Result[0]?.map((item) => item.RoomType);
        //     setRoomType(room_type);
        //   })
        //   .catch((error) => {});
      } else {
        if (booking_name?.value === "") {
          setnewgetErrorMessagefunc(["Please select guest house name from list"]);
        }
        if (booking_status?.value === "") {
          setnewgetErrorMessagefunc((pre) => [...pre, "Please select property status"]);
        }

        seterrormsg("Error");
        setTimeout(() => {
          error_close();
          setnewgetErrorMessagefunc([]);
        }, 2000);
      }
    },
    [booking_name?.value, booking_date?.value, booking_status?.value, formValues]
  );
  const onFocussdate = () => {
    startObj.show();
    document.querySelector("#bookedDate").blur();
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }
  const [propertyid, set_Propertyid] = useState([]);

  const HandleChange = (event) => {
    // setBookingDetails([]);
    const name = event.target?.id;
    const value = event.target?.value;
    setFormValues((values) => ({ ...values, [name]: value }));
    // get values for room type
    if (name === "booking_ghname" && booking_name?.value !== "") {
      let property_id = ghName[0]?.filter((item, key) => item.Property === formValues.GHName);

      set_Propertyid(property_id);
    }

    //get room no

    // if (name === "room_type" && room_type?.value !== "")  {

    //   let roomnoData = {
    //     PropertyId: propertyid[0]?.Id,
    //     RoomType: room_type?.value,
    //   };

    //   axios
    //     .post(`${proxy_Url}/roomnoload`, roomnoData, { headers: headers })
    //     .then(({ data }) => {
    //       let room_no = data?.Result[0]?.map((item) => item.RoomNoId);
    //       setRoomNo(room_no);
    //     })

    // }
    // else {
    //   setRoomNo([])
    // }
  };
  const clearData = (event) => {
    let bookingStatus = document.querySelector("#bookingStatus");
    if (bookingStatus) {
      // console.log("first")
      document.querySelector("#bookedDate").value = formatDate(new Date());
      document.querySelector("#bookingStatus").value = "CheckIn";
      propertyloadref.current.value = "";
      setTimeout(() => {
        bookingStatusref.current.value = "CheckIn";
        setselected("CheckIn");
      }, 500);
      // roomnoref.current.value = "";
      roomtyperef.current.value = "";
      // window.location.reload();

      // setPropertyload([]);
      setBookingDetails([]);
    }
  };
  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: `${document.querySelector("#booking_ghname")?.value} - Booking Details`,
  //   sheet: "Users",

  // });
  const onDownload = () => {
    bookingdetailsdata("download");

    let propertyId = ghName[0]?.filter((item) => item.Property.trim() === booking_name?.value.trim());
    if (propertyId.length > 0) {
      let bodyData = {
        PropertyId: `${propertyId[0].Id}`,
        Status: document.querySelector("#bookingStatus")?.value || `${formValues.bookingStatus}`,
        BookedDate: booking_date?.value,
        UserId: ghuserId,
        RoomNo: room_no?.value || "",
        RoomType: room_type?.value || "",
      };
      // console.log(bodyData)
      axios
        .post(`${proxy_Url}/bookingloads`, bodyData, { headers: headers })
        .then(({ data }) => {
          
          let Rdata = Array.isArray(data?.Result[0]) === true ? data?.Result[0] : [];
          let exportvalues = Rdata?.map((val) => ({
            BookingNo: val.BookingCode,
            GuestName: val.GuestName,
           /* PropertyName: val.PropertyName,
            RoomType: val.RoomType,*/
            Grade:val.Grade !== 'null' && val.Grade !== 'N/A' && val.Grade !== 'NULL' ? val.Grade : '',
            Occupancy:val.BookingLevel === "Room" ? val.Occupancy:'Sharing',
            RoomNo: val.RoomNoId,
            CheckInDate: val.CheckInDt,
            CheckOutDate: val.CheckOutDt,
          }));
          if (exportvalues?.length > 0) {
            var wb = XLSX.utils.book_new(),
              ws = XLSX.utils.json_to_sheet(exportvalues);
            XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
            XLSX.writeFile(wb, `${document.querySelector("#booking_ghname")?.value} - Booking Details.xlsx`);
          } else {
            setgetErrorMessagefunc("The booking details not available");
            seterrormsg("Error");
            setTimeout(() => {
              error_close();
            }, 2000);
          }
        })
        .catch((error) => {
        //  console.log(error.message)
          setBookingDetails([]);
          setStaycount(0);
          setgetErrorMessagefunc("No data Found");
          setTimeout(() => {
            setgetErrorMessagefunc("");
          }, 2000);
        });
    }
  };

  const onDownloadfunc = () => {
    let propertyId = ghName[0]?.filter(
      (item) => item.Property.trim() === propertyloadref.current.value?.trim() && item
    );
    if (propertyloadref.current.value !== "" && propertyId[0]) {
      onDownload();
    } else {
      setgetErrorMessagefunc("Please select guest house name from list");
      seterrormsg("Error");
      setTimeout(() => {
        error_close();
      }, 2000);
    }
  };

  const selectOption = (val) => {
    isLoadingfunc(true);
    let bodyData = {
      BookingAssingedGuestId: val?.AssignId,
      BookingLevel: val?.BookingLevel,
      UserId: ghuserId,
    };


let getbookingdetailscount = bookingDetails?.filter(item => item.BookingCode === val.BookingCode)

    axios
      .post(`${proxy_Url}/checkin`, bodyData, { headers: headers })
      .then((res) => {
        //show_error_templatefunc(true);
        // setgetsuccessErrorMessagefunc([res.data.Result]);
        seterrormsg("Success");
         
        if (res.data.Result === "Guest Checked in successfully") {
          setguestcheckedinfunc([res.data.Result]);
         
          // console.log("first")
          setSuccessmsg("Success");
          seterr_successfunc("error_success");
          seterrormsg("Success");
        } else if (res.data.Result === "Future Check-In not allowed") {
          
          setfutureerrormsg([res.data.Result]);
          setSuccessmsg("Success");
          seterr_successfunc("error_error");
          seterrormsg("Error");
        } else {
          setgetsuccessErrorMessagefunc([res.data.Result]);
         
          setSuccessmsg("Error");
        }
        seterr_successfunc("");
        //set_Error_Message_func([res.data.Result]);

        //  errorClassNamefunc("error_success");

        isLoadingfunc(false);

        onSubmit();

        setTimeout(() => {
          setgetsuccessErrorMessagefunc([]);
          setguestcheckedinfunc([]);
          setfutureerrormsg([]);
        }, 2000);
      })
      .catch((error) => {

        show_error_templatefunc(true);
        if(getbookingdetailscount?.length > 1) {
          show_error_templatefunc(false);
          setguestcheckedinfunc(["Guest Checked in successfully"]);

          // console.log("first")
          setSuccessmsg("Success");
          seterr_successfunc("error_success");
          seterrormsg("Success");
          isLoadingfunc(false);

        onSubmit();
        setTimeout(() => {
         
          setguestcheckedinfunc([])
        }, 2000);
        }
       // console.log(error)
      });
  };

  const error_close = () => {
    setgetErrorMessagefunc([]);
  };

  const ghnamechange = () => {
    setBookingDetails([]);
    let roomno = document.querySelector("#room_no");
    document.querySelector("#room_type").value = "";
    if (roomno) {
      document.querySelector("#room_no").value = "";
    }
    let property_id = ghName[0]?.filter((item) => item.Property.trim() === booking_name?.value.trim());
    if (property_id?.length > 0) {
      let bodyData = {
        PropertyId: property_id[0]?.Id,
      };
      // console.log(bodyData)
      axios
        .post(`${proxy_Url}/roomtypeload`, bodyData, { headers: headers })
        .then(({ data }) => {
          let room_type = data?.Result[0]?.map((item) => item.RoomType);
          setRoomType(room_type);
        })
        .catch((error) => {});
    } else {
      setRoomType([]);
    }
    setselected("CheckIn");
   document.querySelector("#bookingStatus").value ="CheckIn";
   setFormValues(pre => [{...pre,bookingStatus:"CheckIn"}])
  };
  const ghroomnochange = () => {
    // setBookingDetails([]);
    let property_id = ghName[0]?.filter((item) => item.Property.trim() === booking_name?.value.trim());
    if (property_id?.length > 0 && room_type?.value !== "") {
      let bodyData = {
        PropertyId: property_id[0]?.Id,
        RoomType: room_type?.value,
      };

      axios
        .post(`${proxy_Url}/roomnoload`, bodyData, { headers: headers })
        .then(({ data }) => {
          let room_no = data?.Result[0]?.map((item) => item.RoomNoId);

          setRoomNo(room_no);
        })
        .catch((error) => {});
    } else {
      setRoomNo([]);
    }
  };
  const gridonChange = () => {
    // setBookingDetails([]);
  };
  // console.log(err_success)
const handleSelect = (selectedValue) => {
  setTimeout(() => {
  let property_id = ghName[0]?.filter((item) => item.Property.trim() === document.querySelector("#booking_ghname").value.trim());


  if (property_id?.length > 0) {
 
      let bodyData = {
        PropertyId: property_id[0]?.Id,
      };
      // console.log(bodyData)
      axios
        .post(`${proxy_Url}/roomtypeload`, bodyData, { headers: headers })
        .then(({ data }) => {
          let room_type = data?.Result[0]?.map((item) => item.RoomType);
          setRoomType(room_type);
        })
        .catch((error) => {});
    } else {
      setRoomType([]);
    }
    
      bookingStatusref.current.value = "CheckIn";
      setselected("CheckIn");
   document.querySelector("#bookingStatus").value ="CheckIn";
   setFormValues(pre => [{...pre,bookingStatus:"CheckIn"}])
  },100)
};
  return (
    <React.Fragment>
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con ${err_success}`}>
              <span> {errormsg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content">
              {[getErrorMessage]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {newgetErrorMessagefunc?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con ${err_success}`}>
              <span> {errormsg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content">
              {newgetErrorMessagefunc?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {getsuccessErrorMessagefunc?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con ${err_success}`}>
              <span> {`${Successmsg}`} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content">
              {getsuccessErrorMessagefunc?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {guestcheckedinfunc?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con error_success`}>
              <span> Success Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content">
              {guestcheckedinfunc?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {futureerrormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className={`error_MSg_Con`}>
              <span> Error Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content">
              {futureerrormsg?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      <Head title="Guest house booking"></Head>
      {showerror_template && <Errow_page />}
      <Content>
        <h3 className="page_title">Booking Details</h3>
        <form onSubmit={onSubmit}>
          <div className="bg-white p-3 card-bordered b-form-details">
            <div className="row">
              <div className="col-md-4">
                <label className="e-textlabel">
                  Guest House <span className="c-red">*</span>
                </label>

                <AutoCompleteComponent
                  id="booking_ghname"
                  value={formValues.GHName}
                  placeholder="Select the Guest House"
                  className="form-control"
                  onChange={ghnamechange}
                  dataSource={ghName && ghName[0]?.map((item, key) => item.Property)}
                  filtering={ghnamechange}
                  ref={propertyloadref}
                  options={ghName && ghName[0]?.map((item, key) => item.Property)}
                  name="GHName"
                  autoComplete="off"
                  // onInput={propInput}
                  // onSelect={ghSelect}
                  select={handleSelect}
                />
              </div>
              <div className="col-md-2">
                <label className="e-textlabel">
                  Status<span className="c-red">*</span>
                </label>
                <select
                  id="bookingStatus"
                  placeholder="Choose Type"
                  data-name="bookingStatus"
                  name="bookingStatus"
                  style={{ width: "100%" }}
                  value={formValues.bookingStatus}
                  ref={bookingStatusref}
                  onChange={HandleChange}
                  selected={selected}
                  className="form-control"
                >
                  <option value="CheckIn">Today’s arrival</option>
                  <option value="CurrentStay">In-house Guests</option>
                  <option value="CheckOut">Expected Departure</option>
                </select>
              </div>
              <div className="col-md-2">
                <label className="e-textlabel">Booked Date</label>
                <div className="form-control-wrap">
                  <DatePickerComponent
                    id="bookedDate"
                    format="dd/MM/yyyy"
                    data-name="bookedDate"
                    onFocus={onFocussdate}
                    onChange={HandleChange}
                    name="bookedDate"
                    ref={(date) => (startObj = date)}
                    value={formValues.bookedDate || new Date()}
                    className="form-control"
                  ></DatePickerComponent>
                </div>
              </div>
              <div className="col-md-3">
                <label className="e-textlabel">Room Type</label>

                <AutoCompleteComponent
                  id="room_type"
                  value={formValues.roomType}
                  placeholder="Select room type"
                  className="form-control"
                  onChange={ghroomnochange}
                  dataSource={roomType}
                  filtering={ghroomnochange}
                  ref={roomtyperef}
                  name="roomType"
                  //   onInput={propInput}
                />
              </div>
              {/* <div className="col-md-2 form-group">
                <label className="e-textlabel">Room No</label>
                <div className="form-control-wrap">
                  <AutoCompleteComponent
                    id="room_no"
                    value={formValues.RoomNo}
                    placeholder="Select room no"
                    className="form-control"
                    onChange={gridonChange}
                    dataSource={roomNo}
                    filtering={gridonChange}
                    ref={roomnoref}
                    name="RoomNo"
                    //onInput={propInput}
                  />
                </div>
              </div> */}
              <div className="col-md-12 text-center mt-3 border-top">
                <div className="btnGrp mt-2">
                  <button type="button" className="btn btn-sm btn-success m-1" onClick={onSubmit}>
                    Search
                  </button>
                  <button type="button" className="btn btn-sm btn-primary m-6" onClick={onDownloadfunc}>
                    Export
                  </button>
                  <button type="button" className="btn btn-sm btn-warning m-1" onClick={clearData}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {bookingDetails && bookingDetails.length > 0 ? (
          <>
            <div className="p-3">
              <div className="row my-2">
                <div className="col-6 col-md-6 bg-light p-1 text-center">
                  <div className="p-1 py-2">
                    {" "}
                    <b>Stay Count : {staycount && staycount[0]?.Count}</b>
                  </div>
                </div>
                <div className="col-6 col-md-6 bg-light p-1">
                  <div className="ml-auto p-1 py-1">
                    <input
                      type="text"
                      placeholder="Filter"
                      className="ml-auto form-control"
                      style={{ width: "100%", maxWidth: "200px" }}
                      onChange={(e) => setBookingfilter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 tr_list_table">
                <div className="o_flow h-26 datashow bg-white">
                  <table
                    ref={tableRef}
                    role="table"
                    className="table table-hover table-bordered tr_select booking_details b-shadow w-100"
                  >
                    <thead role="rowgroup">
                      <tr role="row" className="stickyheader">
                        <th role="columnheader">Booking&nbsp;No</th>
                        <th role="columnheader">Guest Name</th>
                        <th role="columnheader">Grade</th>
                        <th role="columnheader">Occupancy</th>
                        <th role="columnheader">Room No</th>
                        <th role="columnheader" style={{ width: "100px" }}>
                          Check-In&nbsp;Date
                        </th>
                        <th role="columnheader" style={{ width: "100px" }}>
                          Check-Out&nbsp;Date
                        </th>
                        <th role="columnheader" style={{ width: "130px" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      {bookingDetails &&
                        bookingDetails?.length > 0 &&
                        bookingDetails
                          .filter((item) => {
                            if (bookingfilter == "") {
                              return item;
                            } else if (
                              item?.BookingCode.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item?.GuestName.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item?.PropertyName.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item?.Grade.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item?.Occupancy.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item?.RoomNoId.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item?.CheckInDt.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item?.CheckOutDt.toLowerCase().includes(bookingfilter.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((val, index) => (
                            <tr role="row" key={index}>
                              <td role="cell">{val?.BookingCode}</td>
                              <td role="cell">{val?.GuestName}</td>
                              <td role="cell">{val?.Grade !== 'null' && val.Grade !== 'N/A' && val.Grade !== 'NULL' && val.Grade !== '' &&  val.Grade  }</td>
                              <td role="cell">{val?.Occupancy !== 'null' && val.Occupancy !== 'N/A' && val.Occupancy !== 'NULL' ? val.Occupancy:''}</td>
                              <td role="cell">{val?.RoomNoId}</td>
                              <td role="cell">{val?.CheckInDt}</td>
                              <td role="cell">{val.CheckOutDt}</td>
                              <td role="cell">
                                <button
                                  className="selectsingleTr btn btn-primary btn-sm"
                                  onClick={(e) => selectOption(val)}
                                  disabled={val.CurrentStatus === "CheckIn" ? true : false}
                                >
                                  Check-In
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p className="d-flex text-align-center justify-content-center mt-3">
            Select Guest House Name and click search to display data
          </p>
        )}
      </Content>
    </React.Fragment>
  );
};

export default BookingDetails;
