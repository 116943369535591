import React, { useState, useRef, useContext, useEffect, useCallback } from "react";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  PreviewCard,
  Icon,
  Button,
  Row,
  Col,
} from "../../components/Component";
import { FormGroup, Input, ButtonGroup } from "reactstrap";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";
import FileSaver from "file-saver";
import { Link} from "react-router-dom";

// const SelectHotelGrid = React.forwardRef(({
//  // showGh, setShowGh,
//   // getandsetGHName
// }, pop) => {
const SelectHotelGrid = () => {
  const {
    isLoadingfunc,
    property_details,
    GH_property_ClientName,
    proxy_Url,
    token,
    show_error_templatefunc,
    errorClassNamefunc,
    getErrorMessagefunc,
    setSchedulerData,
    ghName,
    //getGhName,
    loadspinloaderfunc,
    scheduledataoriginal,
    occupency_Data,
    set_occupency,
    setghOccupency,
    gridoccupencydatafunc,
    formatDateddmmyy,
    gridstartend_date,
    gridstartenddate,
    constgridenddate,
    constenddate,
    getPropertyDetails,
    // propnameid,
    showGh,
    setShowGhfunc,
    getandsetGHName,
    ghClientID,
    // clientID,
    showGhname,
    clientcustomfields,
    propertyloadfunc,
    getpropertynameandid,
    getsetentityname,
    getGHClientName,
    getnewbookingdata,
    getPropertyId,
    propertyID,
    ghaccessdatafunc,
    setSchedulerData_original_func,
    setOccupencyData,
    scheduler_Data,
    propInput,
    dayscountfunc,
    showintofunc,
    settestdatefunc,
    fromhomepagepropertydata,
    fromhomepagepropertyfunc,
    getclientid,
    setOccupancycheckflag,
    
  } = useContext(BookingContent);

  //get ghname property
  const [sm, updateSm] = useState(false);
  const [s_date, setSdate] = useState(new Date());
  const [e_date, setEdate] = useState(new Date(s_date.getTime() + (31 - 1) * 86400000));
  const [properrormsg, setproperrormsg] = useState([]);
  const [propname, setpropname] = useState("");
  const [selecterrormsg, setselecterrormsg] = useState([]);
  const [err_msg, seterr_msg] = useState("");
 
  let getproperty = [];
  // get occupency
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;

  let selectGHref = useRef();
  let Sdatref = useRef();
  let Edatref = useRef();
  let endtime;
  let startend_d;

  useEffect(() => {
    //setEdate(new Date(s_date.getTime() + (31 - 1) * 86400000));

    resDate();
    window.addEventListener("resize", resDate);
    if (property_details[0]?.Property) {
      document.querySelector("#ghproperty").value = property_details[0]?.Property;
      loadspinloaderfunc(true);
    }

    // propertyload func call
    propertyloadfunc();
    // get client id

    if (GH_property_ClientName[0] !== undefined && GH_property_ClientName[0]?.length !== 0) {
      let getclientid =
        GH_property_ClientName[0] &&
        GH_property_ClientName[0][0]?.filter((item) => item.ClientName === property_details[0]?.Property);
      ghClientID(getclientid[0]?.Id);
      clientcustomfields(getclientid[0]?.Id);
    }
    ghaccessdatafunc();
  }, [ghuserId]);

  const resDate = () => {
    let w = window.innerWidth;

    if (w > 760) {
      setEdate(new Date(s_date.getTime() + (31 - 1) * 86400000));
      //adaptiveUI = false;
    } else {
      setEdate(new Date(s_date.getTime() + (31 - 25) * 86400000));
      // adaptiveUI = true;
    }
  };
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };

  // show tr list
  const handleEntailmentRequest = (e) => {
    e.preventDefault();
  };

  //start date focus
  const startonFocus = (args) => {
    Sdatref.show();
    document.querySelector("#STime").blur();
  };
  //End date focus
  const EndonFocus = (args) => {
    Edatref.show();
    document.querySelector("#ETime").blur();
  };

  const showTRList = (e) => {
    e.preventDefault();
    //setShowGh(!showGh);
    setShowGhfunc(!showGh);
  };

  //get handleOccupency
  const handleOccupency = (val) => {
    
    if (scheduler_Data[0]?.roomData?.length > 0) {
    //  console.log(scheduledataoriginal[0]?.roomData,"scheduler_Data[0]?.roomData")
      isLoadingfunc(true);
      setghOccupency(val.target.checked);

      if (val?.target?.checked === false) {
        setSchedulerData(scheduledataoriginal[0]);
        //cursor events block
        // if (GH_property_ClientName[0]?.length === 0) {
        document.querySelector(".control-wrapper").classList.remove("cursorevents");
        // }
        setOccupancycheckflag(false)
        setTimeout(() => {
          isLoadingfunc(false);
        }, 2000);
      } else {
        setSchedulerData(occupency_Data[0]);
        setOccupancycheckflag(true)
        document.querySelector(".control-wrapper").classList.add("cursorevents");

        setTimeout(() => {
          isLoadingfunc(false);
        }, 2000);
      }
    } else {
      //show_error_templatefunc(true);
      errorClassNamefunc("error_warning3");
      getErrorMessagefunc([]);
      // getErrorMessagefunc(["Select the Guest House"]);
      setselecterrormsg(["Please select guest house name from list & submit"]);
      isLoadingfunc(false);
      seterr_msg("Error");
      setTimeout(() => {
        setselecterrormsg([]);
        
      }, 2000);
    }
  };

  // sumit values to grid data

  const submitgridvalue = useCallback(async () => {
    isLoadingfunc(true);
    getErrorMessagefunc([]);
    getErrorMessagefunc("");
    let getghname = document.querySelector("#ghproperty").value;

    let bodyData = {
      UserId: JSON.parse(localStorage.getItem("accessToken"))?.Id,
    };

    axios
      .post(`${proxy_Url}/PropertyLoad`, bodyData, { headers: headers })
      .then(({ data }) => {
        let Rdata = data.Result[0];
        // Rdata.map(item => delete item.Id)
        getproperty = Rdata;

        const propertyselect = getproperty !== undefined && getproperty?.filter((item) => item?.Property === getghname);
        if (propertyselect?.length !== 0) {
          //if (getghname !== "") {
          let stdate = document.querySelector("#STime")?.value.split("/");
          let etdate = document.querySelector("#ETime")?.value.split("/");

          let ss =
            new Date(etdate[2], parseInt(etdate[1]) - 1, etdate[0]).getTime() -
            new Date(stdate[2], parseInt(stdate[1]) - 1, stdate[0]).getTime();
          var Difference_In_Days = ss / (1000 * 3600 * 24);

          let w = window.innerWidth;

          if (w > 760) {
            dayscountfunc(Difference_In_Days + 1);
          } else {
            dayscountfunc(7);
          }

          let bodyData = {
            PropertyId: propertyselect[0]?.Id,
            FromDte: stdate[1] + "/" + stdate[0] + "/" + stdate[2],
            ToDte: etdate[1] + "/" + etdate[0] + "/" + etdate[2],
            PropertyName: propertyselect[0]?.Property,
            action: "delete",
          };
    
          getPropertyDetails(bodyData);
  
          gridoccupencydatafunc(bodyData);
          getpropertynameandid(propertyselect[0]);

          getPropertyId(propertyselect[0]?.Id);
 
          getsetentityname({
            PropertyId: propertyselect[0]?.Id,
            UserId: ghuserId,
          });
          let customfieldfilter = data?.Result[0]?.filter(item => item?.Property === document.querySelector("#ghproperty").value)
          getnewbookingdata(getclientid[0]?.Id, propertyselect[0]?.Id);

          
          clientcustomfields(customfieldfilter[0]?.Id);

          getandsetGHName(propertyselect[0]);
        } else {
          // getErrorMessagefunc(["Select the Guest House"]);
          // show_error_templatefunc(true);
     
          if(fromhomepagepropertydata?.length > 0) {
            let stdate = document.querySelector("#STime")?.value.split("/");
          let etdate = document.querySelector("#ETime")?.value.split("/");
            let bodyData = {
              PropertyId: fromhomepagepropertydata[0]?.PropertyId,
              FromDte: stdate[1] + "/" + stdate[0] + "/" + stdate[2],
              ToDte: etdate[1] + "/" + etdate[0] + "/" + etdate[2],
              PropertyName: propertyselect[0]?.Property,
              action: "delete",
            };
            gridoccupencydatafunc(bodyData);
          }
          else {
          errorClassNamefunc("error_warning3");
          setselecterrormsg(["Please select guest house name from list"]);
          isLoadingfunc(false);
          seterr_msg("Error");
          setTimeout(() => {
            setselecterrormsg([]);
            
          }, 2000);

          setSchedulerData({
            scheduleData: [],
            groupData: [],
            roomData: [],
          });
        }
        }

        //}
      })
      .catch((error) => {});

    setTimeout(() => {
      let roomnametitle = document.querySelector(".e-resource-left-td .e-resource-text");
      if (roomnametitle) {
        roomnametitle.innerHTML = "Room Names";
      }
    }, 2000);
  }, [JSON.parse(localStorage.getItem("accessToken"))?.Id]);

  const resetdate = () => {
    let s_date = new Date();
    let e_date = new Date().getTime() + (31 - 1) * 86400000;
    gridstartenddate({ ...gridstartend_date, FromDte: s_date, ToDte: e_date });
  };

  // clear data
  const clearData = () => {
    resetdate();
    getandsetGHName({});
    document.querySelector("#ghproperty").value = "";
    setSchedulerData({
      scheduleData: [],
      groupData: [],
      roomData: [],
    });
    getGHClientName([]);
  };

  const exportExcel = () => {
    let getghname = document.querySelector("#ghproperty")?.value;
    let propertyId = ghName[0]?.filter((item) => item.Property.trim() === getghname?.trim() && item);

    if (getghname !== "" && propertyId[0]) {
      isLoadingfunc(true);
      let st_date = document.querySelector("#STime")?.value.split("/");
      let ed_date = document.querySelector("#ETime")?.value.split("/");
      let bodyData = {
        PropertyId: property_details[0]?.PropertyId || propertyID[0],
        FromDte: st_date[1] + "/" + st_date[0] + "/" + st_date[2],
        ToDte: ed_date[1] + "/" + ed_date[0] + "/" + ed_date[2],
        UserId: ghuserId,
      };

      axios
        .post(`${proxy_Url}/GHReportExport`, bodyData, { headers: headers })
        .then(({ data }) => {
          FileSaver.saveAs(`${data.Result}`, `${data.FileName}`);
          isLoadingfunc(false);
          // console.log(data)
        })
        .catch((error) => {
         // console.log(error.message);
          isLoadingfunc(false);
        });
    } else {
      //show_error_templatefunc(true);
      errorClassNamefunc("error_warning4");
      getErrorMessagefunc([]);
      // getErrorMessagefunc(["Select the Guest House"]);
      setselecterrormsg(["Please select guest house name from list"]);
      isLoadingfunc(false);
      seterr_msg("Error");
      setTimeout(() => {
        setselecterrormsg([]);
        
      }, 2000);
    }
  };

  const gridonChange = (e) => {
   // resetdate();
   fromhomepagepropertyfunc();
   fromhomepagepropertyfunc([]);
   fromhomepagepropertydata.length = 0;

    setSchedulerData({
      scheduleData: [],
      groupData: [],
      roomData: [],
    });
    // setSchedulerData_original_func([]);
    // setOccupencyData({
    //   scheduleData: [],
    //   groupData: [],
    //   roomData: [],
    // })
    getErrorMessagefunc([]);
    getErrorMessagefunc("");
    setghOccupency(false);
    getGHClientName([]);
  };

  // property select array
  const propertyselect = ghName[0]?.map((item) => item?.Property);

  const setStartDate = (values) => {
    startend_d = formatDateddmmyy(values);
    let stdateformat = startend_d.split("/");
    let stdate = stdateformat[1] + "/" + stdateformat[0] + "/" + stdateformat[2];

    let w = window.innerWidth;

    if (w > 760) {
      endtime = new Date(values.getTime() + (31 - 1) * 86400000);
      //adaptiveUI = false;
    } else {
      //endtime = formatDateddmmyy(new Date(values.getTime() + (31 - 25) * 86400000));
      endtime = new Date(values.getTime() + (31 - 25) * 86400000);
      // adaptiveUI = true;
    }

    constgridenddate(endtime);
    gridstartenddate({ ...gridstartend_date, FromDte: values, ToDte: endtime });

    setSchedulerData({
      scheduleData: [],
      groupData: [],
      roomData: [],
    });
    resDate();
  };
  const setEndDate = (values) => {
    gridstartenddate({ ...gridstartend_date, ToDte: values });

    setSchedulerData({
      scheduleData: [],
      groupData: [],
      roomData: [],
    });
  };
  const setClientcustomfields = (e) => {
    let getclientid = GH_property_ClientName[0] && GH_property_ClientName[0][0].filter((item) => item.ClientName === e);
    ghClientID(getclientid[0]?.Id !== undefined && getclientid[0]?.Id);

    //working
    getnewbookingdata(getclientid[0]?.Id, propertyID[0]);
    clientcustomfields(getclientid[0]?.Id);
    
  };

  const error_close = () => {
    setselecterrormsg([]);
  };


const helpintro = () => {
  showintofunc(false);
  showintofunc(true);
  getGHClientName([])
  getPropertyDetails([{

    "PropertyName": "Test Property",
   
}])

settestdatefunc(new Date('08,01,2023'))
isLoadingfunc(true);
setTimeout(()=> {

 setSchedulerData({
 scheduleData: [{
  "BookingId": "1026302",
  "BookingCode": "625463",
  "RoomId": "1694",
  "BedId": "1793",
  "RoomName": "108",
  "GuestName": "Test bed booking",
  "CheckInDt": "2023-08-15",
  "CheckOutDt": "2023-08-17",
  "Type": "Booked",
  "Occupancy": "Sharing",
  "RoomType": "NonDedicated",
  "BookingLevel": "Bed",
  "ClientName": "Warbler Software Technology",
  "ChkId": null,
  "GHRoomid": 1,
  "GHgroupid": 1,
  "StartTime": "08/15/2023 12:00 PM",
  "EndTime": "08/17/2023 11:59 AM",
  "Start_Time": "12:00 PM",
  "End_Time": "11:59 AM",
  "Title": "Mr",
  "Remarks": "",
  "BlocktableId": "0",
  "Subject": "Test bed booking",
  "bedcount": 2
},{
  "BookingId": "1026017",
  "BookingCode": "625190",
  "RoomId": "1695",
  "BedId": "0",
  "RoomName": "1F - 109 - Single",
  "GuestName": "Test room booking",
  "CheckInDt": "2023-08-05",
  "CheckOutDt": "2023-08-09",
 
  "Type": "Booked",
  "Occupancy": "Single",
  "RoomType": "NonDedicated",
  "BookingLevel": "Room",
  "ClientName": "Warbler Software Technology",
  "ChkId": null,
  "GHRoomid": 2,
  "GHgroupid": 1,
  
  "StartTime": "08/05/2023 12:00 PM",
  "EndTime": "08/09/2023 11:59 AM",
  "Start_Time": "12:00 PM",
  "End_Time": "11:59 AM",
  "Title": "",
  "Remarks": "",
  "BlocktableId": "0",
  "Subject": "Test room booking",
  "bedcount": 2
},
{
  "BookingId": "1433",
  "BookingCode": "1433",
  "RoomId": "4592",
  "BedId": "1433",
  "RoomName": "1433",
  "GuestName": "Test block booking",
  "CheckInDt": "2023-08-10",
  "CheckOutDt": "2023-08-12",
  "Type": "Blocked",
  "Occupancy": "",
  "RoomType": "",
  "BookingLevel": "Blocked",
  "ClientName": "",
  "ChkId": null,
  "GHRoomid": 2,
  "GHgroupid": 1,
  "StartTime": "08/10/2023 12:00 PM",
  "EndTime": "08/12/2023 11:59 AM",
  "Start_Time": "12:00 PM",
  "End_Time": "11:59 AM",
  "Title": "",
  "Remarks": "",
  "BlocktableId": "1433",
  "Subject": "Test block booking",
  "bedcount": 2
}],
 groupData: [{
    "text": "DS3,DS4(Male/Female)",
    "ProjectGroupId": 1,
    "id": 1
},
{
  "text": "DS5,DS6,DS9(Male/Female)",
  "ProjectGroupId": 2,
  "id": 2
}
],
 roomData: [{
  "RoomId": 1729,
  "text": "3F - 308 - Single",
  "id": 1,
  "groupId": 1,
  "BedCount": "2"
},
{
  "RoomId": 1730,
  "text": "3F - 309 - Single",
  "id": 2,
  "groupId": 1,
  "BedCount": "2"
}],
            });
          },500)
          
}

// const handleSelect = () =>  {
//   setTimeout(() => {
//   let propertyId = ghName[0]?.filter((item) => item.Property.trim() === document.querySelector("#ghproperty").value.trim() && item);

//   let stdate = document.querySelector("#STime")?.value.split("/");
//   let etdate = document.querySelector("#ETime")?.value.split("/");
//   console.log(propertyId)
//     let bodyData = {
//       PropertyId: propertyId[0]?.Id,
//       FromDte: stdate[1] + "/" + stdate[0] + "/" + stdate[2],
//       ToDte: etdate[1] + "/" + etdate[0] + "/" + etdate[2],
//       PropertyName: propertyId[0]?.Property,
//       action: "delete",
//     };
//     gridoccupencydatafunc(bodyData);
//     let customfieldfilter = ghName[0]?.filter(item => item?.Property === document.querySelector("#ghproperty").value)
//     console.log(customfieldfilter[0]?.Id)     
//     getnewbookingdata(getclientid[0]?.Id, propertyId[0]?.Id);
// console.log(getclientid[0]?.Id, propertyId[0]?.Id)
          
//           clientcustomfields(customfieldfilter[0]?.Id);
         
//         }, 100);
// }

  return (
    <>
      {selecterrormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content">
              {selecterrormsg?.map((item, i) => (
                <p className="t-center" key={i}>
                  {" "}
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      <form onSubmit={handleEntailmentRequest}>
        <BlockHead size="sm" className={"pb-1"}>
          <BlockBetween className={"mobile-bg-color"}>
            <BlockHeadContent>
              <BlockTitle page>Reservation </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={"d-flex b-indicator"}>
              {/* <div className="room-green_sample">Room</div>
              <div className="room-blue_sample">Bed</div> */}
              <div className="room-green_sample">Booked</div>
              <div className="Checked_in">Checked-In</div>
              <div className="room-black_sample">Blocked</div>
              {/* <div className="room-black_sample_btn" onClick={helpintro}>Need help?</div> */}
            </BlockHeadContent>
      
            <BlockHeadContent className='selectentityname'>
            <span className="form-label">Select entity name
                  </span>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                   
                      <FormGroup>
                        <div className="form-control-wrap">
                            
                          <div className="form-control-select">
                            <Input
                              type="select"
                              name="select"
                              ref={selectGHref}
                              id="Entity_select"
                              style={{ width: "300px" }}
                              placeholder="Select Entity Name"
                              onChange={(e) => setClientcustomfields(e?.target?.value)}
                              disabled={
                                GH_property_ClientName[0] && GH_property_ClientName[0][0]?.length > 1 ? false : true
                              }
                            >
                              {GH_property_ClientName[0] && GH_property_ClientName[0][0]?.length > 1 ? (
                                <>
                                  {" "}
                                  <option value="Select Entity Name">Select Entity Name</option>
                                  {GH_property_ClientName[0] &&
                                    GH_property_ClientName[0][0]?.map((item, i) => (
                                      <option key={i} value={item?.ClientName}>
                                        {item?.ClientName}
                                      </option>
                                    ))}
                                </>
                              ) : GH_property_ClientName[0] && GH_property_ClientName[0][0]?.length === 1 ? (
                                GH_property_ClientName[0] &&
                                GH_property_ClientName[0][0]?.map((item, i) => (
                                  <>
                                    <option key={i} value={item?.ClientName}>
                                      {item?.ClientName}
                                    </option>
                                    <option value="Select Entity Name">Select Entity Name</option>
                                  </>
                                ))
                              ) : (
                                <option value="Select Entity Name">Select Entity Name</option>
                              )}
                            </Input>
                          </div>
                        </div>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {/* Scheduler header */}
        <Block size="lg" className={"pb-0"}>
          <PreviewCard className="property_search_page">
            <Row className="g-4 pb-0">
              <Col lg="2" md="6" sm="6" className={"p-1"}>
                <FormGroup className="form-group ">
                  <label className="form-label" htmlFor="full-name-1">
                    Start Date
                  </label>
                  <div className="form-control-wrap">
                    <DatePickerComponent
                      id="STime"
                      data-name="STime"
                      format="dd/MM/yyyy"
                      className="e-field"
                      ref={(scope) => (Sdatref = scope)}
                      name="STime"
                      value={gridstartend_date?.FromDte || new Date()}
                      placeholder="Start Date"
                      onChange={(e) => setStartDate(e.target.value)}
                      focus={startonFocus}
                    ></DatePickerComponent>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="2" md="6" sm="6">
                <FormGroup className="form-group">
                  <label className="form-label" htmlFor="email-address-1">
                    End Date
                  </label>
                  <div className="form-control-wrap">
                    <DatePickerComponent
                      id="ETime"
                      data-name="ETime"
                      format="dd/MM/yyyy"
                      className="e-field"
                      ref={(scope) => (Edatref = scope)}
                      name="ETime"
                      min={gridstartend_date?.FromDte !== undefined ? new Date(gridstartend_date?.FromDte) : new Date()}
                      value={new Date(gridstartend_date?.ToDte !== undefined ? gridstartend_date?.ToDte : e_date)}
                      placeholder="End Date"
                      max={new Date(constenddate !== undefined ? constenddate : e_date)}
                      onChange={(e) => setEndDate(e.target.value)}
                      focus={EndonFocus}
                    ></DatePickerComponent>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="4" md="6" sm="12" xs="6">
                <FormGroup className="form-group">
                  <label className="form-label">
                    Guest House Name<span className="c-red"> * </span>
                    <span className="ghlink ghselect" onClick={showTRList}>
                      {" "}
                      Choose{" "}
                    </span>
                  </label>
                  <AutoCompleteComponent
                    id="ghproperty"
                    value={property_details[0]?.Property || showGhname}
                    placeholder="Select Guest House"
                    className="form-control"
                    onChange={gridonChange}
                    dataSource={propertyselect}
                    filtering={gridonChange}
                     // onInput={fromhomepagepropertyfunc()}
                    // select={handleSelect}
                  />
                </FormGroup>
              </Col>
              <Col lg="4" md="6" sm="12" xs="6" className="col-12 no-padd mt-0">
                <Row>
                  <Col lg="4" md="4" sm="6" xs="6" className="col-5 no-padd">
                    <FormGroup
                      className="form-group"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <label className="form-label">View&nbsp;Occupancy</label>
                      <ul className="custom-control-group g-3 align-center ocupency-group">
                        <li className={"pt-0 mt-n1"}>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control view_occupancy"
                              onChange={handleOccupency}
                              id="com-email-1"
                              // defaultChecked={set_occupency === true ? true : false}
                              checked={set_occupency === true ? true : false}
                            />

                            <label className="custom-control-label" htmlFor="com-email-1">
                              <span className="ml-2"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </FormGroup>
                  </Col>

                  {/* <Col  lg="7" sm="4" md="7" className="col-6 d-center exp-clr-but"> */}
                  <Col lg="7" sm="6" md="7" className="col-7 p-0 m-md-auto mb-2 mb-md-0 reservationsubmit">
                    <label className="form-label col-md-12" style={{ lineHeight: "28px" }}>
                      &nbsp;
                    </label>

                    <button className="btn btn-primary btn-sm mr-1" onClick={submitgridvalue}>
                      Submit
                    </button>
                    <button className="btn btn-warning btn-sm mr-1" style={{ paddingLeft: "12px" }} onClick={clearData}>
                      Clear
                    </button>
                    <button className="btn btn-primary btn-sm" onClick={exportExcel}>
                      Export
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </PreviewCard>
        </Block>
      </form>
    </>
  );
};

export default React.memo(SelectHotelGrid);