import React, { useContext, useEffect, useRef } from "react";
import { BookingContent } from "../bookingContext/BookingContext";

const GuestListShow = ({ geGguestselect, guestselect }) => {
  const { setShowGuestselectfunc } = useContext(BookingContent);
  let guestfocus = useRef();
  const closeFields = () => {
    // setShowGuestselect(false);
    //123 setShowGuestselectfunc(false);
  };
  // useEffect(() => {
  //   guestfocus?.current?.focus();
  // },[])

  // const guestselectfunc = (value) => {
  //   console.log(value)
  //   let filterguest = guestselect.filter(item => item?.EmpCode === value?.EmpCode && item) 

  //   geGguestselect(filterguest)
  // }

  return (
    <div className="position-relative">
      <div className="guest_list guestshow">
        <div className="AddFieldsheader d-flex">
          <span>Employee Details</span>{" "}
          <span className="d-flex ml-auto bg-light popup_Close" onClick={closeFields}>
            X
          </span>{" "}
        </div>
        <div className="body">
          <table role="table" className="table tr_select blocked_booking">
            <thead className="sticky_header">
              <tr>
                <th>Employee Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              {guestselect &&
                guestselect.map((item, index) => (
                  <tr key={index} onClick={() => geGguestselect(item)}>
                    <td>
                      <span className="tr_guest_list">{item.label}</span>
                    </td>
                    <td>
                      <button className="btn btn-primary btn-xs" ref={guestfocus} onClick={() => geGguestselect(item)}>
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(GuestListShow);
