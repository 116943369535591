import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";
import Homepage from "../pages/Homepage";
import Reservation from "../rcomponents/bookingmodule/Reservation";
import Component from "../pages/components/Index";
import Alerts from "../pages/components/Alerts";
import DataTablePage from "../pages/components/table/DataTable";
import Modals from "../pages/components/Modals";
import Popovers from "../pages/components/Popovers";
import Progress from "../pages/components/Progress";
import Spinner from "../pages/components/Spinner";
import Blank from "../pages/others/Blank";
import BookingDetails from "../rcomponents/bookingDetails/BookingDetails";
import ServiceEntry from "../rcomponents/serviceEntry/ServiceEntry";
import Availability from "../rcomponents/availability/Availability";
import RoomBlock from "../rcomponents/editorTableComponent/RoomBlock";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Dashboards*/}
        <Route exact path={`${process.env.PUBLIC_URL}/_blank`} component={Blank}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/reservation`} component={Reservation}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/booking-details`} component={BookingDetails}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/service-entry`} component={ServiceEntry}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/availability`} component={Availability}></Route>

        {/*Application*/}

        {/*Components*/}
        <Route exact path={`${process.env.PUBLIC_URL}/components`} component={Component}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/alerts`} component={Alerts}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/components/modals`} component={Modals}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/components/popovers`} component={Popovers}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/progress`} component={Progress}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/components/spinner`} component={Spinner}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/table-datatable`} component={DataTablePage}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/room-block`} component={RoomBlock}></Route>

        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Homepage}></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
