import React from "react";
import {Link} from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img className="logo-light logo-img" style={{background:'none'}} src='https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png' alt="logo" />
    </Link>
  );
};

export default Logo;
