import React from 'react'
import { Spinner } from "reactstrap";

const LoadSpinner = () => {
  return (
    <div className="spinner-class">
      <Spinner size="sm" />
  <span> Loading... </span></div>
  )
}

export default React.memo(LoadSpinner)