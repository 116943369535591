import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import "../../assets/scss/changeEditor.scss";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import CustomFieldsEdit from "../editorTableComponent/CustomFieldsEdit";
import { setTime } from "@syncfusion/ej2-react-schedule";
import moment from "moment";

//date refresh when error shows
const ModifyShfiting = ({ showChangedatafunc, showroomshiftingmodify,showChangedata, editpopup,modifyshiftdata }) => {
  const {
    setSchedulerData,
    scheduledataoriginal,
    gridoccupencydatafunc,
    property_details,
    setCancelEvent,
    token,
    show_error_templatefunc,
    getErrorMessagefunc,
    errorClassNamefunc,
    isLoadingfunc,
    proxy_Url,
    change_event_data,
    editmodificationfunc,
    editmodificationdata,
    staychangedate,
    changeeventdatafunc,
    editdatafunc,
    booking_modify_cancel_data,
    ghaccessdata,
    set_Error_Message_func,
    propertyID,
    setErrorMsgClassfunc,
    client_id,
    clientID,
    setstaychangedate,
    clientcustomfieldscollection,
    edit_cus_data,
    propInput,
    stayshift,
    GH_property_ClientName,
    stayshiftfunc,
    roomshiftdata,
    changeroomid,
    editmodicheckfunc,
    editmodicheck,
    roomshiftingdatafunc,
    set_modify_Error_Message_func,
    scheduler_Data,
    selectmodifyoption,
    seteventchangedatefunc,
    changeroomidfunc,
  } = useContext(BookingContent);

  //set session id

  //date change
  let chkindate = null;
  //let chInDate = "";
  let chkoutdate = null;

  //let chkOutDate = "";
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  let remarks = useRef();
  let intimeobj = useRef();
  let outdateObj = useRef();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chInDate, setchInDate] = useState("");
  const [chkOutDate, setchkOutDate] = useState("");
  const [minchkOutDate, setminchkOutDate] = useState("");
  const [e_customFields, setE_customFields] = useState(false);
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [get_ErrorMessage, setget_ErrorMessagefunc] = useState([]);
  const [getroomerrormsg, setgetroomerrormsg] = useState([]);
  const [err_msg, seterr_msg] = useState("");
  const [edit_modi_check, setedit_modi_check] = useState([]);
  const [changelog, setchangelog] = useState([]);
  const [showchangelog, setshowchangelog] = useState(false);
  const [msg_option, setmsg_option] = useState([]);
  const [bedstay, setbedstay] = useState("");
  const [bedidavailablecheckcount, setbedidavailablecheckcount] = useState([]);
  const [editmodificationfilterdata, seteditmodificationfilterdata] = useState([]);
  const [time_changeout, settime_changeoutfunc] = useState("");
  const [end_Date, setend_Date] = useState(null);
  const [choutdate, setchoutdate] = useState(null);
  const [changeroomno, setchangeroomno] = useState(0);
  const [time_change, settime_changefunc] = useState("");
  const [Fromroom, setFromroom] = useState("");
  const [guesterrormsg, setguesterrormsg] = useState([]);
  const [timediff, settimediff] = useState(5);
  const [modifycuccessMessage, setmodifycuccessMessage] = useState([]);
  const [roomdata, setroomdata] = useState([]);
  const [changeouttime, setchangeouttime] = useState("11:59:00 AM");

  // gender check for bed booking
  const [gender, setgender] = useState([]);
  const [toRoomdata, settoRoomdata] = useState([]);
  const [genderbchked, setGenderbched] = useState(0);
  const [genderbooking, setgenderbooking] = useState(0);
  const [displaygender, setdisplaygender] = useState("");
  const [maxtime, setMaxTime] = useState("11:00:00 PM");
  const [changeroomtext, setchangeroomtext] = useState("");
  const [e_date, sete_date] = useState("");

  let ghproperty_Name = document.querySelector("#ghproperty");
  let ghproperty_Entity = document.querySelector("#Entity_select");
  let endmodifyObj = useRef();
  let startmodifyObj = useRef();

  let mincheckindate;
  let checkid = "drag";
  let bedidcheck = 0;
  let doublebeddata = [];
 

  //get bet count

  useEffect(() => {
    setfourhrs();
  }, []);


  useEffect(() => {
    // let editmodificationdata = booking_modify_cancel_data;
    let changeddate = [];
    if(booking_modify_cancel_data?.Result?.length > 1 ){
      changeddate = booking_modify_cancel_data?.Result.filter(item => item.RoomId == modifyshiftdata?.BedId)[0];
    }
    else {
      changeddate = booking_modify_cancel_data?.Result[0];
    } 
   

    let result = scheduler_Data[0]?.scheduleData?.filter(
      (item) =>
        item.BookingCode == changeddate?.BookingCode &&
        item.BookingId == changeddate?.BookingId &&
        normalizeName(item.GuestName) == normalizeName(changeddate?.Guest)
    );

let roomname = scheduler_Data[0]?.roomData.filter(item => item.RoomId == modifyshiftdata?.RoomId );

  

    changeeventdatafunc(result[0]);
    editmodificationfunc(result[0]);
    seteventchangedatefunc(result[0]);
    changeroomidfunc(result[0]);
    setFromroom(roomname[0]?.text)

    dragChangeData(result[0], {});

    chkindate = result[0]?.CheckInDt?.split("-");
    chkoutdate = result[0]?.CheckOutDt?.split("-");

   

    setchInDate(chkindate[2] + "/" + chkindate[1] + "/" + chkindate[0]);
    setchkOutDate(chkoutdate[2] + "/" + chkoutdate[1] + "/" + chkoutdate[0]);
    setminchkOutDate(chkindate[1] + "/" + chkindate[2] + "/" + chkindate[0]);
    if(GH_property_ClientName[0][1][0].CheckIn24Hrs === true) {

    setEndDate(new Date(chkoutdate[1] + "-" + chkoutdate[2] + "-" + chkoutdate[0]));
   
    }
    else {
    let  previousdate = new Date(chkoutdate[1] + "-" + chkoutdate[2] + "-" + chkoutdate[0]);
    
    if(changeddate?.CheckInDt == changeddate?.CheckOutDt)
      {
        setTimeout(() => {
          setEndDate(new Date(new Date(previousdate).setDate(new Date(previousdate).getDate() + 1)));
        }, 500);
      
      }
      }


    //previous date to call function
    let previousdate = new Date(chkoutdate[1] + "-" + chkoutdate[2] + "-" + chkoutdate[0]);
    previousdate = new Date(new Date(previousdate).setDate(new Date(previousdate).getDate() - 1));
    setStartDate(new Date(chkoutdate[1] + "-" + chkoutdate[2] + "-" + chkoutdate[0]));
    
    setTimeout(() => {
      setStartDatefunc(previousdate);
      roomavailablecheck();
    }, 400);
// check room available for room shifting
    
  }, [booking_modify_cancel_data]);

  useEffect(() => {
    setTimeout(() => {
      let st_date = document.querySelector("#edit_s_date")?.value;
      let e_date = document.querySelector("#edit_e_date")?.value;
      if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs !== true && st_date === e_date) {
        let stdate = st_date.split("/");
        let splitstdate = `${stdate[1]},${stdate[0]},${stdate[2]}`;

        //setEndDate(new Date(new Date(splitstdate).setDate(new Date(splitstdate).getDate() + 1)));
      }

      if (
        change_event_data?.Type === "CheckIn" /* &&
        document.querySelector("#edit_s_date").value == chInDate &&
        document.querySelector("#edit_e_date").value == chkOutDate*/
      ) {
        seterr_msg("Error");
        setget_ErrorMessagefunc([
          `Check-In date ${
            GH_property_ClientName[0][1][0].CheckIn24Hrs === true ? " & Check-In time" : ""
          } cannot be modified`,
        ]);
        // setget_ErrorMessagefunc([`The checkIn date has been confirmed and cannot be changed`]);

        setTimeout(() => {
          setget_ErrorMessagefunc([]);
        }, 2000);
      }
    }, 300);
    setTimeout(() => {
      checkgendercheckfunc();
      timecheck({
        intime: timeformatcheck(document.querySelector("#checkintime")?.value),
        outtime: timeformatcheck(document.querySelector("#checkouttime")?.value),
      });
    }, 2100);
  }, [change_event_data]);

  //order the name to compare
  function normalizeName(nameString) {
    return nameString
      ?.split(",")
      .map((name) => name.trim()) // Trim whitespace from each name
      .sort() // Sort the names alphabetically
      .join(","); // Join them back into a string
  }

  const checkgendercheckfunc = () => {
    let checkgender_data;
    let result_data;

    if (change_event_data?.BookingLevel === "Bed") {
      // gender check
      let sdate = document.querySelector("#edit_s_date")?.value.split(" ");
      let stime = document.querySelector("#checkintime")?.value;
      let edate = document.querySelector("#edit_e_date")?.value.split(" ");
      let etime = document.querySelector("#checkouttime")?.value;
      let e_time;
      if (etime === undefined) {
        stime = setstaychangedate?.Start_Time;
        e_time = setstaychangedate?.End_Time;
      } else {
        e_time = etime?.includes(":59") ? etime.replace(":59", ":59:00") : etime.replace(":00", ":00:00");
      }

      let gender_bodyData = {
        PropertyId: `${propertyID[0]}`,
        ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,

        FromDt: sdate[0] + " " + stime.replace(":00", ":00:00"),

        ToDt: edate[0] + " " + e_time,
        RoomId: changeroomid[0]?.RoomId || setstaychangedate?.RoomId,
      };

      let genderchkresult = bedbookinggendercheck(gender_bodyData);

      genderchkresult
        .then((result) => {
          // Access the result here
          result_data = result?.Result;

          //!== result?.Result[0][0]?.Title))
          if (result_data !== "No records found") {
            checkgender_data = change_event_data?.Title !== result_data[0][0]?.Title[0];

            if (result_data !== "No records found") {
              checkgender_data = change_event_data?.Title !== result_data[0][0]?.Title[0];

              if (checkgender_data === true) {
                setGenderbched(1);
                isLoadingfunc(false);
                setdisplaygender(result_data[0][0]?.Title[0] === "Mr" ? "Male" : "Female");
              }
            }
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error(error);
          isLoadingfunc(false);
        });
    }
  };
  //, startDate, endDate, time_change, time_changeout

  const bedbookinggendercheck = (item) => {
    setgender([]);
    let result = axios
      .post(`${proxy_Url}/Bedbookinggendercheck`, item, { headers: headers })
      .then(async (res) => {
        if (res?.data?.Result === "No records found") {
          setgender([]);
        } else {
          setgender(res?.data?.Result);
        }

        return res?.data;
      })
      .catch((error) => {
        console.log(error.message);
      });

    return result;
  };

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };

  //start date focus
  const startonFocusmodify = (args) => {
    startmodifyObj.show();
    document.querySelector("#edit_s_date").blur();
  };
  //End date focus
  const EndonFocusmodify = (args) => {
    endmodifyObj.show();

    document.querySelector("#edit_e_date").blur();
  };

  const changeDatafunc = () => {
    if (changelog?.length > 0) {
      setshowchangelog(true);
    } else {
      showChangedatafunc(false);
      changeeventdatafunc([]);
      setCancelEvent(false);
      setSchedulerData(scheduledataoriginal[0]);
    }
  };

  const dragChangeData = (data, roomdata) => {
    let getguestid = {
      BookingId: data?.BookingId ?? change_event_data.BookingId,
      FirstName: data?.GuestName || change_event_data.GuestName || change_event_data.Subject,
      UserId: ghuserId,
    };

    axios
      .post(`${proxy_Url}/BookingAssignedid`, getguestid, { headers: headers })
      .then((res) => {
        // editdatafunc(res.data);
        doublebeddata = res.data;
      })
      .catch((error) => {});

    
    let bodyData = {
      RoomId: data?.RoomId,
      BookingCode: data?.BookingCode,
      UserId: ghuserId,
      guestname: data?.Guest || data?.GuestName,
    };

    axios
      .post(`${proxy_Url}/GetBookingModificationDetails`, bodyData, { headers: headers })
      .then((res) => {
        // editdatafunc(res.data);

        let editfilter = res.data?.Result?.filter(
          (item) => parseInt(item.RoomId) == parseInt(change_event_data?.BedId)
        );

        if (res.data?.Result?.length > 1 && change_event_data?.BookingLevel === "Bed") {
          let beddatadouble = {
            ...res.data,
            CustomFieldDetails: {
              ...res.data.CustomFieldDetails,

              [0]: [
                {
                  GuestName: doublebeddata.Result[0][0].FirstName,
                  Column1: doublebeddata.Result[0][0].Column1,
                  Column2: doublebeddata.Result[0][0].Column2,
                  Column3: doublebeddata.Result[0][0].Column3,
                  Column4: doublebeddata.Result[0][0].Column4,
                  Column5: doublebeddata.Result[0][0].Column5,
                  Column6: doublebeddata.Result[0][0].Column6,
                  Column7: doublebeddata.Result[0][0].Column7,
                  Column8: doublebeddata.Result[0][0].Column8,
                  Column9: doublebeddata.Result[0][0].Column9,
                  Column10: doublebeddata.Result[0][0].Column10,
                  BookingAssingedId: doublebeddata.Result[0][0].guestassignid,
                  Title: doublebeddata.Result[0][0].Title,
                },
              ],
            },
          };

         // editmodificationfunc(beddatadouble);
          seteditmodificationfilterdata(editfilter);
        } else {

          let data = res.data.Result.filter(item => item.RoomId == modifyshiftdata?.BedId)[0]
         let data1 = {...res.data,Result:[data]}
         if(res.data.Result.length > 1){
          editmodificationfunc(data1)
         }
         else {
          editmodificationfunc(res.data);
         }
          seteditmodificationfilterdata(editfilter);

        }

        //  if(stayshift === 'dragevent'){
        setedit_modi_check([]);

        //modification data check start

        // setedit_modi_check([]);

        let sd = document.querySelector("#edit_s_date")?.value.split("/");
        let ed = document.querySelector("#edit_e_date")?.value.split("/");

        let stime = document.querySelector("#checkintime")?.value;

        let etime = document.querySelector("#checkouttime")?.value;
        if (etime === undefined) {
          stime = change_event_data?.Start_Time;
          etime = change_event_data?.End_Time;
        }

        if (change_event_data?.BookingLevel === "Bed") {
          let bodydata = {
            BookingId: change_event_data?.BookingId,
            RoomId:
              change_event_data?.BookingLevel === "Bed"
                ? roomdata[0]?.RoomId || changeroomid[0]?.RoomId || change_event_data?.RoomId
                : change_event_data?.BedId,

            CheckInDt: sd[2] + "-" + sd[1] + "-" + sd[0] + " " + stime,
            CheckOutDt: ed[2] + "-" + ed[1] + "-" + ed[0] + " " + etime,
            BedId: editfilter[0]?.BookingAssingedId,
          };

          setbedidavailablecheckcount([]);
          if (selectmodifyoption === "Shift") {
            axios
              .post(`${proxy_Url}/GHRoomShiftingBedLoad`, bodydata, { headers: headers })
              .then((res) => {
                setedit_modi_check(res.data?.Result[0][0]);

                bedidcheck = res.data?.Result[0][0];
                editmodicheckfunc(res.data?.Result[0][0] !== undefined && res.data?.Result[0][0]);

                let bedavailablecount = res.data?.Result[0]?.filter(
                  (item) => parseInt(item?.RoomId) == parseInt(change_event_data?.BedId)
                );

                setbedidavailablecheckcount(bedavailablecount);
                if (bedavailablecount?.length > 0) {
                  setedit_modi_check(bedavailablecount);
                  bedidcheck = bedavailablecount;

                  stayshiftfunc("dragevent");

                  setbedstay("bedStay");
                } else {
                  setbedstay("bedShift");
                }
              })
              .catch((error) => {});
            // bed shift hide 123
          }
        }

        //modification data end

          // gridoccupencydatafunc(bodyData1);
      })
      .catch((error) => {});
  };
  const setfourhrs = () => {
    setTimeout(() => {
      let sdate = document.querySelector("#edit_s_date")?.value.split(" ");
      let edate = document.querySelector("#edit_e_date")?.value.split(" ");
      let checkintime = document.querySelector("#checkintime")?.value;
      checkintime = checkintime === undefined ? change_event_data?.Start_Time : checkintime;
      const dynamicTime = checkintime;

      // Split the time string into hours, minutes, and period (AM/PM)
      
      if (dynamicTime) {
        const [timeHours, timeMinutes, period] = dynamicTime && dynamicTime.split(/:| /);
     

      // Convert hours and minutes to numbers
      let hours = parseInt(timeHours);
      let minutes = parseInt(timeMinutes);
      settime_changefunc(change_event_data?.Start_Time);

      // Adjust hours for PM time
      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      } else {
      }
   
      // Create a new Date object representing the current date and time
      let n_date = new Date();
      let m_date = new Date();
      let spl_sdate = sdate[0] != undefined && sdate[0].split("/");
      let spl_edate = edate[0] != undefined && edate[0].split("/");

      if (
        GH_property_ClientName[0][1][0].CheckIn24Hrs === true &&
        (spl_sdate[0] === spl_edate[0] && spl_sdate[1] === spl_edate[1] && spl_sdate[2] === spl_edate[2]) !== false
      ) {
        /// Create a new Date object representing the current date and time
        // Dynamic time value

        // Set the time to the current date
        n_date.setHours(hours + 4, minutes);
        setchangeouttime(n_date);
        settime_changeoutfunc(n_date);
        m_date.setHours(19, 0);
        setMaxTime(m_date);
      } else {
        n_date.setHours(0, 0);
        setchangeouttime(n_date);

        m_date.setHours(23, 0);
        setMaxTime(m_date);
        n_date.setHours(11, 59);
        settime_changeoutfunc(n_date);
      }
    }
    }, 2000);
  };

  const editmodifyavailable = () => {};

  const dateconversionyymmdd = (value) => {
    var d = new Date(value),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    isLoadingfunc(true);
    setTimeout(() => {
      setend_Date(endDate);

      setfourhrs();
      isLoadingfunc(false);
    }, 2000);
  }, [endDate]);

  useEffect(() => {
    //call editmodifyavailable

    if (ghaccessdata[0]?.ModifyResFlg === false) {
      setgetErrorMessagefunc([]);
      setgetErrorMessagefunc(["You dont have access to modify this booking"]);
      setTimeout(() => {
        setgetErrorMessagefunc([]);
        error_close();
      }, 2000);
    }

    setTimeout(() => {
      let get_change_date = staychangedate;

      setStartDate(new Date(get_change_date.start_date));

      if (get_change_date.start_date === get_change_date.end_date) {
        setEndDate(new Date(get_change_date.diff_date));
      } else {
        setEndDate(new Date(get_change_date.end_date));
      }

      isLoadingfunc(false);
    }, 100);

    let ss = change_event_data?.CheckInDt;
    let es = change_event_data?.CheckOutDt;
    if (ss) {
      let s_date = ss.split("/");
      let s_date_split = s_date[1] + "/" + s_date[0] + "/" + s_date[2];

      setStartDate(s_date_split);
      let e_date = es.split("/");
      let e_date_split = new Date(e_date[1], e_date[0], e_date[2]);
      setEndDate(e_date_split);
    }
    dragChangeData(change_event_data);
  }, [staychangedate]);

  const splitdatearray = (data) => {
    if (data instanceof Date) {
      // If data is a Date object, extract day, month, and year
      let date = data.getDate();
      let month = data.getMonth() + 1; // Get month (0-indexed, so +1)
      month = month < 10 ? "0" + month : month; // Ensure two-digit month
      let year = data.getFullYear();
      return [date, month, year]; // Return as an array [DD, MM, YYYY]
    } else {
      // If it's a string, assume it's in DD/MM/YYYY format and split accordingly
      let splitdata = data.split("/");
      return [splitdata[0], splitdata[1], splitdata[2]]; // Return as [DD, MM, YYYY]
    }
  };

  

  const setStartDatefunc = (e) => {
    setStartDate(new Date(e));
    let edate = new Date(new Date(e).setDate(new Date(e).getDate() + 1));
    setEndDate(edate);
    // setStartDate
  };

  const editCustomField = () => {
    setE_customFields(!e_customFields);
  };

  const onSubmit = (data) => {};

  const checkbooking = () => {
    let room_shifting = document.querySelector("#room_shifting")?.value;
   
    if(room_shifting == "Select Room") {
     let get_remarks_data = document.querySelector("#remarks");
     let value = get_remarks_data?.value;
     // console.log(value)
      seterr_msg("Error");
      setget_ErrorMessagefunc(["Select the room for shifting"]);
      if (value == "" || value == undefined) {
        setget_ErrorMessagefunc(pre => [...pre,`Please enter modification remarks`]);
      }
      setTimeout(() => {
        setget_ErrorMessagefunc([]);
        seterr_msg("");
      }, 2000);
    }
    else {
    if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && timediff < 4 === true) {
      // setget_ErrorMessagefunc(["Minimum 4 hours required for booking"]);
      seterr_msg("Error");
      setTimeout(() => {
        setget_ErrorMessagefunc([]);
        seterr_msg("");
      }, 1000);
      isLoadingfunc(false);
    } else {
      checkModify();
    }
  }
  };

  const roomnotavailablecall = () => {
    let s_date = document.querySelector("#STime")?.value.split("/");
    let e_date = document.querySelector("#ETime")?.value.split("/");

    let bodyData1 = {
      PropertyId: property_details[0]?.Id || propertyID[0],
      FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
      ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
      PropertyName: property_details[0]?.Property || document.querySelector("#ghproperty")?.value,
    };

    gridoccupencydatafunc(bodyData1);
  };

  const checkModify = async () => {
    isLoadingfunc(true);
    let soutput_date = document.querySelector("#edit_s_date").value;
    let eoutput_date = document.querySelector("#edit_e_date").value;

    let s_datecheck = soutput_date.split("/");
    let s_date_splitcheck = s_datecheck[1] + "/" + s_datecheck[0] + "/" + s_datecheck[2];
    let e_datecheck = eoutput_date.split("/");
    let e_date_splitcheck = e_datecheck[1] + "/" + e_datecheck[0] + "/" + e_datecheck[2];

    //const d = new Date("03,24,2023");
    const dt1 = new Date(`${s_datecheck[1]},${s_datecheck[0]},${s_datecheck[2]},`);
    const dt2 = new Date(`${e_datecheck[1]},${e_datecheck[0]},${e_datecheck[2]},`);
    let modifydatediff = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    //check for same id

    let blocknameadd;
    if (roomshiftdata[0]?.text.includes("- Single")) {
      let roomname = roomshiftdata[0]?.BlockName + " - " + roomshiftdata[0]?.text.replace("- Single", "");
      blocknameadd = roomname.includes("Apartment") ? roomname.replace("Apartment", "") : roomname;
    } else if (roomshiftdata[0]?.text.includes("- Double")) {
      let roomname = roomshiftdata[0]?.BlockName + " - " + roomshiftdata[0]?.text.replace("- Double", "");
      blocknameadd = roomname.includes("Apartment") ? roomname.replace("Apartment", "") : roomname;
    } else {
      let roomname = roomshiftdata[0]?.BlockName + " - " + roomshiftdata[0]?.text;
      blocknameadd = roomname.includes("Apartment") ? roomname.replace("Apartment", "") : roomname;
    }

    let frmroomid =
      setstaychangedate?.BookingLevel === "Bed"
        ? parseInt(setstaychangedate?.BedId)
        : parseInt(editmodificationdata?.Result[0]?.RoomId);
    let toroomid =
      roomshiftdata?.length > 0
        ? roomshiftdata[0]?.RoomId
        : setstaychangedate?.BookingLevel === "Bed"
        ? stayshift === "resizeevent"
          ? parseInt(editmodificationdata?.Result[0]?.RoomId)
          : parseInt(edit_modi_check?.RoomId)
        : parseInt(editmodificationdata?.Result[0]?.RoomId);
    let roomdata = {
      BookingId: editmodificationdata?.Result[0]?.BookingId,
      BookingLevel: editmodificationdata?.Result[0]?.BookingLevelId, // Room or Bed
      CheckInDt:
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && setstaychangedate?.BookingLevel === "Room"
          ? document.querySelector("#edit_s_date").value
          : soutput_date,
      CheckOutDt:
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && setstaychangedate?.BookingLevel === "Room"
          ? document.querySelector("#edit_e_date").value
          : eoutput_date,
      ChkInTime:
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && setstaychangedate?.BookingLevel === "Room"
          ? document.querySelector("#checkintime").value
          : editmodificationdata?.Result[0]?.CheckInTime1,
      ChkOutTime:
        // change_event_data?.Type === "CheckIn" ? document.querySelector("#checkintime").value :
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && setstaychangedate?.BookingLevel === "Room"
          ? document.querySelector("#checkouttime").value
          : editmodificationdata?.Result[0]?.CheckOutTime1,

      FromRoomId:
        setstaychangedate?.BookingLevel === "Bed"
          ? parseInt(setstaychangedate?.BedId)
          : parseInt(editmodificationdata?.Result[0]?.RoomId),
      ToRoomId:
        editmodificationdata?.Result[0]?.CurrentStatus === "CheckIn"
          ? frmroomid
          : roomshiftdata?.length > 0
          ? roomshiftdata[0]?.RoomId
          : setstaychangedate?.BookingLevel === "Bed"
          ? stayshift === "resizeevent"
            ? parseInt(editmodificationdata?.Result[0]?.RoomId)
            : parseInt(edit_modi_check?.RoomId)
          : parseInt(editmodificationdata?.Result[0]?.RoomId),
      RoomCaptured:
        1 || stayshift === "resizeevent"
          ? 1
          : 2 || editmodificationdata?.Result[0]?.RoomCapturedId || edit_modi_check?.RoomId
          ? 2
          : 1,
      CurrentStatus: editmodificationdata?.Result[0]?.CurrentStatus,
      TariffMode: editmodificationdata?.Result[0]?.TariffMode,
      ServiceMode: editmodificationdata?.Result[0]?.ServiceMode,
      Remarks: document.querySelector("#remarks").value,
      Type:
        editmodificationdata?.Result[0]?.CurrentStatus === "CheckIn"
          ? "Stay"
          : frmroomid == toroomid
          ? "Stay"
          : roomshiftdata?.length > 0
          ? "Shift"
          : (soutput_date === chInDate || eoutput_date === chkOutDate) === true
          ? "Stay"
          : "Shift",
      ToRoomNo:
        roomshiftdata?.length > 0
          ? blocknameadd.trim()
          : //roomshiftdata[0]?.text
            (edit_modi_check?.label !== undefined && edit_modi_check?.label) || staychangedate?.RoomName,
      UserId: ghuserId,

      Column1: editmodificationdata?.CustomFieldDetails[0][0]?.Column1 || "",
      Column2: editmodificationdata?.CustomFieldDetails[0][0]?.Column2 || "",
      Column3: editmodificationdata?.CustomFieldDetails[0][0].Column3 || "",
      Column4: editmodificationdata?.CustomFieldDetails[0][0].Column4 || "",
      Column5: editmodificationdata?.CustomFieldDetails[0][0].Column5 || "",
      Column6: editmodificationdata?.CustomFieldDetails[0][0].Column6 || "",
      Column7: editmodificationdata?.CustomFieldDetails[0][0].Column7 || "",
      Column8: editmodificationdata?.CustomFieldDetails[0][0].Column8 || "",
      Column9: editmodificationdata?.CustomFieldDetails[0][0].Column9 || "",
      Column10: editmodificationdata?.CustomFieldDetails[0][0].Column10 || "",
      BookingAssingedId:
        editmodificationdata?.CustomFieldDetails[0][0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0]?.BookingAssingedId ||
        "",
      Column21: "",
      Column22: "",
      Column23: "",
      Column24: "",
      Column25: "",
      Column26: "",
      Column27: "",
      Column28: "",
      Column29: "",
      Column30: "",
      BookingAssingedId2: 0,
    };
    //editmodificationfilterdata

    let beddata = {
      BookingId: editmodificationfilterdata[0]?.BookingId ?? editmodificationdata?.Result[0].BookingId,
      BookingLevel: editmodificationfilterdata[0]?.BookingLevelId ?? editmodificationdata?.Result[0].BookingLevelId, // Room or Bed
      FromRoomId: parseInt(setstaychangedate?.BedId),
      ToRoomId: parseInt(setstaychangedate?.BedId),
      Type: selectmodifyoption,
      RoomCaptured: editmodificationfilterdata[0]?.RoomCapturedId ?? editmodificationdata?.Result[0].RoomCapturedId,
      ToRoomNo:
        (edit_modi_check?.label !== undefined && edit_modi_check?.label) ||
        staychangedate?.RoomName ||
        editmodificationdata?.Result[0].RoomNoId,

      CheckInDt: soutput_date ?? editmodificationdata?.Result[0].CheckInDt,
      CheckOutDt: eoutput_date ?? editmodificationdata?.Result[0].CheckOutDt,
      ChkInTime:
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true
          ? document.querySelector("#checkintime").value
          : editmodificationfilterdata[0]?.CheckInTime1 ?? editmodificationdata?.Result[0].CheckInTime1,
      ChkOutTime:
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true
          ? document.querySelector("#checkouttime").value
          : //change_event_data?.Type === "CheckIn" ? document.querySelector("#checkintime").value :
            editmodificationfilterdata[0]?.CheckOutTime1 ?? editmodificationdata?.Result[0].CheckOutTime1,

      CurrentStatus: editmodificationfilterdata[0]?.CurrentStatus ?? editmodificationdata?.Result[0].CurrentStatus,
      TariffMode: editmodificationfilterdata[0]?.TariffMode ?? editmodificationdata?.Result[0].TariffMode,
      ServiceMode: editmodificationfilterdata[0]?.ServiceMode ?? editmodificationdata?.Result[0].ServiceMode,
      Remarks: document.querySelector("#remarks").value,
      // Type: setstaychangedate?.BookingLevel ==="Room" ? (soutput_date === chInDate || eoutput_date === chkOutDate) === true ? "Stay" : "Shift" :bedstay === "bedStay" ? "Stay" : "Shift",

      UserId: ghuserId,
      Column1: editmodificationdata?.CustomFieldDetails[0][0]?.Column1 || "",
      Column2: editmodificationdata?.CustomFieldDetails[0][0]?.Column2 || "",
      Column3: editmodificationdata?.CustomFieldDetails[0][0].Column3 || "",
      Column4: editmodificationdata?.CustomFieldDetails[0][0].Column4 || "",
      Column5: editmodificationdata?.CustomFieldDetails[0][0].Column5 || "",
      Column6: editmodificationdata?.CustomFieldDetails[0][0].Column6 || "",
      Column7: editmodificationdata?.CustomFieldDetails[0][0].Column7 || "",
      Column8: editmodificationdata?.CustomFieldDetails[0][0].Column8 || "",
      Column9: editmodificationdata?.CustomFieldDetails[0][0].Column9 || "",
      Column10: editmodificationdata?.CustomFieldDetails[0][0].Column10 || "",
      BookingAssingedId:
        editmodificationfilterdata[0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0][0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0]?.BookingAssingedId ||
        "",
      Column21: "",
      Column22: "",
      Column23: "",
      Column24: "",
      Column25: "",
      Column26: "",
      Column27: "",
      Column28: "",
      Column29: "",
      Column30: "",
      BookingAssingedId2: 0,
    };

  
    let diffroomdata = {
      BookingId: editmodificationdata?.Result[0]?.BookingId,
      BookingLevel: setstaychangedate?.BookingLevel, //BedorRoom

      CheckInDt:
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && setstaychangedate?.BookingLevel === "Room"
          ? document.querySelector("#edit_s_date").value
          : soutput_date,
      CheckOutDt:
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && setstaychangedate?.BookingLevel === "Room"
          ? document.querySelector("#edit_e_date").value
          : eoutput_date,
      ChkInTime:
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true
          ? document.querySelector("#checkintime").value
          : editmodificationdata?.Result[0].CheckInTime,
      ChkOutTime:
        //change_event_data?.Type === "CheckIn" ? document.querySelector("#checkintime")?.value :
        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true
          ? document.querySelector("#checkouttime").value
          : editmodificationdata?.Result[0].CheckOutTime,

      FromRoomId:
        setstaychangedate?.BookingLevel === "Bed"
          ? parseInt(setstaychangedate?.BedId)
          : parseInt(editmodificationdata?.Result[0]?.RoomId),
      
      ToRoomId: change_event_data?.BookingLevel === "Room" ? changeroomno : edit_modi_check?.RoomId,
      RoomCaptured: editmodificationfilterdata[0]?.RoomCapturedId || editmodificationdata?.Result[0]?.RoomCapturedId,
      CurrentStatus: "Booked",
      TariffMode: "Bill to Client",
      ServiceMode: "Direct",
      Remarks: document.querySelector("#remarks")?.value,
      Type: "Shift",
      UserId: ghuserId,
      Column1: editmodificationdata?.CustomFieldDetails[0][0]?.Column1 || "",
      Column2: editmodificationdata?.CustomFieldDetails[0][0]?.Column2 || "",
      Column3: editmodificationdata?.CustomFieldDetails[0][0]?.Column3 || "",
      Column4: editmodificationdata?.CustomFieldDetails[0][0]?.Column4 || "",
      Column5: editmodificationdata?.CustomFieldDetails[0][0]?.Column5 || "",
      Column6: editmodificationdata?.CustomFieldDetails[0][0]?.Column6 || "",
      Column7: editmodificationdata?.CustomFieldDetails[0][0]?.Column7 || "",
      Column8: editmodificationdata?.CustomFieldDetails[0][0]?.Column8 || "",
      Column9: editmodificationdata?.CustomFieldDetails[0][0]?.Column9 || "",
      Column10: editmodificationdata?.CustomFieldDetails[0][0]?.Column10 || "",
      BookingAssingedId:
        editmodificationfilterdata[0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0][0]?.BookingAssingedId ||
        editmodificationdata?.CustomFieldDetails[0]?.BookingAssingedId ||
        "",
      Column21: "",
      Column22: "",
      Column23: "",
      Column24: "",
      Column25: "",
      Column26: "",
      Column27: "",
      Column28: "",
      Column29: "",
      Column30: "",
      BookingAssingedId2: 0,
      ToRoomNo:
        setstaychangedate?.BookingLevel === "Room"
          ? changeroomtext || blocknameadd.trim()
          : edit_modi_check?.label !== undefined && edit_modi_check?.label,
    };

    let get_remarks_data = document.querySelector("#remarks");
    if (get_remarks_data) {
      let value = get_remarks_data?.value;
      //check booking modification available

      let modifystartdata = document.querySelector("#edit_s_date").value.split("/");
      let modifyenddata = document.querySelector("#edit_e_date").value.split("/");
      let chk_bodyData = {
        PropertyId: `${propertyID[0]}`,
        ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
        FromDt:
          modifystartdata ||
          modifystartdata[1] +
            "/" +
            modifystartdata[0] +
            "/" +
            modifystartdata[2] +
            " " +
            editmodificationfilterdata[0]?.CheckInTime1.replace(":00", ":00:00") ||
          " 12:00:00 PM",
        ToDt: modifyenddata || modifyenddata[1] + "/" + modifyenddata[0] + "/" + modifyenddata[2] + " 11:59:00 AM",
        RoomId: `${editmodificationfilterdata[0]?.RoomId}`,
      };

      //check booking modification available
      let s_date = document.querySelector("#STime")?.value.split("/");
      let e_date = document.querySelector("#ETime")?.value.split("/");
      let bodyData = {
        PropertyId: value?.PropertyId || propertyID[0],
        FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
        ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
        PropertyName: value?.PropertyName || document.querySelector("#ghproperty")?.value,
      };
      if (value !== "") {
  
        if (modifydatediff >= 0) {
          if (setstaychangedate?.BookingLevel === "Bed") {
            if (
              change_event_data?.chInDate == document.querySelector("#edit_s_date").value &&
              change_event_data?.chkOutDate == document.querySelector("#edit_e_date").value &&
              roomdata?.FromRoomId === changeroomid[0]?.RoomId &&
              GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true &&
              change_event_data?.Start_Time == document.querySelector("#checkintime")?.value &&
              change_event_data?.End_Time == document.querySelector("#checkouttime")?.value
            ) {
              if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true) {
                seterr_msg("Error");
                setget_ErrorMessagefunc([
                  `Date ${
                    GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && "and time"
                  } cannot be same as booked details`,
                ]);
              } else {
                seterr_msg("Error");
                setget_ErrorMessagefunc([`Date cannot be same as booked details`]);
              }
              setTimeout(() => {
                setget_ErrorMessagefunc([]);
                isLoadingfunc(false);
              }, 2000);
            } else {
             
            //  if ((parseInt(change_event_data?.RoomId) == parseInt(changeroomid?.RoomId && selectmodifyoption == "Stay")) || (parseInt(change_event_data?.RoomId) == parseInt(changeroomid?.RoomId && selectmodifyoption == "Shift"))) {
         

            if (parseInt(change_event_data?.RoomId) == parseInt(changeroomid?.RoomId)){
           // if (parseInt(change_event_data?.RoomId) == (parseInt(changeroomid?.RoomId || modifyshiftdata?.RoomId)) && selectmodifyoption == "Stay") {
                isLoadingfunc(true);
                let dateinsplit = change_event_data?.CheckInDt.split("-");
                let dateoutsplit = change_event_data?.CheckOutDt.split("-");

                let modindate = document.querySelector("#edit_s_date").value;
                let date_insplit = dateinsplit[2] + "/" + dateinsplit[1] + "/" + dateinsplit[0];
                let date_outsplit = dateoutsplit[2] + "/" + dateoutsplit[1] + "/" + dateoutsplit[0];

                let modoutdate = document.querySelector("#edit_e_date").value;

                let bookingdatacheck;
                if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs) {
                  bookingdatacheck =
                    date_insplit == document.querySelector("#edit_s_date").value &&
                    date_outsplit == document.querySelector("#edit_e_date").value &&
                    change_event_data?.Start_Time == document.querySelector("#checkintime")?.value &&
                    change_event_data?.End_Time == document.querySelector("#checkouttime")?.value;
                } else {
                  bookingdatacheck =
                    date_insplit == document.querySelector("#edit_s_date").value &&
                    date_outsplit == document.querySelector("#edit_e_date").value;
                }

                if (bookingdatacheck && selectmodifyoption == "Stay") {
                  if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true) {
                    seterr_msg("Error");
                    setget_ErrorMessagefunc([
                      `Date ${
                        GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && "and time"
                      } cannot be same as booked details`,
                    ]);
                  } else {
                    seterr_msg("Error");
                    setget_ErrorMessagefunc([`Date cannot be same as booked details`]);
                  }

                  
                  isLoadingfunc(false);
                  setTimeout(() => {
                    show_error_templatefunc(false);
                    setget_ErrorMessagefunc([]);
                  }, 2000);
                } else {
                  setTimeout(() => {
                   modificationinsert({ data: beddata, bodyData: bodyData }); 
                   

                    isLoadingfunc(false);
                  }, 2000);
                }
              } else {
                
if(selectmodifyoption == "Shift" && beddata?.FromRoomId == toRoomdata[0].ToRoomId && beddata.BookingLevel == "Bed") {
  let Staybeddata = {...beddata,Type:"Stay"}
  setTimeout(() => {
   modificationinsert({ data: Staybeddata, bodyData: bodyData });
    
    isLoadingfunc(true);
  }, 2000);
}
else if(selectmodifyoption == "Shift" && beddata?.FromRoomId == beddata.ToRoomId && beddata.BookingLevel == "Room") {
  let Stayroomdata = {...roomdata,Type:"Stay",ToRoomId:roomdata.FromRoomId}
  setTimeout(() => {
   modificationinsert({ data: Stayroomdata, bodyData: bodyData });
  
    isLoadingfunc(true);
  }, 2000);
  
}
else {


               
                if (diffroomdata?.ToRoomId !== undefined) {
                  isLoadingfunc(true);
                  
                  setTimeout(() => {
                   modificationshiftinsert({ data: diffroomdata, bodyData: bodyData });
                
                 
                  }, 2000);
                } else {
                  show_error_templatefunc(true);
                  getErrorMessagefunc([`Room not available`]);
                 

                  let s_date = document.querySelector("#STime")?.value.split("/");
                  let e_date = document.querySelector("#ETime")?.value.split("/");

                  let bodyData1 = {
                    PropertyId: property_details[0]?.Id || propertyID[0],
                    FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
                    ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
                    PropertyName: property_details[0]?.Property || document.querySelector("#ghproperty")?.value,
                  };

                  gridoccupencydatafunc(bodyData1);

                  setTimeout(() => {
                    show_error_templatefunc(false);
                  }, 2000);
                }



              }


              }

              showChangedatafunc(false);
            }
          } else {
            //roomshifting

            let bookingdatacheck;
            if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs) {
              bookingdatacheck =
                chInDate == document.querySelector("#edit_s_date").value &&
                chkOutDate == document.querySelector("#edit_e_date").value &&
                roomdata?.FromRoomId == roomdata?.ToRoomId &&
                change_event_data?.Start_Time == document.querySelector("#checkintime")?.value &&
                change_event_data?.End_Time == document.querySelector("#checkouttime")?.value;
            } else {
              bookingdatacheck =
                chInDate == document.querySelector("#edit_s_date").value &&
                chkOutDate == document.querySelector("#edit_e_date").value &&
                roomdata?.FromRoomId == roomdata?.ToRoomId;
            }
            if (bookingdatacheck && selectmodifyoption == "Stay") {
              if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true) {
                seterr_msg("Error");
                setget_ErrorMessagefunc([
                  `Date ${
                    GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && "and time"
                  } cannot be same as booked details`,
                ]);
              } else {
                seterr_msg("Error");
                setget_ErrorMessagefunc([`Date cannot be same as booked details`]);
              }

              setTimeout(() => {
                setget_ErrorMessagefunc([]);
                isLoadingfunc(false);
              }, 2000);
            } else {
              //if (roomdata?.FromRoomId === changeroomid[0]?.RoomId) {
              if (selectmodifyoption == "Stay") {
                if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && timediff < 4 === true) {
                  // setguesterrormsg(["Minimum 4 hours required for booking"]);
                  setTimeout(() => {
                    setguesterrormsg([]);
                  }, 2000);
                } else {
                  if (roomdata?.BookingLevel === "Room") {
                    let roomavilableresult = await roomavailcheckmodify({
                      roomid: roomdata?.ToRoomId,
                      bookingid: roomdata?.BookingId,
                    });
                    if (roomavilableresult === "Room available") {
                     modificationinsert({ data: roomdata, bodyData: bodyData });

                      showChangedatafunc(false);
                      setTimeout(() => {
                        roomshiftingdatafunc([]);
                      }, 1000);
                    } else {
                      show_error_templatefunc(true);
                      getErrorMessagefunc([`Room not available`]);
                      
                      //date refresh when error shows
                      roomnotavailablecall();

                      setTimeout(() => {
                        show_error_templatefunc(false);
                        getErrorMessagefunc([``]);
                        isLoadingfunc(false);
                      }, 2000);
                    }
                  }
                }
              } else {
                if (roomdata?.BookingLevel === "Room") {
                  let roomavilableresult = await roomavailcheckmodify({
                    roomid: roomdata?.ToRoomId,
                    bookingid: roomdata?.BookingId,
                  });

                  if (roomavilableresult === "Room available") {
                    if (GH_property_ClientName[0][1][0]?.CheckIn24Hrs === true && timediff < 4 === true) {
                      // setguesterrormsg(["Minimum 4 hours required for booking"]);
                      setTimeout(() => {
                        setguesterrormsg([]);
                      }, 2000);
                    } else {
                      isLoadingfunc(false);
                    modificationshiftinsert({ data: diffroomdata, bodyData: bodyData });
                   

                      showChangedatafunc(false);
                      setTimeout(() => {
                        roomshiftingdatafunc([]);
                      }, 1000);
                    }
                  } else {
                    show_error_templatefunc(true);
                    getErrorMessagefunc([`Room not available`]);
                   
                    //date refresh when error shows
                    roomnotavailablecall();
                    setTimeout(() => {
                      show_error_templatefunc(false);
                      getErrorMessagefunc([``]);
                      isLoadingfunc(false);
                    }, 2000);
                  }
                }
              }
            }
          }
        } else {
          setgetErrorMessagefunc([`New check-out date should be greater than new check-in date`]);
          seterr_msg("Error");
          setTimeout(() => {
            setgetErrorMessagefunc([]);
            error_close();
          }, 2000);
        }
        
      } else {
        //show_error_templatefunc(true);
        seterr_msg("Error");
        setgetErrorMessagefunc([]);
        setgetErrorMessagefunc([`Please enter modification remarks`]);
        setTimeout(() => {
          setgetErrorMessagefunc([]);
          error_close();
          isLoadingfunc(false);
        }, 2000);
        errorClassNamefunc("error_warning6");
      }
    }
  };
  const onTimeChange = (args) => {
    setfourhrs();
    setTimeout(() => {
      settime_changefunc(args.value);
    }, 501);
    let intime = document.querySelector("#checkintime")?.value;
    let outtime = document.querySelector("#checkouttime")?.value;
    const pattern = /\b(0?[1-9]|1[0-2]):00 (AM|PM)\b/;
    if (pattern.test(intime)) {
      timecheck({
        intime: timeformatcheck(intime),
        outtime: timeformatcheck(outtime),
      });
    } else {
      setTimeout(() => {
        let n_date = new Date();
        n_date.setHours(12, 0);
        settime_changefunc(n_date);
      }, 501);
    }

    //can remove
    checkgendercheckfunc();
  };

  const modificationinsert = (data) => {
    
    isLoadingfunc(true);
    axios
      .post(`${proxy_Url}/BookingModification`, data?.data, { headers: headers })
      .then((res) => {
        // show_error_templatefunc(true);
        showroomshiftingmodify(false);
        if (res.data.Result == "Booking Modified Successfully") {
          set_modify_Error_Message_func([res.data.Result]);
          
          setTimeout(() => {
            set_modify_Error_Message_func([]);
          }, 1000);
        } else {
          set_Error_Message_func([res.data.Result]);
          setTimeout(() => {
            set_Error_Message_func([]);
          }, 1000);
        }

        if (res.data.Result !== "*  Room Not Available") {
          if (res.data.Result !== "* Bed Not Available") {
            setErrorMsgClassfunc({ class: "error_error_msg", msg: "Error" });
          } else {
            setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
            errorClassNamefunc("error_success");
          }
        } else {
          setErrorMsgClassfunc({ class: "error_error_msg", msg: "Error" });
          //errorClassNamefunc("error_success");
        }
        isLoadingfunc(false);
        //clear modification data
        editmodificationfunc([]);
        seterr_msg("Success");

        gridoccupencydatafunc(data?.bodyData);

        setchangelog([]);
        setshowchangelog(false);
        //clear the modify check
        setedit_modi_check([]);
        editmodicheckfunc([]);
        roomshiftingdatafunc([]);
        close();
      })
      .catch((error) => {
        setgetErrorMessagefunc([`Room not available`]);
        set_Error_Message_func([`Room not available`]);
        setTimeout(() => {
          set_Error_Message_func([]);
        }, 1000);
        setTimeout(() => {
          setgetErrorMessagefunc([]);
          error_close();
        }, 2000);
        gridoccupencydatafunc(data.bodyData);
        setErrorMsgClassfunc({ class: "error-error", msg: "Error" });

        isLoadingfunc(false);

        setchangelog([]);
        setshowchangelog(false);
      });
    // }
  };
  const modificationshiftinsert = (data) => {
    isLoadingfunc(true);

    axios
      .post(`${proxy_Url}/RoomShifting`, data?.data, { headers: headers })
      .then((res) => {
        let s_date = document.querySelector("#STime")?.value.split("/");
        let e_date = document.querySelector("#ETime")?.value.split("/");
        let gridData = {
          PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || propertyID[0],
          FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
          ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
          PropertyName:
            property_details[0]?.PropertyName ||
            property_details[0]?.Property ||
            document.querySelector("#ghproperty")?.value,
          ss: "3",
        };
        gridoccupencydatafunc(gridData);
        isLoadingfunc(false);

        // show_error_
        //clear the modify check
        if (res?.data?.Result === "*  Bed Not Available") {
          // get bed error function show
          show_error_templatefunc(true);
          getErrorMessagefunc([`Bed not available`]);
        } else if(res?.data?.Result === "*  Room Not Available"){
          show_error_templatefunc(true);
          getErrorMessagefunc([`Room not available`]);
        }
         else {
          setgetErrorMessagefunc([`Shifted successfully`]);
          set_Error_Message_func([`Shifted successfully`]);
          showroomshiftingmodify(false);
        }

        setTimeout(() => {
          set_Error_Message_func([]);
          setgetErrorMessagefunc([]);
          setguesterrormsg([]);
          // get bed error function hide
          show_error_templatefunc(false);
          getErrorMessagefunc([]);
        }, 1000);
        setErrorMsgClassfunc({ class: "error-success", msg: "Success" });
        setedit_modi_check([]);
        editmodicheckfunc([]);
        roomshiftingdatafunc([]);
      })
      .catch((error) => {
        isLoadingfunc(false);
      });
  };

  const clearData = () => {};

  const error_close = () => {
    setgetErrorMessagefunc([]);
  };

  const confirm_changlog = () => {
    setchangelog([]);
    showChangedatafunc(false);
    changeeventdatafunc([]);
    setCancelEvent(false);
    setSchedulerData(scheduledataoriginal[0]);
    showroomshiftingmodify(false);
  };
  const cancel_changlog = () => {
    setshowchangelog(false);
  };

  const starttimeFocus = (args) => {
    intimeobj?.show();
    //document.querySelector("#checkintime").blur();
  };
  const endtimeFocus = (args) => {
    outdateObj?.show();
    // document.querySelector("#checkouttime").blur();
  };
  const outTimeChange = (args) => {
    settime_changeoutfunc(args.value);

    let intime = document.querySelector("#checkintime")?.value;
    let outtime = document.querySelector("#checkouttime")?.value;
    const pattern = /\b(0?[1-9]|1[0-2]):00 (AM|PM)\b/;
    if (pattern.test(outtime)) {
      timecheck({
        intime: timeformatcheck(intime),
        outtime: timeformatcheck(outtime),
      });
    } else {
      let n_date = new Date();
      n_date.setHours(11, 59);
      settime_changeoutfunc(n_date);
    }
    checkgendercheckfunc();
  };

  const timecheck = (args) => {
    let stimechange = document.querySelector("#edit_s_date")?.value;
    let etimechange = document.querySelector("#edit_e_date")?.value;

    settimediff(5);

    if (stimechange === etimechange) {
      let diff = args?.outtime - args?.intime;

      if (GH_property_ClientName[0][1][0].CheckIn24Hrs === true) {
        if (diff < 4) {
          // setguesterrormsg(["Minimum 4 hours required for booking"]);
          setTimeout(() => {
            setguesterrormsg([]);
          }, 2000);
        }
        // settimediff(diff);
      }

      //
    }
  };

  const timeformatcheck = (value) => {
    if (value !== undefined) {
      const [time, modifier] = value?.split(" ");

      let [hours, minutes] = time?.split(":");

      if (parseInt(hours) === 12 && modifier === "AM") {
        hours = 0;
      } else if (parseInt(hours) < 12 && modifier === "AM") {
        hours = parseInt(hours);
      } else if (parseInt(hours) === 12 && modifier === "PM") {
        hours = parseInt(hours);
      } else {
        hours = parseInt(hours) + 12;
      }

      return hours;
    }
    //return `${hours}:${minutes}`;
  };

  // roomavailcheckmodify while modify the room
  const roomavailcheckmodify = (data) => {
    isLoadingfunc(true);
    showChangedatafunc(false);
    let frmdate = document.querySelector("#edit_s_date")?.value.split("/");
    let todate = document.querySelector("#edit_e_date")?.value.split("/");
    let roomfromtime = document.querySelector("#checkintime")?.value;
    let roomtotime = document.querySelector("#checkouttime")?.value;

    let roomcintime =
      GH_property_ClientName[0][1][0]?.CheckIn24Hrs && setstaychangedate?.BookingLevel === "Room"
        ? roomfromtime?.replace(":00", ":00:00")
        : roomdata?.ChkInTime?.replace(":00", ":00:00");
    let roomcouttime =
      GH_property_ClientName[0][1][0]?.CheckIn24Hrs && setstaychangedate?.BookingLevel === "Room"
        ? roomtotime?.includes(":59")
          ? roomtotime?.replace(":59", ":59:00")
          : roomtotime?.replace(":00", ":00:00")
        : roomdata?.ChkOutTime?.replace(":00", ":00:00");

    let chk_bodyData = {
      PropertyId: property_details[0]?.Id || propertyID[0],
      ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
      FromDt: `${frmdate[2] + "-" + frmdate[1] + "-" + frmdate[0]}`,
      FromTime: roomcintime,
      ToDt: `${todate[2] + "-" + todate[1] + "-" + todate[0]}`,
      ToTime: roomcouttime,
      RoomId: data?.roomid,
      Bookingid: data?.bookingid,
    };
    let availablestatus = axios
      .post(`${proxy_Url}/roomshiftingCheckDetail`, chk_bodyData, { headers: headers })
      .then(async (res) => {
        return res?.data?.Result[0][0]?.AvailabilityStatus;
      })
      .catch();

    return availablestatus;
  };

  const disablekeyboard = () => {
    document.onkeydown = function (e) {
      return false;
    };
  };

  const enablekeyboard = () => {
    document.onkeydown = function (e) {
      return true;
    };
  };
  const close = () => {
    if (changelog?.length > 0) {
      setshowchangelog(true);
    } else {
      showChangedatafunc(false);
      changeeventdatafunc([]);
      setCancelEvent(false);
      setSchedulerData(scheduledataoriginal[0]);
      showroomshiftingmodify(false);
      error_close();
    }
  };

  const SelectChange = (e) => {
    
    setchangeroomno(e.target.value);

    let roomchangeid = scheduler_Data[0]?.roomData?.filter(
      (item) => parseInt(item?.RoomId) == parseInt(e.target.value)
    );
    setchangeroomtext(roomchangeid[0]?.text !== undefined && roomchangeid[0]?.text);
   
    changeroomidfunc(roomchangeid);
    dragChangeData(change_event_data, roomchangeid);

    setchangelog(["Changes that you made may not be saved", "do you want to close?"]);
    setmsg_option("Error");
    //showEditpopup(!editpopup);
  };

  const roomavailablecheck = () => {

    let st_date = document.querySelector("#edit_s_date")?.value.split("/");
      let e_date = document.querySelector("#edit_e_date")?.value.split("/");
      let stime = document.querySelector("#checkintime")?.value;
      let etime = document.querySelector("#checkouttime")?.value;

      //let change_st_date = change_event_data?.FromDt.split("/");
      //let change_e_date = change_event_data?.ToDt.split("/");
    let bodydata = {
      "Bookingtype": modifyshiftdata?.BookingLevel,
      "FromDt": `${st_date[2]}/${st_date[1]}/${st_date[0]}`,
      "FromTime": stime || "12:00 PM",
      "PAction": "BlockblukCheck",
      "PropertyId" : `${propertyID[0]}`,
      "ToDt": `${e_date[2]}/${e_date[1]}/${e_date[0]}`,
      "ToTime": etime || "11:59 AM",
      "UserId": ghuserId
      }
   axios
      .post(`${proxy_Url}/shiftingavailableroomcheck`, bodydata, { headers: headers })
      .then(async (res) => {
        

       

       const fromdateTimeString = `${bodydata?.FromDt} ${bodydata?.FromTime}`;
       const todateTimeString = `${bodydata?.ToDt} ${bodydata?.ToTime}`;

       const change_fromdateTimeString = `${change_event_data?.CheckInDt} ${change_event_data?.Start_Time}`;
       const change_todateTimeString = `${change_event_data?.CheckOutDt} ${change_event_data?.End_Time}`;
       //if()
       const change_fromDate = new Date(change_fromdateTimeString);
       const change_toDate = new Date(change_todateTimeString);
       const user_fromDate = new Date(fromdateTimeString);
       const user_toDate = new Date(todateTimeString);

// Check if user dates are exactly equal to the default dates
const isEqual = user_fromDate.getTime() === change_fromDate.getTime() &&
                user_toDate.getTime() === change_toDate.getTime();

// Check if user dates are within the default range
const isWithinRange = user_fromDate >= change_fromDate && user_toDate <= change_toDate;

// Combine both checks
const isMatch = isEqual || isWithinRange;
  
     if(isMatch) {
      let data = res?.data.Result[0]?.filter(item => item.RoomId != change_event_data?.RoomId);
      settoRoomdata(data)
    
     }
     else {
      settoRoomdata(res?.data.Result[0])
      
     }



      })
      .catch((error) => {
        console.log(error.message);
      });

  };


  return (
    <>
      {changelog?.length > 0 && showchangelog === true && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con">
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center">
              {changelog?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
            <button className="btn btn-sm btn-info mt-n1 mb-2 mr-2 continue-but" onClick={confirm_changlog}>
              Yes
            </button>
            <button className="btn btn-sm btn-warning mt-n1 mb-2 mr-2 continue-but" onClick={cancel_changlog}>
              No
            </button>
          </div>
        </div>
      )}
      {guesterrormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Error Message</span>{" "}
            </div>
            <div className="error_content" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              <p className="t-center">{guesterrormsg}</p>
            </div>
          </div>
        </div>
      )}
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {getErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {modifycuccessMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>Success Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {modifycuccessMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {getroomerrormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {getroomerrormsg?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {genderbchked > 0 && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con exceed" style={{ background: "#ff0000" }}>
              <span>Alert Message</span>{" "}
            </div>
            <div className="error_content t-center" style={{ borderBottom: "1px solid #dddd", padding: "3px 0px" }}>
              <p className="t-center">
                <p>{displaygender} guest was staying in that room, do you want to continue..?</p>
              </p>
            </div>

            <button
              className="btn btn-sm btn-info  mt-n1 mb-2 mr-2  continue-but"
              onClick={() => {
                setGenderbched(0);
                setgenderbooking(0);
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-sm btn-warning mt-n1 mb-2  continue-but"
              onClick={() => {
                setGenderbched(0);
                setgenderbooking(1);
                changeDatafunc();
              }}
            >
              No
            </button>
          </div>
        </div>
      )}
      {get_ErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {get_ErrorMessage?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {e_customFields && <CustomFieldsEdit e_customFields={e_customFields} setE_customFields={setE_customFields} />}
      {ghaccessdata[0]?.ModifyResFlg === true && (
        <form onSubmit={onSubmit}>
          <div className="changeEditor">
            <div className="editorBody">
              <div className="header">
                <span>
                  {selectmodifyoption == "Stay" ? "Booking Modification" : "Room Shifting"} for{" "}
                  <b>{`${ghproperty_Name?.value} | ${
                    change_event_data.ClientName || setstaychangedate?.ClientName
                  }`}</b>{" "}
                </span>
                
                <span className="d-flex ml-auto bg-light popup_Close" onClick={close}>
                  X
                </span>
              </div>
              <div className="body">
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xl-4 booking_input">
                    <div className="col-md-12 my-2">
                      <span>Guest Name</span> : <b>{change_event_data?.Guest || change_event_data?.Subject}</b>
                    </div>
                    <div className="col-md-12">
                      <span>Status</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{" "}
                      <b>{change_event_data?.CurrentStatus || change_event_data?.Type}</b>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-4 booking_input">
                    <div className="col-md-12 my-2">
                      <span>Current Check-In Date</span> &nbsp;&nbsp;&nbsp;: <b>{chInDate}</b>
                    </div>
                    <div className="col-md-12">
                      <span>Current Check-Out Date</span> : <b>{chkOutDate}</b>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 col-xl-4 booking_input">
                    <div className="col-md-12 my-2">
                      <span>Booking Confirmation Number</span>&nbsp;:&nbsp;<b>{change_event_data?.BookingCode}</b>
                    </div>
                  </div>
                </div>

                <div className="w-100 p-1 mt-3" style={{ background: "#dde9f8" }}>
                  <div className="">
                    <div className="row">
                    {/* {selectmodifyoption == "Shift" && ( 
                      
                      <div className="col-md-6"><span style={{ display: "flex", alignItems: "center",justifyItems:'center' }}>
                      From&nbsp;Room : &nbsp;{" "}<b className="w-100 input_box" style={{background:'#fff',width:'80%',padding:'5px',borderRadius:'5px',border:'1px solid #ddd'}}>{Fromroom}</b></span></div>)} */}
                    {/* {selectmodifyoption == "Shift" && ( <div className="col-md-6">
                        
                          <span style={{ display: "flex", alignItems: "center" }}>
                            To&nbsp;Room : &nbsp;{" "}
                            <select
                              style={{ background: "#ffffff", width: "100%",border:'1px solid #ddd'}}
                              name="room_shifting"
                              class="room_shifting"
                              id="room_shifting"
                              onChange={SelectChange}
                            >
                              <option>Select Room</option>
                              {toRoomdata.filter(item => item.RoomId != changeroomid?.RoomId).map((item) => (
                                  <option key={item.Id} value={item.RoomId}>
                                    {item.RoomNoId}
                                  </option>
                                ))}
                            </select>
                          </span>
                        
                      </div>)} */}

                      {selectmodifyoption == "Shift" && ( <div className="col-md-6">
                        <div className="change_editor">
                        From Room{" "}
                          <span className="c-red" style={{ color: "red" }}>
                            *
                          </span>{" "}
                          :{" "}
                          <label
                           
                            className="w-100 input_box"
                           style={{background:"#ddd",padding:"5px",marginTop:0}}
                           
                          >{Fromroom}</label>
                        </div>
                      </div>)}
                      {selectmodifyoption == "Shift" && ( <div className="col-md-6" style={{marginBottom:"10px"}}>
                        <div className="change_editor">
                        To Room{" "}
                          <span className="c-red" style={{ color: "red" }}>
                            *
                          </span>{" "}
                          :{" "}
                          <select
                              style={{ background: "#ffffff", width: "100%",border:'1px solid #ddd'}}
                              name="room_shifting"
                              class="w-100 input_box"
                              id="room_shifting"
                              onChange={SelectChange}
                            >
                              <option>Select Room</option>
                              {toRoomdata.filter(item => item.RoomId != changeroomid?.RoomId).map((item) => (
                                  <option key={item.Id} value={item.RoomId}>
                                    {item.RoomNoId}
                                  </option>
                                ))}
                            </select>
                        </div>
                      </div>)}
                     <hr/>
                      <div className="col-md-4">
                        <div className="change_editor">
                          Modification Remarks{" "}
                          <span className="c-red" style={{ color: "red" }}>
                            *
                          </span>{" "}
                          :{" "}
                          <input
                            type="text"
                            id="remarks"
                            className="w-100 input_box modifyremarks"
                            name="remarks"
                            ref={remarks}
                            onInput={propInput}
                            onChange={() => {
                              setchangelog(["Changes that you made may not be saved", "do you want to close?"]);
                              setmsg_option("Error");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className={`change_editor ${change_event_data?.Type === "CheckIn" && "blocknewcheckin"}`}>
                          New Check-In Date :{" "}
                          <DatePickerComponent
                            format="dd/MM/yyyy"
                            id="edit_s_date"
                            selected={startDate}
                            // min={new Date(startDate)}
                            ref={(date) => (startmodifyObj = date)}
                            className="form-control date-picker"
                            onChange={(date) => {
                              setStartDatefunc(date?.target?.value);
                              setchangelog(["Changes that you made may not be saved", "do you want to close?"]);
                              setmsg_option("Error");
                              stayshiftfunc("resizeevent");
                              dragChangeData(change_event_data, {});
                              checkgendercheckfunc();
                              setfourhrs();
                              timecheck({
                                intime: timeformatcheck(document.querySelector("#checkintime")?.value),
                                outtime: timeformatcheck(document.querySelector("#checkouttime")?.value),
                              });
                              roomavailablecheck();
                            }}
                            value={change_event_data?.Type === "CheckIn" ? chInDate : chInDate}
                            onFocus={startonFocusmodify}
                            readOnly={change_event_data?.Type === "CheckIn" && true}
                          />
                        </div>
                      </div>
                      {/* {GH_property_ClientName[0][1][0]?.CheckIn24Hrs && setstaychangedate?.BookingLevel === "Room" && ( */}
                      {GH_property_ClientName[0][1][0]?.CheckIn24Hrs && (
                        <div className="col-md-4 timebgcolor">
                          <div
                            className={`change_editor ${change_event_data?.Type === "CheckIn" && "blocknewcheckin"}`}
                          >
                            New Check-In Time :{" "}
                            <TimePickerComponent
                              id="checkintime"
                              openOnFocus={true}
                              onClick={starttimeFocus}
                              onChange={onTimeChange}
                              min={"12:00 PM"}
                              //selected={"12:00 PM"  }
                              max={maxtime}
                              ref={(scope) => (intimeobj = scope)}
                              step={60}
                              value={change_event_data?.Type === "CheckIn" ? time_change : "12:00 PM"}
                              placeholder="Select a Time"
                              readOnly={change_event_data?.Type === "CheckIn" && true}
                              onFocus={disablekeyboard}
                              onBlur={enablekeyboard}

                              //focus={starttimeFocus}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-md-4">
                        <div className="change_editor">
                          New Check-Out Date :{" "}
                          <DatePickerComponent
                            id="edit_e_date"
                            format="dd/MM/yyyy"
                            ref={(date) => (endmodifyObj = date)}
                            min={
                              GH_property_ClientName[0][1][0]?.CheckIn24Hrs
                                ? // && setstaychangedate?.BookingLevel === "Room"
                                  new Date(splitdatearray(chInDate).reverse().join("/"))
                                : new Date(
                                    new Date(splitdatearray(chInDate).reverse().join("/")).setDate(
                                      new Date(splitdatearray(chInDate).reverse().join("/")).getDate() + 1
                                    )
                                  )
                            }
                            selected={endDate}
                            className="form-control date-picker"
                            onChange={(date) => {
                              setEndDate(date?.target?.value);
                              setchangelog(["Changes that you made may not be saved", "do you want to close?"]);
                              setmsg_option("Error");
                              stayshiftfunc("resizeevent");
                              dragChangeData();
                              setfourhrs();
                              timecheck({
                                intime: timeformatcheck(document.querySelector("#checkintime")?.value),
                                outtime: timeformatcheck(document.querySelector("#checkouttime")?.value),
                              });
                              checkgendercheckfunc();
                              roomavailablecheck();
                            }}
                            value={new Date(endDate)}
                            onFocus={EndonFocusmodify}
                          />
                        </div>
                       </div>
                      {/* {GH_property_ClientName[0][1][0]?.CheckIn24Hrs && setstaychangedate?.BookingLevel === "Room" && ( */}
                      {GH_property_ClientName[0][1][0]?.CheckIn24Hrs && (
                        <div className="col-md-4 timebgcolor">
                          <div
                            className={`change_editor`}
                            // className={`change_editor ${change_event_data?.Type === "CheckIn" && "blocknewcheckin"}
                            // `}
                          >
                            New Check-Out Time :{" "}
                            <TimePickerComponent
                              id="checkouttime"
                              openOnFocus={true}
                              onClick={endtimeFocus}
                              min={changeouttime}
                              value={time_changeout || "11:59 AM"}
                              onChange={outTimeChange}
                              ref={(scope) => {
                                outdateObj = scope;
                              }}
                              //focus={endtimeFocus}
                              // openOnFocus={true}
                              // onfiltering={outfiltering}
                              step={60}
                              onFocus={disablekeyboard}
                              onBlur={enablekeyboard}
                            />
                          </div>
                        </div>
                      )}
                      {/* <div className="col-md-4 mofidy_timecheck">
                      { change_event_data?.Type === "CheckIn" && change_event_data?.Type === "CheckIn" && <> New Check-In Time :{" "} <TimePickerComponent
                              id="checkintime"
                              openOnFocus={true}
                              onChange={onTimeChange}
                              min={"12:00 PM"}
                              //selected={"12:00 PM"  }
                              ref={(scope) => (intimeobj = scope)}
                              step={60}
                              value={time_change || "12:00 PM"}
                              placeholder="Select a Time"
                              //focus={starttimeFocus}
                            /> </>}
</div> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 my-2">
                    <input
                      type="button"
                      value="Custom Fields"
                      style={{ float: "right" }}
                      className="btn btn-danger btn-sm"
                      onClick={editCustomField}
                      disabled={edit_cus_data && edit_cus_data[2][0]?.Column1 && true}
                    />
                  </div>
                </div>
              </div>
              <div className="footer">
                <input
                  type="button"
                  value="Check Availability & Modify"
                  className="btn btn-success btn-sm mr-1"
                  onClick={checkbooking}
                />
                {/* <input type="button" value="Clear" className="btn btn-danger btn-sm" onClick={clearData} /> */}
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default React.memo(ModifyShfiting);
