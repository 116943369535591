const menu = [
  {
    icon: "grid-alt",
    text: "Dashboard",
    link: "/",
  },
  {
    icon: "dot-box",
    text: "Reservation",
    link: "/reservation",
  },
  {
    icon: "growth",
    text: "Booking Details",
    link: "/booking-details",
  },
  // {
  //   icon: "coins",
  //   text: "Booking Matrix",
  //   link: "/invest",
  // },
  // {
  //   icon: "tranx",
  //   text: "Service Entry",
  //   link: "/service-entry",
  // },
  {
    icon: "coins",
    text: "Availability",
    link: "/availability",
  },
  // {
  //   icon: "view-col",
  //   text: "Room Block",
  //   link: "/invest",
  // },
  {
    icon: "view-col",
    text: "Room Block",
    link: "/room-block",
  },
  {
    icon: "block-over",
    text: "Change Password",
    link: "/auth-change-password",
    newTab: true,
  },
 
 
];
export default menu;
