import React from 'react'
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

const ServiceEntry = () => {
  return (
    <React.Fragment> 
    <Head title="Guest house booking"></Head>  
   
     
       

      <Content>
      <div>ServiceEntry</div>
    </Content>
    </React.Fragment>
    
  )
}

export default React.memo(ServiceEntry)

