import React, { useState, useContext } from "react";
import { useEffect } from "react";
import "../../assets/scss/additionalfields.scss";
import { BookingContent } from "../bookingContext/BookingContext";

const AddAdditionalFields = ({ addifields, setAddifields, getaddcount }) => {
  const [addFormData, setAddFormData] = useState({
    column1: "",
    column2: "",
    column3: "",
    column4: "",
    column5: "",
    column6: "",
    column7: "",
    column8: "",
    column9: "",
    column10: "",
  });

  const { additionalfields, addfieldsData, new_booking_data } = useContext(BookingContent);
 
  let count = 0;
  let newdata_booking = new_booking_data;
  //JSON.parse(localStorage.getItem("newDatabooking"))

  useEffect(() => {
   
    if (additionalfields) {
      setAddFormData((prev) => ({
        ...prev,
        column1: additionalfields[0]?.Column1,
        column2: additionalfields[0]?.Column2,
        column3: additionalfields[0]?.Column3,
        column4: additionalfields[0]?.Column4,
        column5: additionalfields[0]?.Column5,
        column6: additionalfields[0]?.Column6,
        column7: additionalfields[0]?.Column7,
        column8: additionalfields[0]?.Column8,
        column9: additionalfields[0]?.Column9,
        column10: additionalfields[0]?.Column10,
      }));
    }

    return () => {
      //setAddFormData([]);
      //addfieldsData([]);
    };
  }, [additionalfields]);

  //onChange event
  const handleChange = (event) => {
    const value = event.target.value;
    setAddFormData({ ...addFormData, [event.target.name]: value });
  };
  //close popup page
  const closeFields = () => {
    setAddifields(false);
  };
  const addcustomFields = (val) => {
    getaddcount((count = count + 1));
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    addfieldsData(addFormData);
    setAddifields(false);
  
    let guestdata = JSON.parse(localStorage.getItem("guestDetails"));
    let merge_Guest_Date = Object.assign({}, guestdata, addFormData);
    let get_employee = JSON.parse(localStorage.getItem("trInputstates"));
    merge_Guest_Date.EmployeeId = get_employee.EmployeeId;
    merge_Guest_Date.GradeId = get_employee.GradeId;

    localStorage.setItem("guestDetails", JSON.stringify(merge_Guest_Date));
  };

  return (
    <div className="addFieldsbg" style={{ display: addifields ? "flex" : "none" }}>
      <div className="AddFieldsBlock">
        <div className="AddFields">
          <div className="AddFieldsheader d-flex">
            <span>Custom fields</span>{" "}
            <span className="d-flex ml-auto bg-light popup_Close" onClick={closeFields}>
              X
            </span>{" "}
          </div>

          <div className="AddFieldsbody">
            <form onSubmit={handlesubmit}>
              <table className="table" style={{ marginBottom: "0px !important" }}>
                <tbody>
                  <tr>
                    <td style={{ borderTop: "0px" }}>
                      {newdata_booking[2][0]?.Column1}
                      <span className="text-danger">{newdata_booking[2][0]?.Column1Mandatory && " *"}</span>
                    </td>
                    <td style={{ borderTop: "0px" }}>
                      <input name="column1" value={addFormData.column1} type="text" onChange={handleChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column2}
                      <span className="text-danger">{newdata_booking[2][0]?.Column2Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input name="column2" type="text" value={addFormData.column2} onChange={handleChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column3}
                      <span className="text-danger">{newdata_booking[2][0]?.Column3Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input name="column3" type="text" value={addFormData.column3} onChange={handleChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column4}
                      <span className="text-danger">{newdata_booking[2][0]?.Column4Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input name="column4" type="text" value={addFormData.column4} onChange={handleChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column5}
                      <span className="text-danger">{newdata_booking[2][0]?.Column5Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input name="column5" type="text" value={addFormData.column5} onChange={handleChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column6}
                      <span className="text-danger">{newdata_booking[2][0]?.Column6Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input name="column6" type="text" value={addFormData.column6} onChange={handleChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column7}
                      <span className="text-danger">{newdata_booking[2][0]?.Column7Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input name="column7" type="text" value={addFormData.column7} onChange={handleChange} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column8}
                      <span className="text-danger">{newdata_booking[2][0]?.Column8Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input name="column8" type="text" value={addFormData.column8} onChange={(e) => handleChange(e)} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column9}
                      <span className="text-danger">{newdata_booking[2][0]?.Column9Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input name="column9" type="text" value={addFormData.column9} onChange={(e) => handleChange(e)} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {newdata_booking[2][0]?.Column10}
                      <span className="text-danger">{newdata_booking[2][0]?.Column10Mandatory && " *"}</span>
                    </td>
                    <td>
                      <input
                        name="column10"
                        type="text"
                        value={addFormData.column10}
                        onChange={(e) => handleChange(e)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="2">
                      <div className="AddFieldsfooter">
                        <button className="btn btn-primary btn-sm" onClick={addcustomFields}>
                          Add
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddAdditionalFields);
