import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import "../../assets/scss/changeEditor.scss";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CustomFieldsEdit from "../editorTableComponent/CustomFieldsEdit";
import { setTime } from "@syncfusion/ej2-react-schedule";
import moment from "moment";

const BlockHistory = ({ ghnamechange,setblockhtry }) => {
  const {
    setSchedulerData,
    scheduledataoriginal,
    gridoccupencydatafunc,
    property_details,
    setCancelEvent,
    token,
    show_error_templatefunc,
    getErrorMessagefunc,
    errorClassNamefunc,
    isLoadingfunc,
    proxy_Url,
    change_event_data,
    editmodificationfunc,
    editmodificationdata,
    staychangedate,
    changeeventdatafunc,
    editdatafunc,
    ghaccessdata,
    set_Error_Message_func,
    propertyID,
    setErrorMsgClassfunc,
    client_id,
    clientID,
    setstaychangedate,
    clientcustomfieldscollection,
    edit_cus_data,
    propInput,
    stayshift,
    GH_property_ClientName,
    stayshiftfunc,
    roomshiftdata,
    changeroomid,
    blockhistory,
    getmodifyhistorydata,
  } = useContext(BookingContent);

  //set session id

  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  let remarks = useRef();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [e_customFields, setE_customFields] = useState(false);
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [get_ErrorMessage, setget_ErrorMessagefunc] = useState([]);
  const [err_msg, seterr_msg] = useState("");
  const [edit_modi_check, setedit_modi_check] = useState([]);
  const [changelog, setchangelog] = useState([]);
  const [showchangelog, setshowchangelog] = useState(false);
  const [msg_option, setmsg_option] = useState([]);
  const [historydata, sethistorydata] = useState([]);
  const [bedstay, setbedstay] = useState("");
  const [bedidavailablecheckcount, setbedidavailablecheckcount] = useState([]);
  const [editmodificationfilterdata, seteditmodificationfilterdata] = useState([]);
  const [end_Date, setend_Date] = useState(null);
  const [ghpreopretySearch, setGhpreopretySearch] = useState("");
  const [availablerooms, setavailablerooms] = useState("");

  let ghproperty_Name = document.querySelector("#ghproperty");
  let ghproperty_Entity = document.querySelector("#Entity_select");
  let endmodifyObj = useRef();
  let startmodifyObj = useRef();
  let editmodicheck;
  let mincheckindate;
  let trselectref = useRef();

  //get bet count

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };

  let property_room_filter =
    historydata[0] &&
    historydata[0] !== "N" &&
    historydata[0]?.filter((item) => {
      if (ghpreopretySearch === "") {
        return item;
      } else if (
        item.FromDt.toLowerCase().includes(ghpreopretySearch.toLowerCase()) ||
        item.ToDt.toLowerCase().includes(ghpreopretySearch.toLowerCase()) ||
        item.RoomNo.toLowerCase().includes(ghpreopretySearch.toLowerCase())
      ) {
        return item;
      }
    });
  useEffect(() => {
    let blockhistorycheckData = {
      RoomId: "",
      FromDt: "",
      ToDt: "",
      FromTime: "",
      ToTime: "",
      CheckIn24Hrs: "" || blockhistory[0]?.CheckIn_24HrsFlg === false ? 0 : 1,
      UserId: ghuserId,
      Comments: "",
      PropertyId: blockhistory[0]?.PropertyId,
      Allroomid: "",
    };

    axios
      .post(`${proxy_Url}/BlockHistoryroomcheck`, blockhistorycheckData, { headers: headers })
      .then(({ data }) => {
        setavailablerooms(data?.Result[0]);
        if (data.Result === "No records found") {
        } else {
          //sethistorydata([data.Result[0] && data.Result[0]])
        }
      })
      .catch((error) => {
        // console.log(error.message)
      });
  }, []);

  const selectroomfunc = (val) => {
    let blockcheckData = {
      RoomId: val.target.value,
      FromDt: "",
      ToDt: "",
      FromTime: "",
      ToTime: "",
      CheckIn24Hrs: "" || blockhistory[0]?.CheckIn_24HrsFlg === false ? 0 : 1,
      UserId: ghuserId,
      Comments: "",
      PropertyId: blockhistory[0]?.PropertyId,
    };

    let historyselect = document.querySelector("#blockhistoryselect").value;
    if (historyselect !== "Select room") {
      axios
        .post(`${proxy_Url}/Blockhistory`, blockcheckData, { headers: headers })
        .then(({ data }) => {
          if (data.Result === "No records found") {
            sethistorydata([]);
          } else {
            sethistorydata([data.Result[0] && data.Result[0]]);
          }
        })
        .catch((error) => {});
    } else {
      sethistorydata([]);
    }
  };

  const closeModify = (args) => {
    getmodifyhistorydata([]);
    setblockhtry(false);
  };

  return (
    <>
      <form>
        <div className="changeEditor">
          <div className="editorBody">
            <div className="header">
              <span className="w-100">
                <span style={{ marginLeft: "10px" }}>Block History</span>
              </span>

              {/* <input
                type="search"
                name="ghpreopretySearch"
                defaultValue=""
                placeholder="Blocked room search"
                className="ghpropertySearch ml-1 mt-n1"
                onChange={(e) => setGhpreopretySearch(e.target.value)}
                ref={trselectref}
              />*/}
              <select
                //value={selectValue}
                className="form-control"
                id="blockhistoryselect"
                onChange={selectroomfunc}
              >
                <option value={"Select room"}>Select room</option>
                {availablerooms?.length > 0 &&
                  availablerooms?.map((item) => (
                    <option key={item.RoomId} value={item.RoomId}>
                      {item.RoomNoId}
                    </option>
                  ))}
              </select>
              <span className="d-flex ml-auto bg-light popup_Close" onClick={closeModify}>
                X
              </span>
            </div>

            <div className="body">
              <div className="row">
                <div className="col-md-12 tr_list_table">
                  <div className="o_flow h-26 datashow bg-white">
                    <table
                      role="table"
                      className="table table-hover table-bordered tr_select booking_details b-shadow w-100"
                    >
                      <thead role="rowgroup">
                        <tr role="row" className="stickyheader">
                          <th role="columnheader" style={{ width: "80px", paddingLeft: "5px" }}>
                            Room&nbsp;No
                          </th>
                          <th role="columnheader" style={{ width: "180px" }}>
                            From Date & Time
                          </th>
                          <th role="columnheader" style={{ width: "180px" }}>
                            To Date & Time
                          </th>
                          <th role="columnheader">Comments</th>
                        </tr>
                      </thead>
                      {
                        //   "RoomNo": "501",
                        //   "FromDt": "05/01/2022",
                        //   "FromTime": "12:00:00 PM",
                        //   "ToDt": "31/05/2022",
                        //   "ToTime": "11:59:00 AM",
                        //   "Comments": "room blocked as per Ms.Sangeeta request",
                        //   "PropertyId": "3507",
                        //   "RoomId": "1758",
                        //   "Id": "431"
                      }
                      <tbody role="rowgroup">
                        {property_room_filter && property_room_filter?.length > 0 ? (
                          property_room_filter?.map((val, index) => (
                            <tr role="row" key={index}>
                              <td role="cell">{val.RoomNo}</td>
                              <td role="cell" style={{ whiteSpace: "nowrap" }}>
                                {val.FromDt + " " + val.FromTime}
                              </td>
                              <td role="cell" style={{ whiteSpace: "nowrap" }}>
                                {val.ToDt + " " + val.ToTime}
                              </td>
                              <td role="cell">{val.Comments}</td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr role="row">
                              <td role="cell" colSpan={4}>
                                <p style={{ textAlign: "center" }}></p>
                              </td>
                            </tr>
                            <tr role="row">
                              <td role="cell" colSpan={4}>
                                <p style={{ textAlign: "center" }}>Select room to display data</p>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            {/* <input type="button" value="Save" className="btn btn-success btn-sm mr-1" onClick={saveModify} /> */}
            {/* <input type="button" value="Clear" className="btn btn-danger btn-sm" onClick={clearData} /> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default React.memo(BlockHistory);
