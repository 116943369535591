import React, { useState, useEffect, useContext } from "react";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";

const BlockDeleteData = ({}) => {
  const {
    propertyID,
    set_Error_Message_func,
    // booking_modify_cancel_data,
    // propnameid,
    token,
    proxy_Url,
    // show_error_templatefunc,
    // getErrorMessagefunc,
    // errorClassNamefunc,
    isLoadingfunc,
    property_details,
    gridoccupencydatafunc,
    ghaccessdata,
    setErrorMsgClassfunc,
    formatDateddmmyy,
    clientDetails,
    block_delete_data,
    showblockdeletefunc,
    blockdeletefunc,
  } = useContext(BookingContent);
  // const [cancel_details, setCancelDetails] = useState(booking_modify_cancel_data && booking_modify_cancel_data?.BookingCancelType);
  //const [cancel_data, setcancel_data] = useState(booking_modify_cancel_data && booking_modify_cancel_data?.Result[0]);
  // const [cancel_reason, setcancel_reason] = useState("");
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  // const [msg_option, setmsg_option] = useState([]);
  // const [showchangelog, setshowchangelog] = useState(false);
  // const [changelog, setchangelog] = useState([]);

  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };
  useEffect(() => {
    if (ghaccessdata[0]?.CancelResFlg === false) {
      setgetErrorMessagefunc(["You dont have access to cancel this booking"]);
      setTimeout(() => { setgetErrorMessagefunc([])},2000);
    }
  }, []);
  let ghproperty_Name = document.querySelector("#ghproperty");
  let ghproperty_Entity = document.querySelector("#Entity_select");
  const close = () => {
    showblockdeletefunc(false);
  };

  const cancelBooking = () => {
    isLoadingfunc(true);

    let bodyData = {
      PropertyId: `${propertyID[0]}`,
      RoomId: block_delete_data?.RoomId,
      ToDt: formatDateddmmyy(block_delete_data?.CheckOutDt),
      CheckIn24Hrs: clientDetails[0][0]?.CheckIn24Hrs,
      UserId: ghuserId,
    };
    //fetch data
    axios
      .post(`${proxy_Url}/BlockroomsDataDetails`, bodyData, { headers: headers })
      .then((res) => {
        let resData = res.data.Result[0][0];

        let deleteData = {
          PropertyId: `${resData?.Id}`,
          RoomId: `${resData?.RoomId}`,
          CheckIn24Hrs: resData?.CheckIn_24HrsFlg,
          UserId: ghuserId,
        };

        // delete data
        axios
          .post(`${proxy_Url}/BlockDeleteroomsDataDetails`, deleteData, { headers: headers })
          .then((res) => {
            let s_date = document.querySelector("#STime")?.value.split("/");
            let e_date = document.querySelector("#ETime")?.value.split("/");
            let gridData = {
              PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || propertyID[0],
              FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
              ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
              PropertyName:
                property_details[0]?.PropertyName ||
                property_details[0]?.Property ||
                document.querySelector("#ghproperty")?.value,
              action: "delete",
            };
            set_Error_Message_func(["Room unblocked successfully"]);
            setTimeout(() => {set_Error_Message_func([])},1000);
            setErrorMsgClassfunc({ class: "error-success", msg: "Success" });

            isLoadingfunc(true);
            showblockdeletefunc(false);
            gridoccupencydatafunc(gridData);

            if (res.status === 200) {
              // setMsg("record deleted successfully");
            }
            //refress the grid
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };
  const error_close = () => {
    blockdeletefunc([]);
    showblockdeletefunc(false);
  };

  return (
    <>
      {/* {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span> Message</span>{" "}
              <div className="close_but bg-light" onClick={error_close}>
                X
              </div>
            </div>
            <div className="error_content t-center">
              {[...new Set(getErrorMessage)]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )} */}
      {ghaccessdata[0]?.CancelResFlg === true && (
        <div className="changeEditor">
          <div className="editorBody" style={{width: "50%",minWidth: "500px"}}>
            <div className="header">
              <span>
                Room Unblock for <b>{`${ghproperty_Name?.value} 
               
                `}</b>{" "}
                 {/* ${ booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.ClientName !== undefined ?  booking_modify_cancel_data?.Result[0]?.ClientName : ""}*/}
              </span>
              <span className="d-flex ml-auto bg-light popup_Close" onClick={close}>
                X
              </span>
            </div>
            <div className="body">
              <div className="row">
                {/* <div className="col-md-6 col-lg-6 booking_input">
                  <div className="col-md-12 my-2">
                    <span>Booking Confirmation Number</span> :{" "}
                    <b>{booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.BookingCode}</b>
                  </div>
                  <div className="col-md-12">
                    <span>Status</span> :{" "}
                    <b>{booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.CurrentStatus}</b>
                  </div>
                </div> */}
                {/* <div className="col-md-6 col-lg-6 booking_input">
                  <div className="col-md-12 my-2">
                    <span>Guest Name</span> :{" "}
                    <b>{booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.Guest}</b>
                  </div>

                  <div className="col-md-12 my-2">
                    <span>Booking Level</span> :{" "}
                    <b>{booking_modify_cancel_data?.Result && booking_modify_cancel_data?.Result[0]?.BookingLevelId}</b>
                  </div>
                </div> */}
              </div>
              {/* <div className="p-1" style={{ background: "rgb(221, 233, 248)" }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="change_editor">
                      Reason For Cancellation <span className="c-red">*</span> :{" "}
                      <select
                        style={{ background: "#ffffff" }}
                        name="cancel_booking"
                        id="cancel_booking"
                        onChange={SelectChange}
                      >
                        
                        {booking_modify_cancel_data?.BookingCancelType?.map((item) => (
                          <option key={item.Id} value={item.CancelType}>
                            {item.CancelType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="footer">
              <input
                type="button"
                value="Unblock Room"
                className="btn btn-success btn-sm mr-1"
                onClick={cancelBooking}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(BlockDeleteData);
