import React, { useState, useContext, useEffect } from "react";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, Button, Icon, PreviewCard } from "../../components/Component";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { BookingContent } from "../../rcomponents/bookingContext/BookingContext";
import axios from "axios";

//import { useHistory } from "react-router-dom";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [uName, setUName] = useState("");
  const [pwd, setPwd] = useState("");
  const [autherrormsg, setautherrormsg] = useState([]);
  const [logindata, setlogindata] = useState([]);
  const [Userconfirm, setUserconfirm] = useState(false);

  const { getLoginAuth, proxy_Url } = useContext(BookingContent);

  // useEffect(() => {
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("accessToken1");
  //   sessionStorage.removeItem("credentials");
  // },[])

  const loginchk = (data) => {
    let Rdata;
    Rdata = data.Result[0];
    sessionStorage.setItem("access_Token1", JSON.stringify(data.user_token));
    localStorage.setItem("accessToken1", JSON.stringify(JSON.parse(sessionStorage.getItem("access_Token1"))));
    getLoginAuth(Rdata[0]);

    sessionStorage.setItem("credentials", JSON.stringify(Rdata[0]));
    localStorage.setItem("accessToken", JSON.stringify(JSON.parse(sessionStorage.getItem("credentials"))));

    if (Rdata[0].msg === "Success") {
      window.history.pushState(
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
        "auth-login",
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
      );
      window.location.reload();
    } else {
      setError("Cannot login with credentials");
      setLoading(false);
    }
  };

  const onFormSubmit = (formData) => {
    let cd = JSON.parse(localStorage.getItem("accessToken"));

    setLoading(true);

    let bodyData = { Email: uName, UserPassword: pwd };

    axios
      .post(`${proxy_Url}/ghlogin`, bodyData)
      .then(({ data }) => {
        setlogindata(data);
        if (cd !== null) {
          // if (cd?.UserName === data?.Result[0][0].UserName && cd?.Id === data?.Result[0][0].Id) {
          //   loginchk(data);
          // } else {
          //   setUserconfirm(true);
          // }
          loginchk(data);
        } else {
          loginchk(data);
        }
      })
      .catch((error) =>
      { 
        //console.log(error.message)
      });
    // }
  };
  const { errors, register, handleSubmit } = useForm();
  const error_close = () => {
    setautherrormsg([]);

    cancellogin();
  };

  const cancellogin = () => {
    setUserconfirm(false);
    setLoading(false);
  };

  const confirm_login = () => {
    setUserconfirm(false);

    let Rdata = logindata.Result[0];
    sessionStorage.setItem("access_Token1", JSON.stringify(logindata.user_token));
    localStorage.setItem("accessToken1", JSON.stringify(JSON.parse(sessionStorage.getItem("access_Token1"))));
    getLoginAuth(Rdata[0]);

    sessionStorage.setItem("credentials", JSON.stringify(Rdata[0]));
    localStorage.setItem("accessToken", JSON.stringify(JSON.parse(sessionStorage.getItem("credentials"))));

    if (Rdata[0].msg === "Success") {
      window.history.pushState(
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
        "auth-login",
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
      );
      window.location.reload();
    }
  };

  return (
    <React.Fragment>
      {Userconfirm && (
        <div className="error_message staydaysblock">
          <div className="error_Message_content t-center">
            <div className="error_MSg_Con">
              <span>Error Message</span>{" "}
              <div className="close_but bg-light ss" onClick={error_close}>
                X
              </div>
            </div>
            <div className="error_content t-center">
              <p className="t-center">Another user logged in do you want to continue?</p>
            </div>

            <button className="btn btn-sm btn-info mt-n1 mb-2 mr-2 continue-but" onClick={confirm_login}>
              Yes
            </button>
            <button className="btn btn-sm  btn-warning mt-n1 mb-2 mr-2 continue-but" onClick={cancellogin}>
              No
            </button>
          </div>
        </div>
      )}
      {autherrormsg?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>Error Message</span>{" "}
              <div className="close_but bg-light" onClick={error_close}>
                X
              </div>
            </div>
            <div className="error_content t-center">
              {autherrormsg?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <PreviewCard className="card-bordered login-logo" bodyClass="card-inner-lg">
            <div className="brand-logo pb-1 text-center">
              <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                <img
                  className="logo-dark"
                  src="https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png"
                  alt="logo-dark"
                />
              </Link>
            </div>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" />Invalid username or password.
                   {/* Please check your credentials and try again {" "} */}
                </Alert>
              </div>
            )}
            <h3 className="text-center fw-bold mt-3">Have an account?</h3>
            <h5 className="text-center fw-bold mt-0">Login</h5>
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup className="mb-0">
                <div className="form-label-group">
                  <label className="form-label mt-0" htmlFor="default-01">
                    Username
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="name"
                    ref={register({ required: "Username is required" })}
                    //defaultValue="info@softnio.com"
                    value={uName}
                    onChange={(e) => setUName(e.target.value)}
                    placeholder="Username"
                    className="form-control-lg form-control"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="passcode"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    ref={register({ required: "Password is required" })}
                    placeholder="Password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"} passwordfield`}
                  />
                  {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                </div>
                <div className="form-label-group mt-2">
                  <label className="form-label" htmlFor="password">
                    {" "}
                  </label>
                  <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                    Forgot Password?
                  </Link>
                </div>
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </FormGroup>
            </Form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
