import React, { useContext, useState, useRef, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { BookingContent } from "../bookingContext/BookingContext";
import axios from "axios";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";

const Availability = () => {
  const { formatDateddmmyy, ghName, proxy_Url, getGhName, token, propertyloadfunc, propInput } =
    useContext(BookingContent);
  const [formValues, setFormValues] = useState({
    GHName: "",
    RoomType: "",
    bookingLevel: "",
    frmDate: new Date(),
  });
  const [enddate, setenddate] = useState("");
  const [availability, setAvailability] = useState([]);
  //const [Propertyload, setPropertyload] = useState([]);
  const [bookingfilter, setBookingfilter] = useState([]);
  const [Availabilityvalue, setAvailabilityvalue] = useState([]);
  // const [bookingDetails, setBookingDetails] = useState([]);
  const [roomType, setRoomType] = useState([]);
  const [staycount, setStaycount] = useState([]);
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [errormsg, seterrormsg] = useState("");
  // excel download
  const tableRef = useRef();

  //date object
  let startObj = useRef();
  let endObj = useRef();
  let propertyloadref = useRef();
  let bookingStatusref = useRef();
  let roomtyperef = useRef();

  let booking_name = document.querySelector("#booking_ghname");
  let room_type = document.querySelector("#room_type");

  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };

  useEffect(() => {
    let date = new Date(formValues.frmDate);
    date.setDate(date.getDate() + 1);
    setenddate(date);

    getGhName([]);

    // propertyload func call
    propertyloadfunc();
  }, []);

  const onSubmit = (e) => {
    //e.preventDefault();
   
    setAvailability([]);
    setAvailabilityvalue([]);
    if (booking_name?.value !== "") {
      let propertyId = ghName[0]?.filter((item) => item.Property.trim() === booking_name?.value.trim() && item);
      if (propertyId.length > 0) {
        let bodyData = {
          PropertyId: `${propertyId[0]?.Id}`,
          BookingLevel: document.querySelector("#bookingLevel").value,
          StartDate: formatDate(document.querySelector("#frmDate").value),
          EndDate: formatDate(document.querySelector("#toDate").value),
          UserId: ghuserId,
          RoomType: room_type?.value || "",
        };

        axios
          .post(`${proxy_Url}/GHAvailablityCheck`, bodyData, { headers: headers })
          .then(({ data }) => {
            let Rdata = Array.isArray(data?.Result[0]) === true ? data?.Result[0] : [];

            if(document.querySelector("#bookingLevel").value === 'Bed') {
              setAvailability(Rdata);
              setAvailabilityvalue(Rdata);
            setStaycount(data?.Result[1]);
            }
            else {
              const uniqueData = Rdata.filter(
                (item, index, self) =>
                  index ===
                  self?.findIndex((obj) => obj?.RoomDetails === item?.RoomDetails)
              );
              setAvailabilityvalue(uniqueData);
              setAvailability(uniqueData);
            setStaycount(data?.Result[1]);

             // setAvailability(uniqueData);
            //setStaycount(uniqueData?.length);
            }
            
            
            if (Rdata.length > 0) {
            } else {
              setgetErrorMessagefunc("No data Found");
            }

            seterrormsg("Error");
            setTimeout(() => {
              error_close();
            }, 2000);
          })
          .catch((error) => {});
      } else {
        setgetErrorMessagefunc("Please select guest house name from list");
        seterrormsg("Error");
        setTimeout(() => {
         
          error_close();
        }, 2000);
      }
    } else {
      setgetErrorMessagefunc("Please select guest house name from list");
      seterrormsg("Error");
      setTimeout(() => {
        error_close();
      }, 2000);
    }
  };

  const onFocussdate = () => {
    startObj.show();
    document.querySelector("#frmDate").blur();
  
  };

  const onFocussenddate = () => {
    endObj.show();
    document.querySelector("#toDate").blur();
   
  };

  

  const HandleChange1 = (event) => {
    setenddate(new Date(event?.value));
   
  };
  const HandleChange = (event) => {
   
    const name = event.target.name;
    const value = event.target.value;
    setFormValues((values) => ({ ...values, [name]: value }));
    if(name === 'bookingLevel'){
      setAvailability([]);
    setRoomType([]);
    }
    //clear data 
    // setAvailability([]);
    //         setStaycount([]);
    //         setTimeout(() => {
    //         document.querySelector("#bookingLevel").innerHTML = 'Bed Level';
    //         setFormValues(pre => ({...pre,bookingLevel:'Bed'}))
    //       }, 2000);
   
  };

  useEffect(() => {
   
    let date = new Date(formValues?.frmDate);
    date.setDate(date.getDate() + 1);
    setenddate(date);
   
  }, [formValues?.GHName,formValues?.frmDate]);

  const clearData = (val) => {
    if(val !== 'noguesthouse' ) {
    propertyloadref.current.value = "";
    }
    bookingStatusref.current.value = "Room";
    roomtyperef.current.value = "";

    document.querySelector("#frmDate").value = formatDateddmmyy(new Date());
    let date = new Date();
    date.setDate(date.getDate() + 1);
    setenddate(date);

    //setPropertyload([]);
    setAvailability([]);
    setRoomType([]);
    
  };
  const clearDataghnamechange = (val) => {
    if(val !== 'noguesthouse' ) {
    propertyloadref.current.value = "";
    }
    bookingStatusref.current.value = "Room";
    roomtyperef.current.value = "";
    
    // document.querySelector("#frmDate").value = formatDateddmmyy(new Date());
    // let date = new Date();
    // date.setDate(date.getDate() + 1);
    // setenddate(date);

    //setPropertyload([]);
    setAvailability([]);
    setRoomType([]);
    
  };


  const ghnamechange = () => {
    clearDataghnamechange('noguesthouse');
    setAvailability([]);
    // setBookingDetails([]);
    let property_id = ghName[0]?.filter((item) => item.Property.trim() === booking_name?.value.trim());
    if (property_id?.length > 0) {
      let bodyData = {
        PropertyId: property_id[0]?.Id,
      };

      axios
        .post(`${proxy_Url}/roomtypeload`, bodyData, { headers: headers })
        .then(({ data }) => {
          let room_type = data?.Result[0]?.map((item) => item.RoomType);
          setRoomType(room_type);
        })
        .catch((error) => {});
    } else {
      setRoomType([]);
    }
   
  };
  function formatDate(date) {
    var d = date.split("/");
  
    return [d[1], d[0], d[2]].join("/");
    
  }

  const ghroomnochange = () => {
    setAvailability([]);
    
  };
  const error_close = () => {
    setgetErrorMessagefunc([]);
    
  };
  const handleSelect = (selectedValue) => {
  setTimeout(() => {
  let property_id = ghName[0]?.filter((item) => item.Property.trim() === document.querySelector("#booking_ghname").value.trim());


  if (property_id?.length > 0) {
   
      let bodyData = {
        PropertyId: property_id[0]?.Id,
      };
     
      axios
        .post(`${proxy_Url}/roomtypeload`, bodyData, { headers: headers })
        .then(({ data }) => {
          let room_type = data?.Result[0]?.map((item) => item.RoomType);
          setRoomType(room_type);
        })
        .catch((error) => {});
    } else {
      setRoomType([]);
    }
  },100)
 
};




const availabilityfilter = (value) => {
  setBookingfilter(value);


  let data;
  if (value === "") {
    // If search filter is cleared, set availabilityvalue back to original availability
    setAvailabilityvalue(availability);
  } else {
    // Filter availability based on the search filter
    data = availability.filter(item => (
      item.RoomType.toLowerCase().includes(value.toLowerCase()) ||
      item.RoomDetails.toLowerCase().includes(value.toLowerCase()) ||
      item.RoomNo.toLowerCase().includes(value.toLowerCase()) ||
      (document.querySelector("#bookingLevel").value === 'Bed' && item.BedNO.toLowerCase().includes(value.toLowerCase()))
    ));
    // Set availabilityvalue to the filtered data
    setAvailabilityvalue(data);
  }
}





  return (
    <React.Fragment>
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{errormsg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content">
              {[getErrorMessage]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      <Head title="Guest house booking"></Head>

      <Content>
        <h3 className="page_title">Availability </h3>
        <form onSubmit={onSubmit}>
          <div className="bg-white p-3 card-bordered b-form-details">
            <div className="row">
              <div className="col-md-4">
                <label className="e-textlabel">
                  Guest House <span className="c-red">*</span>
                </label>

                {/* <input
                type="text"
                list="data"
                ref={propertyloadref}
                onChange={HandleChange}
                name="GHName"
                className="w-100 form-control"
              />

              <datalist id="data">
                {ghName && ghName[0]?.map((item, key) => <option key={key} value={item.Property} />)}
              </datalist> */}
                <AutoCompleteComponent
                  id="booking_ghname"
                  value={formValues.GHName}
                  placeholder="Select the Guest House"
                  className="form-control"
                  onChange={ghnamechange}
                  dataSource={ghName && ghName[0]?.map((item, key) => item.Property)}
                  filtering={ghnamechange}
                  ref={propertyloadref}
                  name="GHName"
                  // onInput={propInput}
                  // onSelect={ghSelect}
                   select={handleSelect}
                />
              </div>
              <div className="col-md-2 form-group">
                <label className="e-textlabel">Room Type</label>
                <div className="form-control-wrap">
                  {/* <input
                  type="text"
                  list="RoomType"
                  onChange={HandleChange}
                  name="RoomType"
                  className="w-100 form-control"
                />

                <datalist id="RoomType">
                  {ghName && ghName[1]?.map((item, key) => <option key={key} value={item.Property} />)}
                </datalist> */}
                  <AutoCompleteComponent
                    id="room_type"
                    //value={formValues.roomType}
                    placeholder="Select room type"
                    className="form-control"
                    //onChange={ghroomnochange}
                    dataSource={roomType}
                    filtering={ghroomnochange}
                    ref={roomtyperef}
                    name="RoomType"
                    // onInput={propInput}
                   
                  />
                </div>
              </div>
              <div className="col-md-2">
                <label className="e-textlabel">Booking Level</label>
                <select
                  id="bookingLevel"
                  placeholder="Choose Type"
                  data-name="bookingLevel"
                  name="bookingLevel"
                  style={{ width: "100%" }}
                  value={formValues.bookingStatus}
                  ref={bookingStatusref}
                  onChange={HandleChange}
                  className="form-control"
                >
                  <option value="Room">Room Level</option>
                  <option value="Bed">Bed Level</option>
                </select>
              </div>
              <div className="col-md-2">
                <label className="e-textlabel">From Date</label>
                <div className="form-control-wrap">
                  <DatePickerComponent
                    id="frmDate"
                    format="dd/MM/yyyy"
                    data-name="frmDate"
                    onFocus={onFocussdate}
                    ref={(date) => (startObj = date)}
                    //ref={startObj}
                    fields={{ text: "frmDate", defaultValue: "frmDate" }}
                    //value={props.StartTime || props.startTime}
                    value={formValues.frmDate}
                    style={{ width: "100%" }}
                    className="e-datetimepicker e-field e-input disble"
                    name="frmDate"
                    onChange={HandleChange}
                  ></DatePickerComponent>
                </div>
              </div>
              <div className="col-md-2">
                <label className="e-textlabel">To Date</label>
                <div className="form-control-wrap">
                  <DatePickerComponent
                    id="toDate"
                    format="dd/MM/yyyy"
                    data-name="toDate"
                    onFocus={onFocussenddate}
                    onChange={HandleChange1}  
                    min={formValues.frmDate}
                    name="toDate"
                    ref={(date) => (endObj = date)}
                    value={enddate}
                    className="form-control"
                  ></DatePickerComponent>
                </div>
              </div>

              <div className="col-md-12 text-center mt-3 border-top">
                <div className="btnGrp mt-2">
                  <button type="button" className="btn btn-sm btn-success m-1" onClick={onSubmit}>
                    Search
                  </button>
                  <button type="button" className="btn btn-sm btn-warning m-1" onClick={clearData}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        {availability && availability?.length > 0 ? (
          <>
            <div className="p-3">
              <div className="row my-2">
                <div className="col-6 col-md-6 bg-light p-1 text-center">
                  <div className="p-1 py-2">
                    {" "}
                    <b>Stay Count : {staycount && staycount[0]?.Count}</b>
                  </div>
                </div>
                <div className="col-6 col-md-6 bg-light p-1">
                  <div className="p-1 py-1">
                    <input
                      type="text"
                      placeholder="Filter"
                      className="ml-auto form-control"
                      style={{ width: "100%", maxWidth: "200px" }}
                      onChange={(e) => availabilityfilter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 tr_list_table">
                <div className="o_flow h-26 datashow bg-white">
                  <table
                    ref={tableRef}
                    role="table"
                    className="table table-hover table-bordered tr_select availability b-shadow w-100"
                  >
                    <thead role="rowgroup">
                      <tr role="row" className="stickyheader">
                        <th role="columnheader">Room Type</th>
                        <th role="columnheader">Room Details</th>
                        <th role="columnheader">Room No</th>
                        {document.querySelector("#bookingLevel").value === 'Bed' &&  <th role="columnheader">Bed No</th> }
                      </tr>
                    </thead>
                    {/* <tbody role="rowgroup">
                      {availability &&
                        availability?.length > 0 &&
                        availability
                          .filter((item) => {
                            if (bookingfilter == "") {
                              return item;
                            } else if (
                              item.RoomType.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.RoomDetails.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.RoomNo.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.BedNO.toLowerCase().includes(bookingfilter.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((val, index) => (
                            <tr role="row" key={index}>
                              <td role="cell">{val.RoomType}</td>
                              <td role="cell">{val.RoomDetails}</td>
                              <td role="cell">{val.RoomNo}</td>
                             {document.querySelector("#bookingLevel").value === 'Bed' && <td role="cell">{val.BedNO}</td> }
                            </tr>
                          ))}
                    </tbody> */}

<tbody role="rowgroup">
                      {/* {availability &&
                        availability?.length > 0 &&
                        availability
                          .filter((item) => {
                            if (bookingfilter == "") {
                              return item;
                            } else if (
                              item.RoomType.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.RoomDetails.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.RoomNo.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.BedNO.toLowerCase().includes(bookingfilter.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((val, index) => (
                            <tr role="row" key={index}>
                              <td role="cell">{val.RoomType}</td>
                              <td role="cell">{val.RoomDetails}</td>
                              <td role="cell">{val.RoomNo}</td>
                             {document.querySelector("#bookingLevel").value === 'Bed' && <td role="cell">{val.BedNO}</td> }
                            </tr>
                          ))} */}
                          {Availabilityvalue &&
                        Availabilityvalue?.length > 0 ?
                        Availabilityvalue
                          .filter((item) => {
                            if (bookingfilter == "") {
                              return item;
                            } else if (
                              item.RoomType.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.RoomDetails.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.RoomNo.toLowerCase().includes(bookingfilter.toLowerCase()) ||
                              item.BedNO.toLowerCase().includes(bookingfilter.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((val, index) => (
                            <tr role="row" key={index}>
                              <td role="cell">{val.RoomType}</td>
                              <td role="cell">{val.RoomDetails}</td>
                              <td role="cell">{val.RoomNo}</td>
                             {document.querySelector("#bookingLevel").value === 'Bed' && <td role="cell">{val.BedNO}</td> }
                            </tr>
                          )) :  <tr role="row">
                          <td role="cell" colSpan={4} style={{textAlign:"center"}}>No data found</td>
                        </tr>}
                    </tbody>
</table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p className="d-flex text-align-center justify-content-center mt-3">
            Select Guest House Name and click search to display data
          </p>
        )}
      </Content>
    </React.Fragment>
  );
};

export default Availability;