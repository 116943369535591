import React, { useEffect, useState, useContext } from "react";
import "./TrRequestComponent.css";
import axios from "axios";
import LoadSpinner from "../spinner/Spinner";
import { BookingContent } from "../bookingContext/BookingContext";
import "../../assets/scss/responsivetable.scss";

const TRRequest = ({ getTrDetails, startdate, enddate, setEditordata }) => {
  const {
    propertyID,
    // getGhName,
    // ghName,
    // User_Details,
    // propertyLoad,
    // addToProperty,
    isLoadingfunc,
    client_id,
    clientID,
    token,
    getTrSelectDetails,
    proxy_Url,
    tr_details,
    settrdetailsfunc,
    setShowTrfunc,
    showTr,
    GH_property_ClientName
  } = useContext(BookingContent);
  // const [trDetails, setTrDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // ghpreopretySearch
  const [ghtrSearch, settrSearch] = useState("");
  // const [selectedtrDetails, setSelectedtrDetails] = useState([]);

  // user id
  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };

  //format date
  // function formatmonDateyear(date) {

  //   var d = new Date(date),
  //     month = "" + (d.getMonth() + 1),
  //     day = "" + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = "0" + month;
  //   if (day.length < 2) day = "0" + day;

  //   return [day, month, year].join("/");
  // }

  useEffect(() => {
    settrdetailsfunc([]);
    let getclientname = document.querySelector("#Entity_select")?.value;
    let getclientid = GH_property_ClientName[0][0]?.filter((item) => item.ClientName === getclientname)[0]?.Id;
    let st_date = document.querySelector("#STime")?.value.split("/");
    let ed_date = document.querySelector("#ETime")?.value.split("/");
    const bodyData = {
      //  FromDt: formatmonDateyear(new Date(startdate).toISOString().split("T")[0]),
      // ToDt: formatmonDateyear(new Date(enddate).toISOString().split("T")[0]),
      FromDt: st_date[1] + "/" + st_date[0] + "/" + st_date[2],
      ToDt: ed_date[1] + "/" + ed_date[0] + "/" + ed_date[2],

     // ClientId: clientID[0]?.length > 0 ? clientID[0] : client_id,
     ClientId: clientID[0]?.length > 0 ? getclientid || clientID[0] : getclientid ||client_id,
      PropertyId: propertyID[0],
      UserId: ghuserId,
    };

    axios
      .post(`${proxy_Url}/TRCodeLoads`, bodyData, { headers: headers })
      .then((response) => {
        //  setTrDetails(response?.data?.Result);

        settrdetailsfunc(response?.data?.Result);

        setIsLoading(false);
        isLoadingfunc(false);
      })
      .catch((error) => {});

    return () => {
      // setTrDetails([])
    };
  }, []);

  //select the property
  const selectOption = (e, val) => {
    e.preventDefault();

    //getTrDetails(val);
    setShowTrfunc(false);

    const bodyData = {
      TRId: val.TRFormNo,
      UserId: parseInt(ghuserId),
    };

    axios
      .post(`${proxy_Url}/TRNoSelect`, bodyData, { headers: headers })
      .then((response) => {
        getTrSelectDetails(response.data.Result[0]);
    

        //   document.querySelector("#StartTime").value =
        //   dataresult.CheckInDate || val.CheckInDate;
        // document.querySelector("#EndTime").value =
        //   dataresult.CheckOutDate  || val.CheckOutDate;
        //   document.querySelector("#checkintime").value =
        //   dataresult?.CheckInTime  || val.CheckInTime;
        //   document.querySelector("#tr_number").value =
        //   dataresult?.label  || val.TRFormNo;
        // console.log(response.data.Result[0][0])
      })
      .catch((error) => {});
  };

  //close popup page
  const closeFields = () => {
    setShowTrfunc(!showTr);
  };

  //filter the property list
  let filter_tr_number =
    tr_details.flat(Infinity) &&
    tr_details.flat(Infinity)?.filter((item) => {
      if (ghtrSearch == "") {
        return item;
      } else if (item.TRFormNo?.includes(ghtrSearch)) {
        return item;
      }
    });
// console.log(filter_tr_number)
  return (
    <>
      {isLoading ? (
        <LoadSpinner />
      ) : (
        <div className="changeEditor">
          <div className="editorBody no-padd" style={{ overflow: "hidden" }}>
            <div className="header">
              <span className="w-100">
                <span>Travel Request</span>

                <input
                  type="search"
                  name="ghpreopretySearch"
                  defaultValue=""
                  placeholder="Travel No Search"
                  className="ghpropertySearch ml-1 mt-n1"
                  onChange={(e) => settrSearch(e.target.value)}
                />
              </span>
              <span className="d-flex ml-auto bg-light popup_Close" onClick={closeFields}>
                X
              </span>
            </div>
            <div className="body">
              <div className="h-100">
                <form className="tr_list_table new_tr_booking h-100">
                  <div className="row h-100">
                    <div className="col-md-12 h-100">
                      <div className="change_editor" style={{ overflow: "hidden", height: "100%" }}>
                        <div className="o_flow h_100">
                          <table role="table" className="table tr_select b-shadow w-100">
                            <thead role="rowgroup" className="sticky_header">
                              <tr role="row">
                                <th role="columnheader">Travel&nbsp;No</th>
                                <th role="columnheader">Requested Date</th>
                                <th role="columnheader">Employee Name</th>
                                <th role="columnheader">City</th>
                                <th role="columnheader">Check-In Date</th>
                                <th role="columnheader">Check-Out Date</th>
                                <th role="columnheader" style={{ maxWidth: "70px" }}>
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody role="rowgroup">
                              {filter_tr_number?.length > 0 && filter_tr_number[0] !== "No records found" ? (
                                filter_tr_number?.map((val, index) => (
                                  <tr role="row" key={index}>
                                    <td role="cell">{val.TRFormNo}</td>
                                    <td role="cell">{val.RequestDate}</td>
                                    <td role="cell">{val.EmpName}</td>
                                    <td role="cell">{val.City}</td>
                                    <td role="cell">{val.ChkInDt}</td>
                                    <td role="cell">{val.ChkOutDt}</td>
                                    <td role="cell" style={{ textAlign: "center", maxWidth: "70px" }}>
                                      <button
                                        className="selectsingleTr btn btn-primary btn-sm"
                                        onClick={(e) => selectOption(e, val)}
                                      >
                                        Select
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr role="row">
                                  <td colSpan={7} rowSpan={2} style={{ textAlign: "center", width: "100%" }}>
                                    No Records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(TRRequest);
